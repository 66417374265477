import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { CollectionAgencies } from '@appTypes';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { PaymentLink } from '@pages/ContractDetails/components';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalContractRiskAndWaterfall } from '@schemas/opsPortal/types/contractRiskAndWaterfall';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { IOpsPortalPaymentLink } from '@schemas/opsPortal/types/paymentLink';
import { IOpsPortalRiskApplicationDetails } from '@schemas/opsPortal/types/riskApplicationDetails';
import { CollectionManagementEntities } from '@utils/enums';
import { getFullName } from '@utils/getFullName';
import { getTranslatedStatus } from '@utils/getTranslatedStatus';
import { IOverviewData } from '../../utils/types';

interface IMapContractOverviewData {
  canUsePaymentLink: boolean;
  collectionAgencies: CollectionAgencies;
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  data: IContractDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isArrearsToBeManagedByHeidi: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  monetaBalanceDetails?: IOpsPortalMonetaBalanceDetails;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
  paymentLinkData?: IOpsPortalPaymentLink;
  riskAndWaterfallData?: IOpsPortalContractRiskAndWaterfall;
  riskApplicationData?: IOpsPortalRiskApplicationDetails;
  translate: Translate;
}

export const mapContractOverviewData = ({
  canUsePaymentLink,
  collectionAgencies,
  collectionsBlockAutoReferralData,
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isArrearsToBeManagedByHeidi,
  isCompass,
  isInternalOpsUser,
  monetaBalanceDetails,
  pauseCommunicationsData,
  paymentLinkData,
  riskAndWaterfallData,
  riskApplicationData,
  translate,
}: IMapContractOverviewData): IOverviewData => {
  const {
    arrears_managed_by: arrearsManagedBy,
    contract_uuid: contractUUID,
    created_date: createdDate,
    credit_type: creditType,
    managed_by_crabb: managedByCrabb,
    mediobanca_external_id: praticaId,
    merchant_name: merchantName,
    merchant_order_reference: merchantOrderReference,
    merchant_user: merchantUser,
    op_product_name: productName,
    op_subproduct_name: subProductName,
    order_details: orderDetails,
    original_number_of_installments: numberOfInstallments,
    payment_method: paymentMethod,
    payment_plan_reference: paymentPlanReference,
  } = data;

  const maturityDate = monetaBalanceDetails?.maturity_date;
  const monthlyPaymentAmount = monetaBalanceDetails?.monthly_payment_amount;
  const showAutoReferralStatus =
    collectionsBlockAutoReferralData && isArrearsToBeManagedByHeidi;

  const currentPhase = riskApplicationData?.current_phase;
  const creditStatus = riskApplicationData?.credit_status;
  const identityStatus = riskApplicationData?.identity_status;
  const paymentMethodStatus = riskApplicationData?.payment_method_status;

  const {
    risk_config_name: riskConfigName,
    waterfall_config_name: waterfallName,
  } = riskAndWaterfallData ?? {};

  const opsPortalPrefix = 'OPS_PORTAL.STATUS';
  const opsPortalPaymentMethodPrefix = 'OPS_PORTAL.PAYMENT_METHOD';

  const collectionAssignmentManagedBy = managedByCrabb
    ? CollectionManagementEntities.Crabb
    : CollectionManagementEntities.HeyLight;

  return addPlaceholderForEmptyValues<IOverviewData>({
    contractUUID,
    merchantName,
    ...(isInternalOpsUser
      ? {
          merchantUserId: merchantUser?.email,
          merchantUserName: getFullName({
            firstName: merchantUser?.first_name,
            lastName: merchantUser?.last_name,
          }),
          merchantUserPhoneNumber: merchantUser?.phone_number,
          merchantOrderReference,
          productName,
          subProductName,
          storeId: orderDetails?.full_details?.store_id,
        }
      : {}),
    arrearsManagedBy: collectionAgencies[arrearsManagedBy],
    collectionAssignmentManagedBy,
    ...(!isCompass && { paymentPlanReference }),
    ...(isCompass && { praticaId }),
    createdDate: createdDate && getLocaleDate({ date: createdDate }),
    maturityDate: maturityDate && getLocaleDate({ date: maturityDate }),
    numberOfInstallments,
    monthlyPaymentAmount: getLocaleCurrencyValue({
      currency: monthlyPaymentAmount?.currency,
      value: monthlyPaymentAmount?.amount,
    }),
    currentPhase,
    creditStatus:
      creditStatus &&
      translate(getTranslatedStatus(opsPortalPrefix, creditStatus)),
    identityStatus:
      identityStatus &&
      translate(getTranslatedStatus(opsPortalPrefix, identityStatus)),
    paymentMethodStatus:
      paymentMethodStatus &&
      translate(getTranslatedStatus(opsPortalPrefix, paymentMethodStatus)),
    paymentMethod:
      paymentMethod &&
      translate(
        getTranslatedStatus(opsPortalPaymentMethodPrefix, paymentMethod),
      ),
    ...(canUsePaymentLink
      ? {
          paymentLink: <PaymentLink paymentLinkData={paymentLinkData} />,
        }
      : {}),
    ...(showAutoReferralStatus && {
      canBeReferredToExternalCollectors: translate(
        getTranslatedStatus(
          opsPortalPrefix,
          !collectionsBlockAutoReferralData.block_external_collections_auto_referral,
        ),
      ),
    }),
    creditType,
    ...(pauseCommunicationsData && {
      pauseCommunications: translate(
        getTranslatedStatus(
          opsPortalPrefix,
          pauseCommunicationsData?.pause_communications,
        ),
      ),
    }),
    riskConfigName,
    waterfallName,
  });
};
