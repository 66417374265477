import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface IUseLazyLoadTab {
  eventKey?: string;
}

interface ISetLazyLoadActiveTab {
  key: string;
}

export const useLazyLoadTab = ({ eventKey }: IUseLazyLoadTab = {}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [hasTabBeenClicked, setHasTabBeenClicked] = useState(false);

  const setLazyLoadActiveTab = ({ key }: ISetLazyLoadActiveTab) => {
    navigate(location.pathname, { state: { activeKey: key } });
  };

  useEffect(() => {
    if (!hasTabBeenClicked) {
      setHasTabBeenClicked(location.state?.activeKey === eventKey);
    }
  }, [
    eventKey,
    hasTabBeenClicked,
    location.state?.activeKey,
    setHasTabBeenClicked,
  ]);

  return { hasTabBeenClicked, setLazyLoadActiveTab };
};
