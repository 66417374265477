import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IFormattedPaymentScheduleHistoryTableData } from '../../../utils/types';
import { IScheduleVersion } from './getScheduleVersions';

const renderCell = (data: string, isNewEntryForVersion = false) => (
  <span className={isNewEntryForVersion ? 'schedule-version-new-entry' : ''}>
    {data}
  </span>
);

interface IGetPaymentScheduleHistoryColumns {
  scheduleVersions: IScheduleVersion[];
  translate: Translate;
}

export const getPaymentScheduleHistoryColumns = ({
  scheduleVersions,
  translate,
}: IGetPaymentScheduleHistoryColumns) => {
  const columnHelper =
    createColumnHelper<IFormattedPaymentScheduleHistoryTableData>();

  return [
    columnHelper.accessor('paymentDate', {
      header: translate('TITLE.DATE'),
      cell: ({ row }) => renderCell(row.original.paymentDate),
    }),
    ...scheduleVersions.map((version, index) =>
      columnHelper.accessor(version.id.toString(), {
        header: version.name,
        cell: ({ row }) => {
          const currentVersionValue = String(row.original[version.id]);
          const previousVersionValue =
            index > 0
              ? String(row.original[scheduleVersions[index - 1].id])
              : null;

          return renderCell(
            String(row.original[version.id]),
            index > 0 && previousVersionValue !== currentVersionValue,
          );
        },
      }),
    ),
  ];
};
