import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { IModal, IModalProps, IUseModalResponse } from './types';

interface IGetModalDetails {
  modal: Nullable<IUseModalResponse>;
  modalProps: IModalProps;
}

export const getModalDetails = ({
  modal,
  modalProps,
}: IGetModalDetails): IModal | null => {
  if (!modal?.canUseModal) {
    return null;
  }

  modal.handleSetModalProps(modalProps);

  return {
    button: {
      additionalButtonContent: modalProps.additionalButtonContent,
      clickHandler: modal.openModal,
      buttonKey: modal.modalName,
      text: modal.buttonText,
      variant: modalProps.variant,
    },
  };
};
