import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';
import { Translate } from '@hooks/useI18n';

interface IGetCompassNoteTypesDropDownOptions {
  translate: Translate;
}
export const getCompassNoteTypesDropDownOptions = ({
  translate,
}: IGetCompassNoteTypesDropDownOptions): IDropdownOptions[] => {
  return [
    {
      id: 'neg350',
      name: `NEG 350 - ${translate('OPS_PORTAL.LABEL.NEGATIVE_WITH_CONTACT')}`,
    },
    {
      id: 'neg351',
      name: `NEG 351 - ${translate('OPS_PORTAL.LABEL.NOT_AVAILABLE')}`,
    },
    {
      id: 'neg352',
      name: `NEG 352 - ${translate('OPS_PORTAL.LABEL.DISPUTE')}`,
    },
    {
      id: 'neg353',
      name: `NEG 353 - ${translate('OPS_PORTAL.LABEL.CUSTOMER_ABSENT')}`,
    },
    {
      id: 'neg354',
      name: `NEG 354 - ${translate('OPS_PORTAL.LABEL.DECEASED')}`,
    },
    {
      id: 'neg356',
      name: `NEG 356 - ${translate('OPS_PORTAL.LABEL.IN_PRISON')}`,
    },
    {
      id: 'neg357',
      name: `NEG 357 - ${translate('OPS_PORTAL.LABEL.UNEMPLOYED')}`,
    },
    {
      id: 'par150',
      name: `PAR 150 - ${translate('OPS_PORTAL.LABEL.PARTIAL_PAYMENT')}`,
    },
    {
      id: 'pos152',
      name: `POS 152 - ${translate('OPS_PORTAL.LABEL.POSITIVE_TOTAL')}`,
    },
    {
      id: 'rev112',
      name: `REV 112 - ${translate('OPS_PORTAL.LABEL.POSITION_RECALLED')}`,
    },
  ];
};
