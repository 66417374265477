export enum OpsPortalDocumentTypes {
  CustomerAgreement = 'Customer agreement',
  IntrumIdentificationFiles = 'Intrum identification files',
  IntrumSigningFiles = 'Intrum signing files',
  OnfidoBack = 'Onfido back',
  OnfidoFront = 'Onfido front',
  OnfidoHealthCard = 'Onfido health card',
  OnfidoSelfie = 'Onfido selfie',
  TermsAndConditionsConclusion = 'Terms and conditions (Conclusion)',
  TermsAndConditionsFinalization = 'Terms and conditions (Finalization)',
  TradeInOptionAgreement = 'Trade in option agreement',
  UploadedDocumentAcknowledgementOfDebt = 'Uploaded document (Acknowledgement of debt)',
  UploadedDocumentComplaintAboutAgency = 'Uploaded document (Complaint about debt collection agency)',
  UploadedDocumentComplaintAboutLateFee = 'Uploaded document (Complaint about late fee)',
  UploadedDocumentComplaintAboutMerchant = 'Uploaded document (Complaint about merchant)',
  UploadedDocumentCreditContractSignedInstore = 'Uploaded document (Credit contract signed instore)',
  UploadedDocumentGeneralComplaint = 'Uploaded document (General complaint)',
  UploadedDocumentImportantCommunication = 'Uploaded document (Important communication from customer)',
  UploadedDocumentOther = 'Uploaded document (Other)',
  UploadedDocumentReplyToAComplaint = 'Uploaded document (Reply to a complaint)',
}

export enum OpsPortalDocumentModes {
  Contract = 'Contract',
  Merchant = 'Merchant',
}

export const UploadedDocumentTypes = [
  OpsPortalDocumentTypes.UploadedDocumentAcknowledgementOfDebt,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutAgency,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutLateFee,
  OpsPortalDocumentTypes.UploadedDocumentComplaintAboutMerchant,
  OpsPortalDocumentTypes.UploadedDocumentCreditContractSignedInstore,
  OpsPortalDocumentTypes.UploadedDocumentGeneralComplaint,
  OpsPortalDocumentTypes.UploadedDocumentImportantCommunication,
  OpsPortalDocumentTypes.UploadedDocumentOther,
  OpsPortalDocumentTypes.UploadedDocumentReplyToAComplaint,
];
