import { useQuery } from '@tanstack/react-query';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetMonetaBalanceQuery {
  contractUuid?: string;
}

export const useGetMonetaBalanceQuery = ({
  contractUuid,
}: IUseGetMonetaBalanceQuery) => {
  const {
    data: monetaBalanceDetails,
    isError: isMonetaBalanceError,
    isInitialLoading: isMonetaBalanceInitialLoading,
  } = useQuery<IOpsPortalMonetaBalanceDetails>(
    [QueryKeys.MONETA_BALANCE_DATA, contractUuid],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchMonetaBalance}/${contractUuid}`),
    { retry: false, enabled: Boolean(contractUuid) },
  );

  return {
    monetaBalanceDetails,
    isMonetaBalanceError,
    isMonetaBalanceInitialLoading,
  };
};
