import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import {
  ICertificatesOfInterest,
  IOpsPortalCertificatesOfInterest,
} from '@schemas/opsPortal/types/certificatesOfInterest';

interface IMappedCertificateOfInterestTableData {
  accountingBalance: string;
  createdDate: string;
  debitInterest: string;
  pdfUrl: string;
  status: string;
  uuid: string;
}

interface IMapCertificateOfInterestTableData {
  data?: IOpsPortalCertificatesOfInterest;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
}

export const mapCertificateOfInterestTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapCertificateOfInterestTableData): IMappedCertificateOfInterestTableData[] =>
  data?.results?.map(
    ({
      accounting_balance: accountingBalance,
      created_date: createdDate,
      debit_interest: debitInterest,
      pdf_url: pdfUrl,
      status,
      uuid,
    }: ICertificatesOfInterest) => {
      return addPlaceholderForEmptyValues<IMappedCertificateOfInterestTableData>(
        {
          accountingBalance:
            accountingBalance &&
            getLocaleCurrencyValue({
              currency: accountingBalance.currency,
              value: accountingBalance.amount,
            }),
          debitInterest:
            debitInterest &&
            getLocaleCurrencyValue({
              currency: debitInterest.currency,
              value: debitInterest.amount,
            }),
          createdDate: createdDate && getLocaleDate({ date: createdDate }),
          pdfUrl,
          uuid,
          status,
        },
      );
    },
  ) ?? [];
