import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TabEventKeys, TableNames } from '@utils/constants';
import { OpsPortalDocumentModes } from './enums';
import { useDocumentsTableData } from './useDocumentsTableData';

export const useContractDocumentsTab = (contractUuid: string): ITabData => {
  const { translate } = useI18n();

  const { error, isLoading, tableProps } = useDocumentsTableData(
    contractUuid,
    OpsPortalDocumentModes.Contract,
  );

  return {
    columns: tableProps.columns,
    data: tableProps.data,
    eventKey: TabEventKeys.CONTRACT_DOCUMENTS,
    error,
    isLoading,
    tableName: TableNames.DOCUMENTS,
    title: translate('OPS_PORTAL.TITLE.CONTRACT_DOCUMENTS'),
  };
};
