import { Translate } from '@hooks/useI18n';
import { getCompassNoteTypesDropDownOptions } from './getCompassNoteTypesDropDownOptions';

interface IGetCompassType {
  compassTypeId: string;
  translate: Translate;
}
export const getCompassType = ({
  compassTypeId,
  translate,
}: IGetCompassType): string => {
  const matchingType = getCompassNoteTypesDropDownOptions({ translate }).find(
    option => option.id === compassTypeId,
  );
  return matchingType ? matchingType.name : '';
};
