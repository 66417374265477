import { useCallback } from 'react';
import { Formik, FormikValues } from 'formik';
import { Button, ButtonRow, Fieldset } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IFilter } from '@hooks/useQueryState/utils/types';
import { FilterTypes } from '@utils/constants';
import { FiltersForm, FiltersLegend, FiltersRow } from './Filters.styles';
import { getFiltersValidationSchema, getFilterUI } from './utils';

interface IFilters<TFilterValues> {
  filterHandler: (values: TFilterValues) => void;
  filters: IFilter[];
  isInTab?: boolean;
  resetFilters: () => void;
  showClearFiltersButton?: boolean;
}

export const Filters = <TFilterValues extends FormikValues>({
  filterHandler,
  filters,
  isInTab = false,
  resetFilters,
  showClearFiltersButton = true,
}: IFilters<TFilterValues>) => {
  const { translate } = useI18n();

  // current refers to the current filter initial values are being generated for
  const initialValues = filters.reduce((values, current) => {
    if (
      current?.type === FilterTypes.DATE_RANGE &&
      current.from &&
      current.to
    ) {
      return {
        ...values,
        [current.from.id]: current.from.value,
        [current.to.id]: current.to.value,
      };
    }

    if (
      current?.type === FilterTypes.PRESET_DATE_RANGE &&
      current.from &&
      current.to
    ) {
      return {
        ...values,
        [current.id]: JSON.stringify([current.from.value, current.to.value]),
      };
    }

    return { ...values, [current.id]: current.value };
  }, {} as TFilterValues);

  const handleReset = useCallback(
    () => filterHandler(initialValues),
    [filterHandler, initialValues],
  );

  return filters.length ? (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={filterHandler}
      validationSchema={getFiltersValidationSchema(filters, translate)}
    >
      <FiltersForm isInTab={isInTab}>
        <Fieldset>
          <FiltersLegend>{translate('TITLE.FILTERS')}</FiltersLegend>
          {filters.map((filter: IFilter) => (
            <FiltersRow key={`filter-${filter.id}`}>
              {getFilterUI(filter)}
            </FiltersRow>
          ))}
          <FiltersRow>
            <ButtonRow>
              {showClearFiltersButton ? (
                <Button
                  onClick={resetFilters || handleReset}
                  type="reset"
                  variant={['secondary', 'sm']}
                >
                  {translate('BUTTON.CLEAR_FILTERS')}
                </Button>
              ) : null}
              <Button type="submit" variant={['primary', 'sm']}>
                {translate('BUTTON.APPLY_FILTERS')}
              </Button>
            </ButtonRow>
          </FiltersRow>
        </Fieldset>
      </FiltersForm>
    </Formik>
  ) : null;
};
