import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IPhoneProps {
  phoneNumber: string;
  translate: Translate;
}

export const getCodeValidationSchema = (translate: Translate) =>
  Yup.object({
    code: Yup.string().required(translate(ValidationErrorKeys.Required)),
  });

export const getPhoneValidationSchema = ({
  phoneNumber,
  translate,
}: IPhoneProps) =>
  Yup.object({
    phone_number: Yup.string()
      .required(translate(ValidationErrorKeys.Required))
      .test(
        'not-duplicate',
        translate(ValidationErrorKeys.PhoneNumberHasNotChanged),
        value => value !== phoneNumber,
      ),
  });
