import { MouseEvent, useCallback, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { ActionButtons } from '@appTypes';
import { Button } from '@components/Button';
import { CardButton } from '@components/CardButton';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ButtonVariant } from '@utils/enums';

interface IButtonMenuProps {
  buttonTranslationKey?: string;
  buttons: ActionButtons;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      // eslint-disable-next-line max-len
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0.5rem 0',
    },
    '& .MuiMenuItem-root': {
      justifyContent: 'flex-end',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '& button.btn.btn-text': {
        paddingBlock: 0,
        marginBlock: 0,
      },
    },
  },
}));

// for this one use case we want the button click to be on the menu not the button
// rather than complicate all other pages by making this optional, add a dummy click to the button
const dummyClick = () => {};

export const ButtonMenu = ({
  buttons,
  buttonTranslationKey,
}: IButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { translate } = useI18n();
  const menuId = 'buttons-menu';

  const handleClickMenuItem = useCallback(
    (clickHandler: () => void) => () => {
      clickHandler();
      handleClose();
    },
    [handleClose],
  );

  return (
    <>
      <Button
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant={ButtonVariant.Primary}
      >
        {translate(buttonTranslationKey ?? 'BUTTON.ACTIONS')}
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        id={menuId}
        MenuListProps={{
          'aria-labelledby': menuId,
        }}
        onClose={handleClose}
        open={isOpen}
      >
        {buttons
          .map(button => {
            if (!button) {
              return null;
            }

            const { buttonKey, clickHandler, ...buttonProps } = button;

            return (
              <MenuItem
                disableRipple={true}
                key={buttonKey}
                onClick={handleClickMenuItem(clickHandler as () => void)}
              >
                <CardButton
                  {...buttonProps}
                  buttonKey={buttonKey}
                  clickHandler={dummyClick}
                  variant={[ButtonVariant.Xs, ButtonVariant.Text]}
                />
              </MenuItem>
            );
          })
          .filter(Boolean)}
      </StyledMenu>
    </>
  );
};
