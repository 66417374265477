import { useState } from 'react';
import { useTrackFiltersChangedEffect } from '@utils/grafana/hooks';
import { FilterValues } from '../types';

export const useGetFilters = <T extends FilterValues>(
  tableName: string,
  defaultFilterValues: T,
) => {
  const [filterValues, setFilterValues] = useState<T>(defaultFilterValues);
  const filterHandler = (values: T) => setFilterValues(values);
  const resetFiltersHandler = () => {
    setFilterValues(defaultFilterValues);
  };

  useTrackFiltersChangedEffect({
    filterValues,
    defaultFilterValues,
    tableName,
  });

  return {
    filterValues,
    filterHandler,
    resetFiltersHandler,
  };
};
