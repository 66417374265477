import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { TabEventKeys, TableNames } from '@utils/constants';
import { getPaymentsTableColumns } from './getPaymentsTableColumns';
import { mapPaymentsData } from './mapPaymentsData';

export const useContractPaymentsTab = (
  monetaContractData?: IOpsPortalMonetaContract,
) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractPaymentsColumns = getPaymentsTableColumns(translate);

  const contractPaymentsData = mapPaymentsData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  return {
    eventKey: TabEventKeys.CONTRACT_PAYMENTS,
    title: translate('TITLE.PAYMENTS'),
    data: contractPaymentsData,
    columns: contractPaymentsColumns,
    tableName: TableNames.PAYMENTS,
  };
};
