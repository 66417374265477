/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { FilterValues } from '@components/TableV2/types';
import { removeEmptyKeys } from '@components/TableV2/utils';
import { pushGrafanaEvent } from '@utils/grafana';
import { GrafanaEvent } from '@utils/grafana/enums';

interface IUseTrackFiltersChangedEffect<T extends FilterValues> {
  defaultFilterValues: T;
  filterValues: T;
  tableName: string;
}

export const useTrackFiltersChangedEffect = <T extends FilterValues>({
  defaultFilterValues,
  filterValues,
  tableName,
}: IUseTrackFiltersChangedEffect<T>) => {
  useEffect(() => {
    const values = removeEmptyKeys(filterValues);
    const defaultValues = removeEmptyKeys(defaultFilterValues);

    // if the filter is different from the default for that page, then sent Grafana event.
    Object.keys(values).forEach(key => {
      if (values[key] !== defaultValues[key]) {
        pushGrafanaEvent(GrafanaEvent.FilterApplied, {
          filter: key,
          tableName,
        });
      }
    });
  }, [
    JSON.stringify(defaultFilterValues),
    JSON.stringify(filterValues),
    tableName,
  ]);
};
