import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IMappedPaymentScheduleHistoryTableData } from '../../../utils/types';

interface IMapPaymentDataProps {
  data?: IOpsPortalMonetaContract;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
}

export const mapPaymentScheduleHistoryData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapPaymentDataProps): IMappedPaymentScheduleHistoryTableData[] =>
  data?.balances
    ?.filter(
      ({ amount_due: amountDue, amount_paid: amountPaid }) =>
        Number(amountDue) !== 0 || Number(amountPaid) !== 0,
    )
    .map(dataRow => {
      const {
        amount_due: amountDue,
        arrears,
        payment_date: paymentDate,
        schedule_version: scheduleVersion,
      } = dataRow;

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const givenDate = new Date(paymentDate);
      givenDate.setHours(0, 0, 0, 0);

      const { currency } = data;
      const amountDueString =
        amountDue &&
        getLocaleCurrencyValue({
          currency,
          value: amountDue,
        });

      const arrearsNumber = parseFloat(arrears);
      const arrearsString =
        arrears &&
        getLocaleCurrencyValue({
          currency,
          value: arrears,
        });

      const isFuture = currentDate < givenDate;
      const focused = !isFuture || arrearsNumber <= 0;
      const paymentScheduleData =
        addPlaceholderForEmptyValues<IMappedPaymentScheduleHistoryTableData>({
          amountDue: amountDueString,
          arrears: focused ? arrearsString : null,
          paymentDate: getLocaleDate({ date: paymentDate }),
          scheduleVersion: String(scheduleVersion),
        });
      return { ...paymentScheduleData, focused };
    }) ?? [];
