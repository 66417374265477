import { useParams } from 'react-router-dom';
import { QueryKey } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { ShoppingCartDetails } from '@components/ShoppingCartDetails';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import { useConfig } from '@hooks/useConfig';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { IMerchantPortalMediobancaSettlement } from '@schemas/merchantPortal/types/mediobancaSettlement';
import { IMerchantPortalOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { PaymentTypes } from '@utils/constants';
import {
  checkPropertiesExist,
  getBillingInfoProps,
  getMerchantOrderSummaryProps,
} from '../utils';
import { BillingInfo, OrderSummaryCard, InvoiceDetails } from '.';

interface IOrderDetailsDisplayMerchant {
  mediobancaSettlementsData?: IMerchantPortalMediobancaSettlement;
  orderDetailsData: IMerchantPortalOrderDetails;
  queryKey: QueryKey;
}

export const OrderDetailsDisplayMerchant = ({
  mediobancaSettlementsData,
  orderDetailsData,
  queryKey,
}: IOrderDetailsDisplayMerchant) => {
  const { showCompassOrders, showInvoiceDetails } = useConfig();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isPagolightPro } = useOriginator();
  const { uuid } = useParams();

  const dataObj = orderDetailsData[0];

  const { hasProducts, permissions } = checkPropertiesExist({
    data: dataObj,
    isPagolightPro,
  });

  const billingInfoProps = getBillingInfoProps(dataObj);

  const orderSummaryProps = getMerchantOrderSummaryProps({
    getLocaleCurrencyValue,
    getLocaleDate,
    isPagolightPro,
    orderDetailsData: dataObj,
    showCompassOrders,
  });

  return (
    <>
      <OrderSummaryCard
        canShowPaymentButton={true}
        orderDetailsData={dataObj}
        orderSummaryProps={orderSummaryProps}
        paymentType={PaymentTypes.REFUND}
        permissions={permissions}
        queryKey={queryKey}
        uuid={uuid as string}
      />

      {hasProducts ? (
        <Card title={translate('TITLE.SHOPPING_CART_DETAILS')}>
          <ShoppingCartDetails
            orderDetails={dataObj.order_details as IShoppingCartOrderDetails}
          />
        </Card>
      ) : null}

      <Card title={translate('TITLE.BILLING_INFORMATION')}>
        <BillingInfo {...billingInfoProps} />
      </Card>

      {showInvoiceDetails ? (
        <Card title={translate('TITLE.INVOICE_INFORMATION')}>
          <InvoiceDetails
            invoiceData={mediobancaSettlementsData}
            orderData={dataObj}
          />
        </Card>
      ) : null}
    </>
  );
};
