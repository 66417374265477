import { useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useLazyLoadTab } from '@hooks/useLazyLoadTab/useLazyLoadTab';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalCrabb } from '@schemas/opsPortal/types/crabb';
import { QueryKeys, TabEventKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getCrabbTableColumns } from './getCrabbTableColumns';
import { mapCrabbTableData } from './mapCrabbTableData';
import { ICrabbTableData } from './types';

export const useCrabbTab = (externalContractUUID?: string) => {
  const { translate } = useI18n();
  const { hasTabBeenClicked } = useLazyLoadTab({
    eventKey: TabEventKeys.CRABB,
  });

  const {
    activateQueryStateHook,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.CRABB);

  const queryKey = [
    QueryKeys.CRABB_DATA,
    externalContractUUID,
    stringifiedQueryParams,
  ];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const [{ data: crabbData, error: crabbError, isLoading: isCrabbLoading }] =
    useQueries<[[IOpsPortalCrabb, AxiosError]]>({
      queries: [
        {
          enabled: Boolean(hasTabBeenClicked),
          queryKey,
          queryFn: () =>
            fetchDataFromEndpoint(
              `${Endpoints.FetchCrabbData}/${externalContractUUID}`,
              {
                query: queryParams,
              },
            ),
        },
      ],
    });

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: crabbData?.next_cursor,
    prevCursor: crabbData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  const crabbTableData = mapCrabbTableData({
    data: crabbData?.results,
  });

  const crabbColumns = getCrabbTableColumns({ translate });

  const refToDataTable = useRef<ITableRefObject<ICrabbTableData>>(null);

  const component = (
    <QueryStateRouter error={crabbError} isLoading={isCrabbLoading}>
      <ServersideTable
        columns={crabbColumns}
        data={crabbTableData}
        isCursorPagination={true}
        manageControlledState={updateQueryParams}
        pagination={{
          gotoNextPage,
          gotoPrevPage,
          pageSize: queryParams.pageSize,
          totalItems: crabbData?.count,
        }}
        ref={refToDataTable}
        resetFilters={resetFilters}
        tableState={queryState}
      />
    </QueryStateRouter>
  );

  return {
    eventKey: TabEventKeys.CRABB,
    error: crabbError,
    isLoading: isCrabbLoading,
    tableName: TableNames.CRABB,
    title: translate('OPS_PORTAL.TITLE.CRABB'),
    component,
    queryKey,
  };
};
