import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Button } from '@components/Common.styles';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';
import { TextField } from '@components/TextField/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IMerchantPortalOrgSettings } from '@schemas/merchantPortal/types/orgSettings';
import { QueryKeys } from '@utils/constants';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage';
import { putDataToEndpoint } from '@utils/putDataToEndpoint/putDataToEndpoint';
import { getValidationSchema } from './getValidationSchema';
import { IOrganisationRequest } from './types';

interface IOrganisationForm {
  data: IMerchantPortalOrgSettings;
  industryChoices: IDropdownOptions[];
  setIsEditing: (isEditing: boolean) => void;
}

export const OrganisationForm = ({
  data,
  industryChoices,
  setIsEditing,
}: IOrganisationForm) => {
  const { translate } = useI18n();

  const {
    billingAddress,
    legalName,
    merchantIndustry,
    primaryColour,
    shortName,
    smsSenderId,
    vatNumber,
  } = objectKeysToCamelCase(
    addPlaceholderForEmptyValues<IOrganisationRequest>(data, ''),
  );

  const initialFormikValues = {
    billing_address: billingAddress,
    legal_name: legalName,
    merchant_industry: merchantIndustry || 0,
    primary_colour: primaryColour,
    short_name: shortName,
    sms_sender_id: smsSenderId,
    vat_number: vatNumber,
  };

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError,
    IOrganisationRequest
  >(
    requestData => putDataToEndpoint({ endpoint: 'org/settings', requestData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORGANISATION_INFO]);
        setIsEditing(false);
      },
      onError: error => {
        // eslint-disable-next-line no-console
        console.error(
          getErrorMessage({
            error,
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    (values: IOrganisationRequest) => {
      mutate(values);
    },
    [mutate],
  );

  if (isLoading) {
    return <div>{translate('STATUS.SUBMITTING_CHANGES')}</div>;
  }

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(initialFormikValues, translate)}
    >
      <Form className="form fv-plugins-bootstrap fv-plugins-framework">
        <TextField
          id="legal_name"
          label={translate('LABEL.LEGAL_NAME')}
          name="legal_name"
        />
        <TextField
          id="short_name"
          label={translate('LABEL.DISPLAY_NAME_GENERAL')}
          name="short_name"
          readOnly={true}
        />
        <TextField
          id="sms_sender_id"
          label={translate('LABEL.DISPLAY_NAME_SMS')}
          name="sms_sender_id"
        />
        <TextField
          id="billing_address"
          label={translate('LABEL.REGISTERED_ADDRESS')}
          name="billing_address"
        />
        <TextField
          id="vat_number"
          label={translate('LABEL.VAT_REGISTRATION_NUMBER')}
          name="vat_number"
        />
        <Dropdown
          id="merchant_industry"
          label={translate('LABEL.INDUSTRY')}
          name="merchant_industry"
          options={industryChoices}
        />
        <TextField
          id="primary_colour"
          label={translate('LABEL.COLOUR_CODE')}
          name="primary_colour"
        />

        <Button align="right" type="submit" variant="primary">
          {translate('BUTTON.SUBMIT')}
        </Button>
      </Form>
    </Formik>
  );
};
