import { format } from 'date-fns';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';

export interface IScheduleVersion {
  id: number;
  name: string;
}

export const getScheduleVersions = (
  monetaContractData: IOpsPortalMonetaContract | undefined,
) => {
  const uniqueScheduleVersions: number[] = [];
  const scheduleVersionsOptions: IScheduleVersion[] = [];

  monetaContractData?.balances.forEach(balance => {
    const scheduleVersion = balance.schedule_version;
    if (!uniqueScheduleVersions.includes(scheduleVersion)) {
      const initialScheduleDate = balance.schedule_date;
      uniqueScheduleVersions.push(scheduleVersion);
      scheduleVersionsOptions.push({
        id: scheduleVersion,
        name: `V${scheduleVersion}${
          initialScheduleDate
            ? ` - ${format(new Date(initialScheduleDate), 'yyyy-MM-dd')}`
            : ''
        }`,
      });
    }
  });

  return scheduleVersionsOptions.sort((a, b) => a.id - b.id);
};
