import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';
import { Translate } from '@hooks/useI18n';
import { IMerchantPortalOrgSettings } from '@schemas/merchantPortal/types/orgSettings';
import { dictionary } from '@utils/dictionary';
import { getDisplayKeys } from '@utils/getDisplayKeys';

interface IGetDisplayDataListProps {
  config: Record<string, unknown>;
  industryChoices: IDropdownOptions[];
  organisationData: IMerchantPortalOrgSettings;
  translate: Translate;
}

export const getDisplayDataList = ({
  config,
  industryChoices,
  organisationData,
  translate,
}: IGetDisplayDataListProps) => {
  // Set keys in a specific order, which is why this doesn't spread organisationData.
  const items = {
    legal_name: organisationData.legal_name,
    short_name: organisationData.short_name,
    ...(config.showOrgSMS
      ? { sms_sender_id: organisationData.sms_sender_id }
      : {}),
    billing_address: organisationData.billing_address,
    vat_number: organisationData.vat_number,
    ...(config.showOrgIndustry
      ? {
          industry: industryChoices.find(
            choice =>
              choice.id === organisationData.merchant_industry?.toString(),
          )?.name,
        }
      : {}),
    ...(config.showOrgPrimaryColour
      ? { primary_colour: organisationData.primary_colour }
      : {}),
  };

  const keys = getDisplayKeys({ data: items, category: 'organisation' });
  const { organisation } = dictionary;

  return keys.map(key =>
    addPlaceholderForEmptyValues<IDataRow>({
      key: translate(organisation[key as keyof typeof organisation]),
      value: items[key as keyof typeof items],
    }),
  );
};
