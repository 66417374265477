import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { validateConditionalRequired } from '@utils/validateConditionalRequired';

interface IGetValidationSchema {
  first_name: string;
  last_name: string;
}

export const getValidationSchema =
  (translate: Translate) =>
  ({ first_name: firstName, last_name: lastName }: IGetValidationSchema) =>
    Yup.object({
      first_name: Yup.string().test(
        'conditionally-required',
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({
            current: value,
            original: firstName,
          }),
      ),
      last_name: Yup.string().test(
        'conditionally-required',
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({ current: value, original: lastName }),
      ),
    });
