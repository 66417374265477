import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { IActionButton } from '@appTypes';
import { Spinner } from '@auth/pages/Auth.styles';
import { Card } from '@components/Card';
import { CSVLink } from '@components/Common.styles';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { useQueryState } from '@hooks/useQueryState';
import { IMerchantPortalSettlementSummary } from '@schemas/merchantPortal/types/settlementSummary';
import { IMerchantPortalSettlementTransactions } from '@schemas/merchantPortal/types/settlementTransactions';
import { selectTimeToAuthExpiry } from '@selectors/index';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import {
  fetchDataFromEndpoint,
  fetchDataFromEndpointCsv,
} from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { addCurrencyToValues } from './addCurrencyToValues';
import { getTransactionsTableColumns } from './getTransactionsTableColumns';
import { prepareSummaryData } from './prepareSummaryData';
import { prepareTransactionsTableData } from './prepareTransactionsTableData';
import { SettlementSummary } from './SettlementSummary';
import { ISettlementTransactionColumns } from './types';

export const SettlementDetails = () => {
  const {
    getLocaleCurrencyValue,
    getLocaleDate,
    selectedLanguage: lang,
    translate,
  } = useI18n();

  const { originator } = useOriginator();

  const { settlementsId } = useParams();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const refToDataTable =
    useRef<ITableRefObject<ISettlementTransactionColumns>>(null);

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.SETTLEMENT_TRANSACTIONS);

  const {
    data: summaryData,
    error: summaryError,
    isLoading: isSummaryLoading,
  } = useQuery<IMerchantPortalSettlementSummary, AxiosError>(
    [QueryKeys.TRANSACTIONS_SUMMARY, settlementsId],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchSettlementSummary}/${settlementsId}`,
      ),
    { cacheTime: msUntilTokenExpiry, staleTime: msUntilTokenExpiry },
  );

  const {
    data: detailsData,
    error: detailsError,
    isLoading: isDetailsLoading,
  } = useQuery<IMerchantPortalSettlementTransactions, AxiosError>(
    [QueryKeys.TRANSACTIONS_TABLE_DATA, settlementsId, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchSettlements}/${settlementsId}`, {
        query: { ...queryParams },
      }),
  );

  const { isLoading: isLoadingCsv, mutate: downloadCsv } = useMutation(() =>
    fetchDataFromEndpointCsv(
      `${Endpoints.DownloadSingleSettlement}/${settlementsId}`,
      { query: { lang, originator } },
      `transactions-${settlementsId}`,
    ),
  );

  const transactionsTableData = useMemo(() => {
    return detailsData?.results
      ? prepareTransactionsTableData({
          data: detailsData.results,
          getLocaleCurrencyValue,
          getLocaleDate,
          translate,
        })
      : [];
  }, [detailsData, getLocaleCurrencyValue, getLocaleDate, translate]);

  const summaryListData =
    transactionsTableData?.length &&
    summaryData &&
    prepareSummaryData({
      getLocaleCurrencyValue,
      getLocaleDate,
      summaryData,
      transactionsTableData,
    });

  const refToCSVLink = useRef<HTMLAnchorElement>(null);

  const currency = transactionsTableData[0]?.currency;

  const transactionsTableDataWithCurrency = currency
    ? addCurrencyToValues({
        getLocaleCurrencyValue,
        tableData: transactionsTableData,
      })
    : transactionsTableData;

  const transactionColumns = useMemo(
    () => getTransactionsTableColumns(translate),
    [translate],
  );

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const filters = getFilters({});

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: detailsData?.next_cursor,
    prevCursor: detailsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  const handleDownloadCsvClick = useCallback(() => {
    downloadCsv();
  }, [downloadCsv]);

  const canViewReconciliation = useHasPermission([
    'merchant_portal.can_view_reconciliation',
  ]);

  const buttons: IActionButton[] = [
    {
      ...(isLoadingCsv
        ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
        : {}),
      clickHandler: handleDownloadCsvClick,
      dependencies: [
        canViewReconciliation,
        Boolean(transactionsTableDataWithCurrency?.length),
      ],
      buttonKey: 'downloadTransactions',
      text: translate('BUTTON.DOWNLOAD_TRANSACTIONS'),
    },
  ];

  return (
    <QueryStateRouter
      error={(detailsError || summaryError) as AxiosError}
      isLoading={isDetailsLoading || isSummaryLoading}
    >
      <>
        <Card title={translate('TITLE.SETTLEMENT_DETAILS')}>
          <SettlementSummary {...summaryListData} />
        </Card>
        <Card buttons={buttons} title={translate('TITLE.TRANSACTIONS')}>
          <CSVLink
            download={`transactions-${settlementsId}`}
            ref={refToCSVLink}
          />
          <ServersideTable
            columns={transactionColumns}
            data={transactionsTableDataWithCurrency}
            filters={filters}
            isCursorPagination={true}
            manageControlledState={updateQueryParams}
            pagination={{
              gotoNextPage,
              gotoPrevPage,
              pageSize: queryParams.pageSize,
              totalItems: detailsData?.count,
            }}
            ref={refToDataTable}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        </Card>
      </>
    </QueryStateRouter>
  );
};
