import * as Yup from 'yup';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { validateConditionalRequired } from '@utils/validateConditionalRequired';
import { IOrganisationRequest } from './types';

const conditionallyRequired = 'conditionally-required';

export const getValidationSchema = (
  data: IOrganisationRequest,
  translate: Translate,
) => {
  const {
    billingAddress,
    legalName,
    merchantIndustry,
    primaryColour,
    shortName,
    smsSenderId,
    vatNumber,
  } = objectKeysToCamelCase(data);

  return Yup.object({
    billing_address: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({
          current: value,
          original: billingAddress,
        }),
    ),
    merchant_industry: Yup.number()
      .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
      .test(
        conditionallyRequired,
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({
            current: value,
            original: merchantIndustry,
          }),
      ),
    legal_name: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: legalName }),
    ),
    primary_colour: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({
          current: value,
          original: primaryColour,
        }),
    ),
    short_name: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: shortName }),
    ),
    sms_sender_id: Yup.string()
      .max(10, translate(ValidationErrorKeys.Max10Characters))
      .test(
        conditionallyRequired,
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({
            current: value,
            original: smsSenderId,
          }),
      ),
    vat_number: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: vatNumber }),
    ),
  });
};
