import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useAddNoteModal } from '@hooks/useModal/hooks/useAddNoteModal';
import { IOpsPortalNotes } from '@schemas/opsPortal/types/notes';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getNotesColumns } from './getNotesColumns';
import { getOptions } from './getOptions';
import { mapNotesTableData } from './mapNotesTableData';
import { INotesProps, INotesTableDataRow } from './types';

export const Notes = ({ uuid }: INotesProps) => {
  const { getLocaleDate, translate } = useI18n();

  const {
    data: notesData,
    error,
    isLoading,
  } = useQuery<IOpsPortalNotes, AxiosError>([QueryKeys.NOTES_DATA, uuid], () =>
    fetchDataFromEndpoint(`${Endpoints.FetchNotes}/${uuid}`),
  );

  const columns = useMemo(() => getNotesColumns(translate), [translate]);
  const tableData: INotesTableDataRow[] =
    error ?? !notesData
      ? []
      : mapNotesTableData({
          getLocaleDate,
          notesData,
          translate,
        });

  const dropdownOptions = getOptions(tableData);

  const AddNoteModal = useAddNoteModal({ uuid });

  return (
    <Card
      buttons={AddNoteModal ? [AddNoteModal.button] : []}
      title={translate('OPS_PORTAL.TITLE.NOTES')}
    >
      <QueryStateRouter error={error} isLoading={isLoading}>
        <ClientsideTable
          columns={columns}
          data={tableData}
          tableName={TableNames.NOTES}
          tableSpecificProps={{
            createdByOptions: dropdownOptions,
          }}
        />
      </QueryStateRouter>
    </Card>
  );
};
