import { CommunicationsTypes, FilterTypes, TableNames } from '@utils/constants';
import { getBankAccountTransactionFilters } from './getBankAccountTransactionFilters';
import { getDateFilter } from './getDateFilter';
import { getEventTimelineFilters } from './getEventTimelineFilters';
import { getLanguageDropdown } from './getLanguageDropdown';
import { getMerchantsDropdown } from './getMerchantsDropdown';
import { getOriginationChannelFilter } from './getOriginationChannelFilter';
import { getSearchField } from './getSearchField';
import { getStatusDropdown } from './getStatusDropdown';
import { IFilter, IFiltersByTable, IFiltersProps } from './types';

export const getFilters = ({
  queryParams,
  tableName,
  translate,
  ...tableSpecificProps
}: IFiltersProps): IFilter[] => {
  const {
    createdByOptions,
    isCompass,
    isMerchantPortal,
    isOpsPortal,
    merchantsOptions,
  } = tableSpecificProps;

  const { activityStatusDropdown, performanceStatusDropdown } =
    getStatusDropdown({ queryParams, isMerchantPortal, tableName, translate });

  const {
    allocationStatusDropdown,
    allocationTypeDropdown,
    pendingDropdown,
    unbalancedAllocationsDateFilter,
    unmatchedDropdown,
  } = getBankAccountTransactionFilters({
    queryParams,
    translate,
  });

  const {
    communicationDirectionDropdown,
    communicationStatusDropdown,
    communicationTypeDropdown,
    eventTypeDropdown,
    paymentCaptureStatusDropdown,
    paymentProcessingStatusDropdown,
  } = getEventTimelineFilters({ translate });

  const communicationsDropdown = {
    accessorKey: 'communicationType',
    id: 'communicationType',
    label: translate('OPS_PORTAL.PAGE.COMMUNICATIONS.COMMUNICATION_TYPE'),
    options: [
      { id: '', name: '—' },
      ...Object.keys(CommunicationsTypes).map(key => ({
        id: CommunicationsTypes[key as keyof typeof CommunicationsTypes],
        name: translate(
          `OPS_PORTAL.PAGE.COMMUNICATIONS.${CommunicationsTypes[
            key as keyof typeof CommunicationsTypes
          ].toUpperCase()}`,
        ),
      })),
    ],
    type: FilterTypes.DROPDOWN,
    value: String(queryParams?.communicationType),
  };

  const createdByDropdown = {
    accessorKey: 'createdBy',
    id: 'createdBy',
    label: translate('OPS_PORTAL.TITLE.CREATED_BY'),
    options: createdByOptions,
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const noteTypeDropdown = {
    accessorKey: 'noteType',
    id: 'noteType',
    label: translate('TITLE.TYPE'),
    options: [
      { id: '', name: '—' },
      {
        id: 'Communications',
        name: translate('OPS_PORTAL.TITLE.COMMUNICATIONS'),
      },
      {
        id: 'Manual Note',
        name: translate('OPS_PORTAL.TITLE.MANUAL_NOTE'),
      },
      {
        id: 'ZenDesk Event',
        name: translate('OPS_PORTAL.TITLE.ZENDESK_EVENT'),
      },
    ],
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const responseCodeDropdown = {
    accessorKey: 'responseCode',
    id: 'responseCode',
    label: translate('OPS_PORTAL.LABEL.RESPONSE_CODE'),
    options: [
      { id: '', name: '—' },
      ...['200', '201', '400', '401', '403', '500'].map(code => ({
        id: code,
        name: code,
      })),
    ],
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const dateFilter = getDateFilter({ queryParams, tableName, translate });

  const languageDropdown = getLanguageDropdown({ queryParams, translate });

  const merchantsDropdown = getMerchantsDropdown({
    queryParams,
    merchantsOptions,
    translate,
  });

  const originationChannelDropdown = getOriginationChannelFilter({
    isCompass,
    queryParams,
    translate,
  });

  const searchField = getSearchField({ queryParams, tableName, translate });

  const filtersByTable: IFiltersByTable = {
    [TableNames.API_CALLS]: [
      merchantsDropdown,
      responseCodeDropdown,
      dateFilter,
      searchField,
    ],
    [TableNames.APPLICATIONS]: [
      ...(isOpsPortal ? [merchantsDropdown] : []),
      activityStatusDropdown,
      ...(isOpsPortal ? [languageDropdown] : []),
      dateFilter,
      searchField,
    ],
    [TableNames.AWAITING_DISPATCH]: [
      merchantsDropdown,
      dateFilter,
      searchField,
    ],
    [TableNames.BANK_ACCOUNT_RECONCILIATIONS]: [
      dateFilter,
      allocationStatusDropdown,
    ],
    [TableNames.BLOCKED_APPLICANTS]: [searchField, dateFilter],
    [TableNames.COLLECTIONS]: [
      merchantsDropdown,
      performanceStatusDropdown,
      searchField,
    ],
    [TableNames.COLLECTIONS_QUEUE]: [languageDropdown, searchField],
    [TableNames.COMMUNICATIONS]: [
      communicationsDropdown,
      dateFilter,
      searchField,
    ],
    [TableNames.CONSUMERS]: [searchField],
    [TableNames.CONTRACTS]: [
      merchantsDropdown,
      activityStatusDropdown,
      dateFilter,
      searchField,
    ],
    [TableNames.EVENTS_TIMELINE]: [
      searchField,
      dateFilter,
      eventTypeDropdown,
      communicationDirectionDropdown,
      communicationStatusDropdown,
      communicationTypeDropdown,
      paymentCaptureStatusDropdown,
      paymentProcessingStatusDropdown,
    ],
    [TableNames.MEDIOBANCA_RECONCILIATIONS]: [dateFilter],
    [TableNames.MERCHANTS]: [searchField],
    [TableNames.MERCHANT_STORES]: [searchField],
    [TableNames.NOTES]: [
      dateFilter,
      createdByDropdown,
      noteTypeDropdown,
      searchField,
    ],
    [TableNames.ORDERS]: [dateFilter, originationChannelDropdown, searchField],
    [TableNames.SETTLEMENTS]: [dateFilter],
    [TableNames.SUSPENSE]: [dateFilter, allocationStatusDropdown],
    [TableNames.PENDING_ALLOCATIONS]: [dateFilter, allocationTypeDropdown],
    [TableNames.UNBALANCED_ALLOCATIONS]: [
      unbalancedAllocationsDateFilter,
      pendingDropdown,
      unmatchedDropdown,
    ],
    [TableNames.USERS]: [dateFilter, searchField],
  };

  return filtersByTable[tableName] ?? [];
};
