import Box from '@mui/material/Box';
import { styled, SxProps } from '@mui/material/styles';
import { LoadingSpinner } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';

interface ILoading {
  messageKey?: string;
  showSpinner?: boolean;
  sx?: SxProps;
}

const LoadingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const Loading = ({ messageKey, showSpinner, sx = {} }: ILoading) => {
  const { translate } = useI18n();
  return (
    <LoadingContainer sx={sx}>
      {translate(messageKey ?? 'STATUS.LOADING')}
      {showSpinner ? <LoadingSpinner /> : null}
    </LoadingContainer>
  );
};
