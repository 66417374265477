import { IMappedPaymentScheduleHistoryTableData } from '../../../utils/types';

interface IGetScheduleHistoryDataPerUniqueDate {
  scheduleHistoryData: IMappedPaymentScheduleHistoryTableData[];
}

export const getUniqueScheduleHistoryPaymentData = ({
  scheduleHistoryData,
}: IGetScheduleHistoryDataPerUniqueDate) => {
  const uniquePaymentDateEntries: IMappedPaymentScheduleHistoryTableData[] = [];

  scheduleHistoryData.forEach(data => {
    const existingEntryIndex = uniquePaymentDateEntries.findIndex(
      entry => entry.paymentDate === data.paymentDate,
    );

    if (existingEntryIndex === -1) {
      uniquePaymentDateEntries.push(data);
    } else {
      // If there's an existing entry, replace it if the new one has a higher version
      const existingEntry = uniquePaymentDateEntries[existingEntryIndex];
      if (
        Number(data.scheduleVersion) > Number(existingEntry.scheduleVersion)
      ) {
        uniquePaymentDateEntries[existingEntryIndex] = data;
      }
    }
  });

  return uniquePaymentDateEntries.sort(
    (a, b) =>
      new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime(),
  );
};
