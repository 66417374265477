import { useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { formatQueryParams } from '../helpers/utils';

interface IUseSyncSortSearchParamsEffectParams {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  setSyncedSearchParams?: (params: string) => void;
  sortSearchParam?: ISortingParam;
}

export const useSyncSortSearchParamsEffect = ({
  searchParams,
  setSearchParams,
  setSyncedSearchParams,
  sortSearchParam,
}: IUseSyncSortSearchParamsEffectParams) => {
  useEffect(() => {
    const formattedQueryParams = formatQueryParams({
      ...Object.fromEntries(searchParams),
      ...sortSearchParam,
    });
    setSearchParams(formattedQueryParams);
    setSyncedSearchParams?.(formattedQueryParams);
  }, [sortSearchParam, searchParams, setSearchParams, setSyncedSearchParams]);
};
