import { IQueryState, QueryParams } from './types';

interface IGetStateFromQueryParamsProps {
  initialState: IQueryState;
  searchParams: QueryParams;
}

export const getStateFromQueryParams = ({
  initialState,
  searchParams,
}: IGetStateFromQueryParamsProps): IQueryState =>
  Object.keys(searchParams).reduce((stateObj, key) => {
    const value = searchParams[key];

    if (key === 'search') {
      return { ...stateObj, globalFilter: value };
    }

    if (key === 'cursor') {
      return {
        ...stateObj,
        cursor: value,
      };
    }

    if (key === 'limit') {
      return {
        ...stateObj,
        pagination: { ...stateObj.pagination, pageSize: Number(value) },
      };
    }

    if (key === 'offset') {
      return {
        ...stateObj,
        pagination: { ...stateObj.pagination, pageIndex: Number(value) },
      };
    }

    if (key === 'sorting') {
      return {
        ...stateObj,
        sorting: value.split(',').map((sortedCol: string) => ({
          id: sortedCol.replace('-', ''),
          desc: sortedCol.startsWith('-'),
        })),
      };
    }

    const isDateFrom = key.endsWith('From');
    const isDateTo = key.endsWith('To');

    let filteredColumns;

    if (isDateFrom || isDateTo) {
      const filterKey = key.replace(/From|To/, '');
      const dateRange = [
        ...(stateObj.columnFilters?.find(col => col.id === filterKey)?.value ??
          []),
      ];

      if (isDateFrom) {
        dateRange[0] = value;
      } else {
        dateRange[1] = value;
      }

      filteredColumns = stateObj.columnFilters?.filter(
        col => col.id && col.id !== filterKey,
      );

      return {
        ...stateObj,
        columnFilters: [
          ...(filteredColumns ?? []),
          { id: filterKey, value: dateRange },
        ],
      };
    }

    filteredColumns = stateObj.columnFilters?.filter(col => col.id !== key);

    return {
      ...stateObj,
      columnFilters: [...(filteredColumns ?? []), { id: key, value }],
    };
  }, initialState);
