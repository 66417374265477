import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

interface IUpdateOrderingModel {
  handleSortModelChange?: (sortModel: GridSortModel) => void;
  ordering?: string;
}
export const updateOrderingModel = ({
  handleSortModelChange,
  ordering,
}: IUpdateOrderingModel) => {
  if (ordering) {
    const isDescending = ordering.startsWith('-');
    const sortDirection = isDescending ? 'desc' : 'asc';
    const loadedSortModel: GridSortModel = [
      {
        field: isDescending ? ordering.replace(/^-+/, '') : ordering,
        sort: ordering ? sortDirection : undefined,
      },
    ];
    handleSortModelChange?.(loadedSortModel);
  } else {
    handleSortModelChange?.([]);
  }
};
