import { useEffect, useState } from 'react';
import { Header } from '@tanstack/react-table';
import { SortDirection } from '@utils/constants';
import { SortButton } from './SortButton';
import { FlexRender, SortOrder } from './types';

interface IClientsideHeader<T> {
  flexRender?: FlexRender;
  header: Header<T, unknown>;
}

export const ClientsideHeader = <T,>({
  flexRender,
  header,
}: IClientsideHeader<T>) => {
  const [sortState, setSortState] = useState<SortOrder>();

  const sort = header.column.getIsSorted();

  useEffect(() => {
    setSortState(sort ? SortDirection[sort] : undefined);
  }, [sort]);

  return (
    <th aria-sort={sortState} key={header.id}>
      <SortButton
        sort={sortState}
        toggleSort={header.column.getToggleSortingHandler()}
      >
        {flexRender?.(header.column.columnDef.header, header.getContext())}
      </SortButton>
    </th>
  );
};
