import { Formik, Form } from 'formik';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { TextField } from '@components/TextField/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IConsumerPortalAccountSettings } from '@schemas/consumerPortal/types/accountSettings';
import { IMerchantPortalAccountSettings } from '@schemas/merchantPortal/types/accountSettings';
import { IOpsPortalAccountSettings } from '@schemas/opsPortal/types/accountSettings';
import { getPhoneValidationSchema } from './getValidationSchema';

type IAccountSettings =
  | IMerchantPortalAccountSettings
  | IConsumerPortalAccountSettings
  | IOpsPortalAccountSettings;

interface IRequestObj {
  language: string;
  phone_number: string;
}

interface IEditPhoneForm {
  data?: IAccountSettings;
  formID: string;
  handleSubmit: (data: IRequestObj) => void;
}

export const EditPhoneForm = ({
  data,
  formID,
  handleSubmit,
}: IEditPhoneForm) => {
  const { selectedLanguage, translate } = useI18n();

  const { phoneNumber } = objectKeysToCamelCase(
    addPlaceholderForEmptyValues<IRequestObj>(data ?? {}, ''),
  );

  const initialFormikValues: IRequestObj = {
    phone_number: phoneNumber,
    language: selectedLanguage,
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getPhoneValidationSchema({ translate, phoneNumber })}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="phone_number"
          label={translate('LABEL.PHONE_NUMBER')}
          name="phone_number"
        />
      </Form>
    </Formik>
  );
};
