import { ContractStatuses, TableNames } from '@utils/constants';
import { formatDaysAgo } from '@utils/formatDaysAgo';
import { ITableDefaults, ITableDefaultsProps } from './types';

const { AWAITING_DISPATCH } = ContractStatuses;

const today = formatDaysAgo(0);

const tableDefaults: ITableDefaults = {
  [TableNames.API_CALLS]: () => ({
    columnFilters: [
      {
        id: 'timestamp',
        value: [formatDaysAgo(1), today],
      },
    ],
  }),
  [TableNames.AWAITING_DISPATCH]: () => ({
    columnFilters: [{ id: 'activityStatus', value: AWAITING_DISPATCH }],
  }),
  [TableNames.BANK_ACCOUNT_RECONCILIATIONS]: () => ({
    columnFilters: [
      {
        id: 'transactionDate',
        value: [formatDaysAgo(1), today],
      },
    ],
  }),
  [TableNames.CONTRACTS]: () => ({
    columnFilters: [
      {
        id: 'activityStatus',
        value: '',
      },
    ],
  }),
  [TableNames.MEDIOBANCA_RECONCILIATIONS]: () => ({
    columnFilters: [
      {
        id: 'settlementDate',
        value: [formatDaysAgo(7), today],
      },
    ],
  }),
  [TableNames.UNBALANCED_ALLOCATIONS]: () => ({
    columnFilters: [
      { id: 'pending', value: 'false' },
      { id: 'unmatched', value: 'true' },
    ],
  }),
};

export const getTableDefaults = ({
  initialQueryState,
  tableName,
  tableSpecificDefaults,
}: ITableDefaultsProps) => ({
  ...initialQueryState,
  ...(tableDefaults[tableName]?.(tableSpecificDefaults) ?? {}),
});
