import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { TransactionsTableData, TransactionsTableDataRow } from './types';

interface IAddCurrencyToValuesProps {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  tableData: TransactionsTableData;
}

const receivables = ['signedAmount'];
const isReceivable = (key: string) =>
  receivables.some(receivable => receivable === key);

export const addCurrencyToValues = ({
  getLocaleCurrencyValue,
  tableData,
}: IAddCurrencyToValuesProps): TransactionsTableData =>
  tableData.map(dataRow => {
    const { currency } = dataRow;

    return addPlaceholderForEmptyValues<TransactionsTableDataRow>(
      Object.keys(dataRow).reduce((newDataRow, key) => {
        return {
          ...newDataRow,
          [key]: isReceivable(key)
            ? getLocaleCurrencyValue({
                currency,
                value: dataRow[key as keyof typeof dataRow],
              })
            : dataRow[key as keyof typeof dataRow],
        };
      }, {}),
    );
  });
