import { IAmountObject } from '@schemas/opsPortal/types/contractDetails';

export const getSystemRemainder = (
  totalBalance?: IAmountObject,
  totalMovement?: IAmountObject,
) => {
  const systemRemainderAmount =
    totalBalance && totalMovement
      ? (
          parseFloat(totalBalance.amount) - parseFloat(totalMovement.amount)
        ).toString()
      : undefined;

  return {
    amount: systemRemainderAmount,
    currency: totalBalance?.currency,
  } as IAmountObject;
};
