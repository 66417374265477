import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { styled } from '@mui/material/styles';
import { missingValuePlaceholder } from '@utils/constants';

export const MerchantLogoImg = styled('img')({
  maxWidth: '120px',
  maxHeight: '60px',
  margin: '5px 0',
});

interface IMerchantLogo {
  logo?: Nullable<string>;
}

export const MerchantLogo = ({ logo }: IMerchantLogo) =>
  logo ? (
    <MerchantLogoImg alt="logo" src={logo} />
  ) : (
    <>{missingValuePlaceholder}</>
  );
