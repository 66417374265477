import { useCallback } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { mapObject } from '@heidi-pay/heidi-common-fe/utils/mapObject';
import {
  CursorPaginationModel,
  ICursors,
} from '@hooks/useCursorPagination/types';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { formatQueryParams, removeEmptyKeys } from '../helpers/utils';
import { PaginationParams } from '../types';

export interface IHandleCursorPaginationChangeParams<TFilterValues> {
  cursors?: ICursors;
  filterValues?: TFilterValues;
  handlePagination: (params: CursorPaginationModel) => void;
  pageSize: number;
  paginationParams: PaginationParams;
  setSearchParams: SetURLSearchParams;
  setSyncedSearchParams: (params: string) => void;
  sortSearchParam?: ISortingParam;
}

export const useCursorPaginationChangeHandler = <TFilterValues>({
  cursors,
  filterValues,
  handlePagination,
  pageSize,
  setSearchParams,
  setSyncedSearchParams,
  sortSearchParam,
}: IHandleCursorPaginationChangeParams<TFilterValues>) =>
  useCallback(
    ({
      isNextClicked,
      pageSize: newPageSize,
      reset = false,
    }: CursorPaginationModel) => {
      const updatedlimit = newPageSize ?? pageSize;
      const changePageCursorParam =
        (isNextClicked ? cursors?.nextCursor : cursors?.prevCursor) ?? '';
      const updatedPaginationParamsWithFiltersForURL = removeEmptyKeys({
        ...filterValues,
        ...sortSearchParam,
        cursor: reset ? '' : changePageCursorParam,
        limit: updatedlimit.toString(),
      });

      const urlSearchParams = mapObject(
        updatedPaginationParamsWithFiltersForURL,
        ([key, value]) => [key, String(value)],
      );
      handlePagination({ pageSize: updatedlimit, isNextClicked, reset });

      if (!reset) {
        const formattedQueryParams = formatQueryParams(urlSearchParams);
        setSyncedSearchParams(formattedQueryParams);
        setSearchParams(formattedQueryParams);
      }
    },
    [
      cursors?.nextCursor,
      cursors?.prevCursor,
      filterValues,
      handlePagination,
      pageSize,
      setSearchParams,
      setSyncedSearchParams,
      sortSearchParam,
    ],
  );
