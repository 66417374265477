import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { IApiInitCalls, IApiCall, IMapDataProps } from './types';

export const mapData = ({
  data,
  getLocaleDate,
}: IMapDataProps): IApiInitCalls[] =>
  data?.map((dataRow: IApiCall) => {
    const {
      application_reference: applicationReference,
      consumer_name: consumerName,
      consumer_uuid: consumerUUID,
      external_contract_uuid: applicationUUID,
      merchant_name: merchantName,
      merchant_uuid: merchantUUID,
      response_code: responseCode,
      timestamp,
      uuid: apiCallUUID,
    } = dataRow;

    return addPlaceholderForEmptyValues<IApiInitCalls>({
      applicationReference,
      applicationUUID,
      consumerName,
      consumerUUID,
      merchantName,
      merchantUUID,
      responseCode: responseCode.toString(),
      timestamp: timestamp
        ? getLocaleDate({ date: timestamp, includeTime: true })
        : '',
      apiCallUUID,
    });
  }) ?? [];
