import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { IActionButton, ValueOf } from '@appTypes';
import { Spinner } from '@auth/pages/Auth.styles';
import { Card } from '@components/Card';
import { CSVLink } from '@components/Common.styles';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useConfirmDispatchModal } from '@hooks/useModal/hooks/useConfirmDispatchModal';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { useQueryState } from '@hooks/useQueryState';
import { IConsumerPortalOrders } from '@schemas/consumerPortal/types/orders';
import { IMerchantPortalOrders } from '@schemas/merchantPortal/types/orders';
import { QueryKeys, TableNames, Views } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import {
  fetchDataFromEndpoint,
  fetchDataFromEndpointCsv,
} from '@utils/fetchDataFromEndpoint/index';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { getLocalTimezone } from '@utils/getLocalTimezone';
import { OrderView } from '../enums';
import { getOrdersTableColumns } from './getOrdersTableColumns';
import { prepareOrderTableData } from './prepareOrderTableData';

interface IOrders {
  myOrders?: boolean;
  view?: ValueOf<typeof Views>;
}

export interface IViewConfig {
  csvEndpoint: string;
  endpoint: string;
  queryKey: ValueOf<typeof QueryKeys>;
  queryParam: Record<string, string>;
  title: string;
}

const viewConfig: Record<OrderView, IViewConfig> = {
  [OrderView.Order]: {
    title: 'TITLE.ORDERS',
    queryParam: {},
    endpoint: Endpoints.FetchOrders,
    queryKey: QueryKeys.ORDERS_TABLE_DATA,
    csvEndpoint: Endpoints.FetchOrdersCsv,
  },
  [OrderView.AwaitingDispatch]: {
    title: 'STATUS.AWAITING_CONFIRMATION',
    queryParam: {},
    endpoint: Endpoints.FetchOrdersAwaitingDispatch,
    queryKey: QueryKeys.AWAITING_DISPATCH_TABLE_DATA,
    csvEndpoint: Endpoints.FetchOrdersAwaitingDispatchCsv,
  },
  [OrderView.MyOrder]: {
    title: 'TITLE.MY_ORDERS',
    queryParam: {},
    endpoint: Endpoints.FetchMyOrders,
    queryKey: QueryKeys.MY_ORDERS_TABLE_DATA,
    csvEndpoint: Endpoints.FetchMyOrdersCsv,
  },
};

const getOrderView = (isAwaitingDispatch: boolean, myOrders: boolean) => {
  if (isAwaitingDispatch) {
    return OrderView.AwaitingDispatch;
  }
  if (myOrders) {
    return OrderView.MyOrder;
  }
  return OrderView.Order;
};

export const Orders = ({
  myOrders = false,
  view = Views.CONTRACTS,
}: IOrders) => {
  const {
    getLocaleCurrencyValue,
    getLocaleDate,
    selectedLanguage: lang,
    translate,
  } = useI18n();
  const { isCompass, isPagolightPro, originator } = useOriginator();
  const { isConsumerPortal, isMerchantPortal } = usePortal();
  const isAwaitingDispatch = view === Views.AWAITING_DISPATCH;

  const { csvEndpoint, endpoint, queryKey, title } =
    viewConfig[getOrderView(isAwaitingDispatch, myOrders)];

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.ORDERS);

  const { data, error, isLoading } = useQuery<
    IMerchantPortalOrders | IConsumerPortalOrders,
    AxiosError
  >([queryKey, stringifiedQueryParams, originator], () =>
    fetchDataFromEndpoint(endpoint, {
      query: { ...queryParams, lang, originator },
    }),
  );

  const [dispatchedUuid, setDispatchedUuid] = useState('');

  const { isLoading: isLoadingCsv, mutate: downloadCsv } = useMutation(() =>
    fetchDataFromEndpointCsv(
      csvEndpoint,
      {
        query: {
          ...queryParams,
          lang,
          originator,
          timezone: getLocalTimezone(),
        },
      },
      'orders',
    ),
  );

  const confirmDispatchModal = useConfirmDispatchModal({
    uuid: dispatchedUuid,
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  });

  const columns = useMemo(
    () =>
      getOrdersTableColumns({
        button: confirmDispatchModal?.button,
        isAwaitingDispatch,
        isCompass,
        isConsumerPortal,
        isMerchantPortal,
        isPagolightPro,
        setDispatchedUuid,
        translate,
      }),
    [
      confirmDispatchModal?.button,
      isAwaitingDispatch,
      isCompass,
      isConsumerPortal,
      isMerchantPortal,
      isPagolightPro,
      translate,
    ],
  );

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const ordersTableData = prepareOrderTableData({
    data: data?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    isConsumerPortal,
    translate,
  });

  const refToCSVLink = useRef(null);
  const refToDataTable = useRef(null);

  const showDownloadButton = Boolean(
    !isConsumerPortal && ordersTableData?.length,
  );

  const handleDownloadCsv = useCallback(() => downloadCsv(), [downloadCsv]);

  const buttons: IActionButton[] = [
    {
      ...(isLoadingCsv
        ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
        : {}),
      clickHandler: handleDownloadCsv,
      dependencies: [showDownloadButton],
      buttonKey: 'downloadCSV',
      text: translate('BUTTON.DOWNLOAD_ORDERS'),
    },
  ];

  const cardTitle = translate(
    isAwaitingDispatch ? 'STATUS.AWAITING_CONFIRMATION' : title,
  );

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: data?.next_cursor,
    prevCursor: data?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  const filters = getFilters({ isCompass });

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <Card buttons={buttons} title={cardTitle}>
        <CSVLink download="orders" ref={refToCSVLink} />
        <ServersideTable
          columns={columns}
          data={ordersTableData}
          filters={filters}
          isCursorPagination={true}
          manageControlledState={updateQueryParams}
          pagination={{
            gotoNextPage,
            gotoPrevPage,
            pageSize: queryParams.pageSize,
            totalItems: data?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </Card>
    </QueryStateRouter>
  );
};
