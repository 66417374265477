import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQuery } from '@tanstack/react-query';
import { IActionButton } from '@appTypes';
import { Card } from '@components/Card';
import { DataDisplay } from '@components/DataDisplay/DataDisplay';
import { IDropdownOptions } from '@components/Dropdown/DropdownRHF';
import { Loading } from '@components/Loading/Loading';
import { useConfig } from '@hooks/useConfig/useConfig';
import { useHasPermission } from '@hooks/useHasPermission/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IMerchantPortalIndustries } from '@schemas/merchantPortal/types/industries';
import { IMerchantPortalOrgSettings } from '@schemas/merchantPortal/types/orgSettings';
import { selectTimeToAuthExpiry } from '@selectors/selectTimeToAuthExpiry';
import { QueryKeys } from '@utils/constants';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage';
import { getDisplayDataList } from './getDisplayDataList';
import { OrganisationForm } from './OrganisationForm';

export const Organisation = () => {
  const { translate } = useI18n();
  const config = useConfig();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const {
    data: industryChoicesData,
    error: industryChoicesError,
    isLoading: isLoadingIndustryChoices,
  } = useQuery<IMerchantPortalIndustries, AxiosError<IError>>(
    [QueryKeys.INDUSTRY_CHOICES],
    () => fetchDataFromEndpoint('org/industries'),
    {
      cacheTime: msUntilTokenExpiry,
      staleTime: msUntilTokenExpiry,
    },
  );

  const {
    data: organisationData,
    error: organisationError,
    isLoading: isLoadingOrganisation,
  } = useQuery<IMerchantPortalOrgSettings, AxiosError<IError>>(
    [QueryKeys.ORGANISATION_INFO],
    () => fetchDataFromEndpoint('org/settings'),
    {
      cacheTime: msUntilTokenExpiry,
      staleTime: msUntilTokenExpiry,
    },
  );

  const canEditMerchantSettings = useHasPermission([
    'merchant_portal.can_edit_merchant_settings',
  ]);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancelClick = useCallback(
    () => setIsEditing(false),
    [setIsEditing],
  );
  const handleEditClick = useCallback(() => setIsEditing(true), [setIsEditing]);

  if (isLoadingIndustryChoices || isLoadingOrganisation) {
    return <Loading />;
  }

  if (industryChoicesError || organisationError) {
    return (
      <p>
        {getErrorMessage({
          error: (industryChoicesError || organisationError) as AxiosError,
          translate,
        })}
      </p>
    );
  }

  const cancelButton = {
    buttonKey: 'cancelEditOrgInfo',
    clickHandler: handleCancelClick,
    dependencies: [canEditMerchantSettings, Boolean(config.canEditOrgInfo)],
    text: translate('BUTTON.CANCEL'),
    variant: 'secondary',
  };
  const editButton = {
    buttonKey: 'editOrgInfo',
    clickHandler: handleEditClick,
    dependencies: [canEditMerchantSettings, Boolean(config.canEditOrgInfo)],
    text: translate('BUTTON.EDIT'),
  };

  const button: IActionButton = isEditing ? cancelButton : editButton;

  const industryChoices: IDropdownOptions[] = industryChoicesData.map(
    choice => ({
      id: choice.id.toString(),
      name: choice.industry_name ?? '',
    }),
  );

  const dataList = getDisplayDataList({
    config,
    industryChoices,
    organisationData,
    translate,
  });

  return (
    <Card buttons={[button]} title={translate('TITLE.ORG_INFO')}>
      {isEditing ? (
        <OrganisationForm
          data={organisationData}
          industryChoices={industryChoices}
          setIsEditing={setIsEditing}
        />
      ) : (
        <DataDisplay dataList={dataList} />
      )}
    </Card>
  );
};
