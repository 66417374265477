import * as Yup from 'yup';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { AuthField, Schemas } from './constants';

interface IUseGetAuthForm {
  fields: Array<AuthField>;
  isLogin: boolean;
}

export const useGetAuthFormDetails = ({ fields, isLogin }: IUseGetAuthForm) => {
  const { translate } = useI18n();

  const labels = {
    email: translate('LABEL.EMAIL_ADDRESS'),
    password: translate('LABEL.PASSWORD'),
    passwordconfirm: translate('LABEL.CONFIRM_PASSWORD'),
  };

  const validations = {
    email: Yup.string()
      .email(translate(ValidationErrorKeys.InvalidEmailFormat))
      .required(translate(ValidationErrorKeys.Required)),
    password: Yup.string()
      .required(translate(ValidationErrorKeys.Required))
      .min(8, translate(ValidationErrorKeys.MinCharsX, { min: 8 }))
      .max(250, translate(ValidationErrorKeys.MaxCharsX, { max: 250 }))
      .matches(/[a-z]+/, translate(ValidationErrorKeys.MinLowerCase))
      .matches(/[A-Z]+/, translate(ValidationErrorKeys.MinUpperCase))
      .matches(/\d+/, translate(ValidationErrorKeys.MinNumbers))
      .matches(/[^a-zA-Z\d\s]/, translate(ValidationErrorKeys.MinSymbols)),
    passwordconfirm: Yup.string().test(
      'passwords-match',
      translate(ValidationErrorKeys.PasswordsMustMatch),
      function confirmPasswords(value) {
        return this.parent.password === value;
      },
    ),
    passwordExisting: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
  };

  const schemaShape: Schemas = Object.fromEntries(
    fields.map(field => [
      field,
      isLogin && field === 'password'
        ? validations.passwordExisting
        : validations[field],
    ]),
  );

  return {
    schemaShape,
    validations,
    labels,
  };
};
