import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ButtonWrapper,
  AuthButtonWrapper,
  Spinner,
  SpinnerButton,
} from '@auth/pages/Auth.styles';
import { Button } from '@components/Button';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AuthPathnames } from '@utils/constants';
import { ButtonVariant } from '@utils/enums';
import { OriginatorRouterLink } from '../OriginatorRouterLink';
import { AuthFormFields } from './AuthFormFields';
import { IAuthFormProps } from './types';
import { useGetAuthFormDetails } from './useGetAuthFormDetails';

export const AuthForm = ({
  fields,
  handleSubmit,
  initialValues,
}: IAuthFormProps) => {
  const { translate } = useI18n();

  const { labels, schemaShape } = useGetAuthFormDetails({
    fields,
    isLogin: false,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(schemaShape)}
    >
      {({ errors, isSubmitting, status, touched }) => {
        return (
          <Form>
            <AuthFormFields
              errors={errors}
              fields={fields}
              labels={labels}
              status={status}
              touched={touched}
            />
            <AuthButtonWrapper justifyContent="flex-end">
              <ButtonWrapper>
                <Button
                  as={OriginatorRouterLink}
                  to={AuthPathnames.LOGIN}
                  variant={ButtonVariant.Secondary}
                >
                  {translate('BUTTON.CANCEL')}
                </Button>
                <SpinnerButton
                  disabled={isSubmitting}
                  id="kt_login_signin_submit"
                  type="submit"
                  variant={ButtonVariant.Primary}
                >
                  <span>{translate('BUTTON.SUBMIT')}</span>
                  {isSubmitting ? <Spinner /> : null}
                </SpinnerButton>
              </ButtonWrapper>
            </AuthButtonWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
