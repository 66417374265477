import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IDocument as IConsumerDocument } from '@schemas/consumerPortal/types/documents';
import { IDocumentsTableColumn } from './types';

interface IMapOverpaidContractTableData {
  data?: IConsumerDocument[];
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapDocumentsTableData = ({
  data,
  getLocaleDate,
  translate,
}: IMapOverpaidContractTableData): IDocumentsTableColumn[] =>
  data?.map((dataRow: IConsumerDocument) => {
    const { created, type, uuid } = dataRow;

    return addPlaceholderForEmptyValues<IDocumentsTableColumn>({
      documentType: type,
      documentTypeDesc: translate(`LABEL.${type.toUpperCase()}`),
      ...('path_signed' in dataRow
        ? {
            isSigned: translate(
              dataRow.path_signed ? 'GENERAL.YES' : 'GENERAL.NO',
            ),
          }
        : {}),
      timestamp: getLocaleDate({ date: created }),
      uuid,
    });
  }) ?? [];
