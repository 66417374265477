import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { IStaticTableProps } from '@components/Table/StaticTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { OpsPortalDocumentModes, OpsPortalDocumentTypes } from './enums';
import { getDocumentsTableColumns } from './getDocumentTableColumns';
import { mapDocumentsTabTableData } from './mapDocumentsTabTableData';
import { useGetContractDocumentsQuery } from './queries/useGetContractDocumentsQuery';
import { useGetDownloadExecutionQueries } from './queries/useGetDownloadExecutionQueries';
import { useGetMerchantDocumentsQuery } from './queries/useGetMerchantDocumentsQuery';
import { IDocumentsTableColumn } from './types';
import { useDeleteDocumentModal } from './useDeleteDocumentModal';

interface IDocumentTableData {
  error: AxiosError | null;
  isLoading: boolean;
  tableProps: IStaticTableProps<IDocumentsTableColumn, unknown>;
}

export const useDocumentsTableData = (
  targetUuid: string,
  mode: OpsPortalDocumentModes,
): IDocumentTableData => {
  const { getLocaleDate, translate } = useI18n();

  const queryFunction =
    mode === OpsPortalDocumentModes.Contract
      ? useGetContractDocumentsQuery
      : useGetMerchantDocumentsQuery;
  const { data, error, isLoading } = queryFunction(targetUuid);

  const [documentDeleteDetails, setDocumentDeleteDetails] = useState({});
  const deleteDocumentModal = useDeleteDocumentModal({
    rowData: documentDeleteDetails as IDocumentsTableColumn,
    mode,
  });

  const {
    executeContractUserDocumentsDownload,
    executeCustomerAgreementDownload,
    executeIntrumDownload,
    executeMerchantUserDocumentsDownload,
    executeOnfidoDownload,
    executeTermsAndConditionsDownload,
  } = useGetDownloadExecutionQueries();

  const userDocumentDownload =
    mode === OpsPortalDocumentModes.Contract
      ? executeContractUserDocumentsDownload
      : executeMerchantUserDocumentsDownload;

  const contractDocumentsColumns = useMemo(
    () =>
      getDocumentsTableColumns({
        button: deleteDocumentModal?.button,
        translate,
        onDeleteClick: setDocumentDeleteDetails || (() => null),
        onDownloadClick: rowData => {
          const documentTypeExecutors = {
            [OpsPortalDocumentTypes.OnfidoFront]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoBack]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoSelfie]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoHealthCard]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.IntrumIdentificationFiles]:
              executeIntrumDownload,
            [OpsPortalDocumentTypes.IntrumSigningFiles]: executeIntrumDownload,
            [OpsPortalDocumentTypes.CustomerAgreement]:
              executeCustomerAgreementDownload,
            [OpsPortalDocumentTypes.TradeInOptionAgreement]:
              executeCustomerAgreementDownload,
            [OpsPortalDocumentTypes.TermsAndConditionsFinalization]:
              executeTermsAndConditionsDownload,
            [OpsPortalDocumentTypes.TermsAndConditionsConclusion]:
              executeTermsAndConditionsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentAcknowledgementOfDebt]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutAgency]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutLateFee]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutMerchant]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentGeneralComplaint]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentImportantCommunication]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentOther]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentReplyToAComplaint]:
              userDocumentDownload,
            [OpsPortalDocumentTypes.UploadedDocumentCreditContractSignedInstore]:
              userDocumentDownload,
          };

          documentTypeExecutors[rowData.documentType](rowData);
        },
      }),
    [
      deleteDocumentModal?.button,
      executeCustomerAgreementDownload,
      executeIntrumDownload,
      executeOnfidoDownload,
      executeTermsAndConditionsDownload,
      userDocumentDownload,
      translate,
    ],
  );

  const documentsData = mapDocumentsTabTableData({
    data,
    getLocaleDate,
    translate,
  });

  return {
    tableProps: {
      columns: contractDocumentsColumns,
      data: documentsData,
    },
    error,
    isLoading,
  };
};
