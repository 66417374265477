/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Navigate, Route, Routes } from 'react-router-dom';
import { faro, FaroRoutes } from '@grafana/faro-react';
import { Logout } from '@auth';
import { useCheckForLogOut } from '@auth/hooks/useCheckForLogOut';
import { useHasPermission } from '@hooks/useHasPermission';
import { useMerchantSettingGroup } from '@hooks/useMerchantSettingGroup';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { AccountSettings } from '@pages/AccountSettings';
import { APICalls } from '@pages/APICalls';
import { ApplicationDetails } from '@pages/ApplicationDetails';
import { Applications } from '@pages/Applications';
import { BankAccountReconciliations } from '@pages/BankAccountReconciliations';
import { PendingAllocationsV2 } from '@pages/BankAccountReconciliations/PendingAllocations/PendingAllocationsV2/PendingAllocationsV2';
import { BlockedApplicants } from '@pages/BlockedApplicants';
import { CollectionsTableV2 } from '@pages/Collections/CollectionsV2/CollectionsTableV2';
import { ConsumerDetails } from '@pages/ConsumerDetails';
import { Consumers } from '@pages/Consumers';
import { ContractDetails } from '@pages/ContractDetails';
import { Contracts } from '@pages/Contracts';
import { Documents } from '@pages/Documents';
import { ErrorPage } from '@pages/ErrorPage';
import { MultiLanguageGuide } from '@pages/Help';
import { guides } from '@pages/Help/guideUrls';
import { InStore } from '@pages/InStore';
import { MediobancaReconciliations } from '@pages/MediobancaReconciliations';
import { MerchantDetails } from '@pages/MerchantDetails';
import { Merchants } from '@pages/Merchants';
import { MerchantStores } from '@pages/MerchantStores/MerchantStores';
import {
  OrderDetailsConsumer,
  OrderDetailsMerchant,
} from '@pages/OrderDetails';
import { Orders } from '@pages/Orders';
import { Organisation } from '@pages/Organisation';
import { OverpaidContracts } from '@pages/OverpaidContracts';
import { PausedContracts } from '@pages/PausedContracts';
import { PrivacyPolicy } from '@pages/PrivacyPolicy';
import { SettlementDetails } from '@pages/SettlementDetails';
import { Settlements } from '@pages/Settlements';
import { TerminableContracts } from '@pages/TerminableContracts/TerminableContracts';
import { Tracking } from '@pages/Tracking';
import { UnbalancedAllocationDetails } from '@pages/UnbalancedAllocationDetails';
import { UnbalancedAllocations } from '@pages/UnbalancedAllocations';
import { Users } from '@pages/Users';
import { Utilities } from '@pages/Utilities';
import { PathnamePrefixes, Views } from '@utils/constants';
import { BankAccountReconciliationView, PageRoutes } from '@utils/enums';
import { useSetGrafanaPortal } from '@utils/grafana';
import { scopes } from '@utils/scopes';
import { AuthRedirect } from './AuthRedirect';
import { AuthRoute } from './AuthRoute';
import { getInitialRoute } from './getInitialRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { routeFactory } from './routeFactory';

const permissionConsumerCanViewPaymentPlans =
  'consumer_portal.can_view_payment_plans';
const permissionMerchantCanViewPaymentPlans =
  'merchant_portal.can_view_payment_plans';
const permissionOpsViewApplication = 'ops_portal.view_application';
const permissionOpsViewContract = 'ops_portal.view_contract';
const permissionOpsViewMerchant = 'ops_portal.view_merchant';
const permissionOpsCanViewBankAcctStatementLines =
  'ops_portal.can_view_bank_account_statement_lines';
const permissionConsumerChangeCardGuide = 'consumer_portal.can_change_card';
const permissionMerchantCanViewSalesAssistantLimitedAccess =
  'merchant_portal.can_view_sales_assistant_limited_access';
const permissionCanViewMerchantApplications =
  'merchant_portal.can_view_applications';

export const PortalRoutes = () => {
  useSetGrafanaPortal();
  const { isCompass } = useOriginator();
  const { isConsumerPortal, isMerchantPortal, isOpsPortal } = usePortal();
  const { hideOrdersPage, showApplicationsPage } = useMerchantSettingGroup();
  useCheckForLogOut();

  const canViewOrders =
    useHasPermission([
      permissionConsumerCanViewPaymentPlans,
      permissionMerchantCanViewPaymentPlans,
    ]) && !hideOrdersPage;
  const canMerchantViewMyOrders =
    useHasPermission([permissionMerchantCanViewSalesAssistantLimitedAccess]) &&
    !hideOrdersPage;
  const canMerchantViewApplications =
    useHasPermission([permissionCanViewMerchantApplications]) &&
    (isCompass || Boolean(showApplicationsPage));
  const canMerchantViewMyApplications =
    useHasPermission([permissionMerchantCanViewSalesAssistantLimitedAccess]) &&
    (isCompass || Boolean(showApplicationsPage));

  const { getPathnamePrefixForOriginator: getPathnamePrefix } = useOriginator();

  const initialRoute = getInitialRoute({
    isOpsPortal,
    canViewOrders,
    canMerchantViewMyOrders,
    canMerchantViewMyApplications,
    canMerchantViewApplications,
  });

  const createRoute = (
    basePath: string,
    component: JSX.Element | null = null,
  ) =>
    routeFactory({
      basePath,
      component,
      getPathnamePrefix,
    });

  const RoutesComponent = faro.config ? FaroRoutes : Routes;

  return (
    <RoutesComponent>
      {createRoute(`${PageRoutes.Auth}/*`, <AuthRoute />)}

      <Route
        element={
          <ProtectedRoute
            scopes={[
              'consumer_portal.change_consumeruser',
              'merchant_portal.can_view_account_info',
              permissionOpsViewMerchant,
            ]}
          >
            <AccountSettings />
          </ProtectedRoute>
        }
        path={PageRoutes.AccountSettings}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <Contracts />
          </ProtectedRoute>
        }
        path={PageRoutes.AllContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AllContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewApplication]}>
            <APICalls />
          </ProtectedRoute>
        }
        path={PageRoutes.ApiCalls}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[
              permissionOpsViewApplication,
              permissionCanViewMerchantApplications,
            ]}
          >
            <Applications />
          </ProtectedRoute>
        }
        path={PageRoutes.Applications}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <Applications isMyApplications={true} />
          </ProtectedRoute>
        }
        path={PageRoutes.MyApplications}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ApplicationDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Applications}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_awaiting_dispatch_orders']}
          >
            <Orders view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={PageRoutes.AwaitingConfirmation}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_awaiting_dispatch_orders']}
          >
            <OrderDetailsMerchant myOrder={false} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AwaitingConfirmation}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <Contracts view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={PageRoutes.AwaitingDispatch}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AwaitingDispatch}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <BankAccountReconciliations
              view={BankAccountReconciliationView.BankAccountReconciliations}
            />
          </ProtectedRoute>
        }
        path={PageRoutes.BankAccountReconciliations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_blocked_applicants']}>
            <BlockedApplicants />
          </ProtectedRoute>
        }
        path={PageRoutes.BlockedApplicants}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionConsumerChangeCardGuide]}>
            <MultiLanguageGuide
              guides={guides.CARD_CHANGE}
              translationRef="PAGE.CARD_CHANGE_GUIDE.REDIRECTING_TO_CARD_CHANGE_GUIDE"
            />
          </ProtectedRoute>
        }
        path={PageRoutes.CardChangeGuide}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <CollectionsTableV2 />
          </ProtectedRoute>
        }
        path={PageRoutes.Collections}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.COLLECTIONS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Collections}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_consumer']}>
            <Consumers />
          </ProtectedRoute>
        }
        path={PageRoutes.Consumers}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_consumer']}>
            <ConsumerDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Consumers}/:consumerUuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_create_a_new_plan']}>
            <InStore />
          </ProtectedRoute>
        }
        path={PageRoutes.CreateNewPlan}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionConsumerCanViewPaymentPlans]}>
            <Documents />
          </ProtectedRoute>
        }
        path={PageRoutes.Documents}
      />

      <Route
        element={
          <ProtectedRoute scopes={scopes.merchant}>
            <MultiLanguageGuide
              guides={guides.MERCHANT_GUIDE}
              translationRef="PAGE.MERCHANT_GUIDE.REDIRECTING_TO_MERCHANT_GUIDE"
            />
          </ProtectedRoute>
        }
        path={PageRoutes.MerchantGuide}
      />

      <Route
        element={
          <ProtectedRoute scopes={scopes.merchant}>
            <PrivacyPolicy />
          </ProtectedRoute>
        }
        path={PageRoutes.MerchantPrivacyPolicy}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewMerchant]}>
            <Merchants />
          </ProtectedRoute>
        }
        path={PageRoutes.Merchants}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewMerchant]}>
            <MerchantDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Merchants}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <Orders myOrders={true} />
          </ProtectedRoute>
        }
        path={PageRoutes.MyOrders}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <OrderDetailsMerchant myOrder={true} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.MyOrders}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[
              permissionConsumerCanViewPaymentPlans,
              permissionMerchantCanViewPaymentPlans,
            ]}
          >
            <Orders />
          </ProtectedRoute>
        }
        path={PageRoutes.Orders}
      />

      {isMerchantPortal ? (
        <Route
          element={
            <ProtectedRoute scopes={[permissionMerchantCanViewPaymentPlans]}>
              <OrderDetailsMerchant myOrder={false} />
            </ProtectedRoute>
          }
          path={`${PageRoutes.Orders}/:uuid`}
        />
      ) : null}

      {isConsumerPortal ? (
        <Route
          element={
            <ProtectedRoute scopes={[permissionConsumerCanViewPaymentPlans]}>
              <OrderDetailsConsumer />
            </ProtectedRoute>
          }
          path={`${PageRoutes.Orders}/:uuid`}
        />
      ) : null}

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_edit_merchant_settings']}
          >
            <Organisation />
          </ProtectedRoute>
        }
        path={PageRoutes.OrgInfo}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <OverpaidContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.OverpaidContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.OVERPAID_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.OverpaidContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <TerminableContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.TerminableContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.TERMINABLE_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.TerminableContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_paused_contracts']}>
            <PausedContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.PausedContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_paused_contracts']}>
            <ContractDetails view={Views.PAUSED_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.PausedContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <PendingAllocationsV2 />
          </ProtectedRoute>
        }
        path={PageRoutes.PendingAllocations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_view_merchant_stores']}>
            <MerchantStores />
          </ProtectedRoute>
        }
        path={PageRoutes.MerchantStores}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_view_reconciliation']}>
            <Settlements />
          </ProtectedRoute>
        }
        path={PageRoutes.Settlements}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_view_reconciliation']}>
            <SettlementDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Settlements}/:settlementsId`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <BankAccountReconciliations
              view={BankAccountReconciliationView.Suspense}
            />
          </ProtectedRoute>
        }
        path={PageRoutes.Suspense}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_create_a_new_plan']}>
            <Tracking />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Track}/:contractId`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_mediobanca_reconciliation']}
          >
            <MediobancaReconciliations />
          </ProtectedRoute>
        }
        path={PageRoutes.Transactions}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_par_groups']}>
            <UnbalancedAllocations />
          </ProtectedRoute>
        }
        path={PageRoutes.UnbalancedAllocations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_par_groups']}>
            <UnbalancedAllocationDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.UnbalancedAllocations}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_edit_user_settings']}>
            <Users />
          </ProtectedRoute>
        }
        path={PageRoutes.Users}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_access_utilities']}>
            <Utilities />
          </ProtectedRoute>
        }
        path={PageRoutes.Utilities}
      />

      <Route element={<ErrorPage />} path={PageRoutes.Error} />

      <Route element={<Logout />} path={PageRoutes.Logout} />

      <Route
        element={
          <ProtectedRoute scopes={scopes.all}>
            <Navigate to={initialRoute} />
          </ProtectedRoute>
        }
        path="/"
      />

      <Route
        element={<AuthRedirect country="IT" />}
        path={`${PathnamePrefixes.PAGOLIGHT}/auth/*`}
      />
      <Route
        element={<AuthRedirect country="IT" />}
        path={`${PathnamePrefixes.PAGOLIGHTPRO}/auth/*`}
      />
      <Route element={<AuthRedirect country="CH" />} path="auth/*" />

      <Route element={<Navigate to={PageRoutes.Error} />} path="*" />

      {/* 
        Calling the factory like this creates routes for "/pagolight" and "/pagolightpro" (or "/HeyLight/IT" and "/HeyLight/CH" after rebranding)
      */}
      {createRoute('')}
    </RoutesComponent>
  );
};
