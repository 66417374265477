import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { getJsonCell } from '@utils/jsonUtils/jsonUtils';
import { ICrabbTableData } from './types';

interface IGetCrabbTableColumns {
  translate: Translate;
}

export const getCrabbTableColumns = ({ translate }: IGetCrabbTableColumns) => {
  const columnHelper = createColumnHelper<ICrabbTableData>();

  return [
    columnHelper.accessor('taskUuid', {
      header: translate('OPS_PORTAL.LABEL.TASK_UUID'),
      enableSorting: false,
    }),
    columnHelper.accessor('salesforceId', {
      header: translate('OPS_PORTAL.LABEL.SALESFORCE_ID'),
      enableSorting: false,
    }),
    columnHelper.accessor('salesforceModel', {
      header: translate('OPS_PORTAL.LABEL.SALESFORCE_MODEL'),
      enableSorting: false,
    }),
    columnHelper.accessor('responseCode', {
      header: translate('OPS_PORTAL.LABEL.RESPONSE_CODE'),
      enableSorting: false,
    }),
    columnHelper.accessor('duration', {
      header: translate('OPS_PORTAL.LABEL.DURATION'),
      enableSorting: false,
    }),
    columnHelper.accessor('rawRequestData', {
      header: translate('OPS_PORTAL.LABEL.REQUEST_DATA'),
      cell: ({ row }) => getJsonCell(row.original.rawRequestData),
    }),
    columnHelper.accessor('rawResponseData', {
      header: translate('OPS_PORTAL.LABEL.RESPONSE_DATA'),
      cell: ({ row }) => getJsonCell(row.original.rawResponseData),
      enableSorting: false,
    }),
  ];
};
