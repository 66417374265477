import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { EnhancedStore } from '@reduxjs/toolkit';

interface IAddHeadersToRequest {
  config: InternalAxiosRequestConfig;
  store: EnhancedStore;
}

export const addHeadersToRequest = ({
  config,
  store,
}: IAddHeadersToRequest): InternalAxiosRequestConfig => {
  const {
    auth: { authToken },
  } = store.getState();

  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': config.headers['Content-Type'] ?? 'application/json',
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    } as AxiosRequestHeaders,
  };
};
