import { useEffect } from 'react';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { CursorPaginationModel } from '@hooks/useCursorPagination/types';
import { updateOrderingModel } from '../helpers/updateOrderingModel';
import { formatQueryParams } from '../helpers/utils';

interface IUseSyncURLParamsCursorPagination<TFilterValues> {
  filterHandler?: (values: TFilterValues) => void;
  handlePagination: (paginationParams: CursorPaginationModel) => void;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
  searchParams: URLSearchParams;
  setSyncedSearchParams: (params: string) => void;
  syncedSearchParams: string;
}

export const useSyncURLParamsCursorPagination = <TFilterValues>({
  filterHandler,
  handlePagination,
  handleSortModelChange,
  searchParams,
  setSyncedSearchParams,
  syncedSearchParams,
}: IUseSyncURLParamsCursorPagination<TFilterValues>) => {
  useEffect(() => {
    const searchParamsToString = searchParams.toString();
    const hasUrlParamsBeenManuallyUpdated =
      syncedSearchParams !== searchParamsToString;

    if (hasUrlParamsBeenManuallyUpdated) {
      const urlParams = Object.fromEntries(searchParams);
      const { cursor, limit, ordering, ...filters } = urlParams;

      filterHandler?.({ ...filters } as TFilterValues);

      updateOrderingModel({ handleSortModelChange, ordering });

      handlePagination({
        pageSize: Number.isNaN(Number(limit)) ? 10 : Number(limit),
        manualOverrideCursor: cursor,
      });
      setSyncedSearchParams(formatQueryParams(urlParams));
    }
  }, [
    filterHandler,
    handleSortModelChange,
    handlePagination,
    searchParams,
    setSyncedSearchParams,
    syncedSearchParams,
  ]);
};
