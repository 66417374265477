import {
  ApplicationStatuses,
  ContractStatuses,
  PerformanceStatuses,
  TableNames,
  Views,
} from '@utils/constants';
import { sortDropwdownOptionsByName } from '@utils/sortDropdownOptionsByName/sortDropdownOptionsByName';
import { IFilterOptions, IStatusOptions, TableName } from './types';

export type ApplicationStatusesArray = Array<keyof typeof ApplicationStatuses>;
type ContractStatusesArray = Array<keyof typeof ContractStatuses>;
type PerformanceStatusArray = Array<keyof typeof PerformanceStatuses>;

export const applicationKeys: ContractStatusesArray = [
  'ABANDONED',
  'APPLICATION_ONGOING',
  'DECLINED',
  'FAILED',
  'INITIALISED',
];

export const contractKeys: ContractStatusesArray = [
  'ACTIVE',
  'AWAITING_DISPATCH',
  'CANCELLED',
  'CLOSED',
  'PENDING_CLOSURE',
  'TERMINATED',
  'TERMINATED_AND_PAID',
  'WRITTEN_OFF',
  'WRITTEN_OFF_AND_PAID',
];

export const performanceStatusOrderedKeys: PerformanceStatusArray = [
  'PERFORMING',
  'DELAYED',
  'OVERDUE',
  'COMPLETED',
];

export const translationKeys = {
  ABANDONED: 'STATUS.ABANDONED',
  ACTIVE: 'STATUS.ACTIVE',
  APPLICATION_ONGOING: 'STATUS.APPLICATION_ONGOING',
  AWAITING_AGREEMENT_SIGNING: 'STATUS.AWAITING_SIGNING',
  AWAITING_DISPATCH: 'STATUS.AWAITING_DISPATCH',
  AWAITING_VERIFICATION: 'STATUS.AWAITING_VERIFICATION',
  CANCELLED: 'STATUS.CANCELLED',
  CLOSED: 'STATUS.CLOSED',
  COMPLETED: 'STATUS.CLOSED',
  DECLINED: 'STATUS.DECLINED',
  DELAYED: 'STATUS.DELAYED',
  FAILED: 'STATUS.FAILED',
  INITIALISED: 'STATUS.INITIALISED',
  OVERDUE: 'STATUS.OVERDUE',
  PENDING_CLOSURE: 'STATUS.PENDING_CLOSURE',
  PENDING_TERMINATION: 'OPS_PORTAL.STATUS.PENDING_TERMINATION',
  PERFORMING: 'STATUS.PERFORMING',
  SUCCESSFUL: 'STATUS.SUCCESSFUL',
  TERMINATED: 'OPS_PORTAL.STATUS.TERMINATED',
  TERMINATED_AND_PAID: 'OPS_PORTAL.STATUS.TERMINATED_AND_PAID',
  TERMINATION_FAILED: 'OPS_PORTAL.STATUS.TERMINATION_FAILED',
  WRITTEN_OFF: 'OPS_PORTAL.STATUS.WRITTEN_OFF',
  WRITTEN_OFF_AND_PAID: 'OPS_PORTAL.STATUS.WRITTEN_OFF_AND_PAID',
};

export const merchantStatusTranslationKeys = {
  ABANDONED: 'STATUS.TRACKING_ABANDONED',
  APPLICATION_ONGOING: 'STATUS.TRACKING_APPLICATION_ONGOING',
  AWAITING_AGREEMENT_SIGNING: 'STATUS.TRACKING_AWAITING_SIGNING',
  AWAITING_VERIFICATION: 'STATUS.TRACKING_AWAITING_VERIFICATION',
  DECLINED: 'STATUS.TRACKING_DECLINED',
  FAILED: 'STATUS.TRACKING_FAILED',
  INITIALISED: 'STATUS.TRACKING_INITIALISED',
  SUCCESSFUL: 'STATUS.TRACKING_SUCCESSFUL',
};

export const getStatusOptions = ({
  isMerchantPortal,
  tableName,
  translate,
}: IStatusOptions) => {
  const applicationOptions = (
    Object.keys(ApplicationStatuses) as ApplicationStatusesArray
  ).map(key => ({
    id: ApplicationStatuses[key],
    name: translate(
      isMerchantPortal
        ? merchantStatusTranslationKeys[key]
        : translationKeys[key],
    ),
  }));

  const activityOptions: Partial<Record<TableName, Array<IFilterOptions>>> = {
    [TableNames.APPLICATIONS]: sortDropwdownOptionsByName(applicationOptions),
    [TableNames.CONTRACTS]: contractKeys.map(key => ({
      id: ContractStatuses[key],
      name: translate(translationKeys[key]),
    })),
  };

  const activityStatusOptions =
    tableName === Views.AWAITING_DISPATCH
      ? null
      : [
          {
            id: '',
            name: '—',
          },
          ...(activityOptions[tableName] || []),
        ];

  const performanceStatusOptions = [
    { id: '', name: '—' },
    ...performanceStatusOrderedKeys.map(key => ({
      id: PerformanceStatuses[key],
      name: translate(translationKeys[key]),
    })),
  ];

  return {
    activityStatusOptions,
    performanceStatusOptions,
  };
};
