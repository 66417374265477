import { AxiosInstance, AxiosError } from 'axios';
import { EnhancedStore } from '@reduxjs/toolkit';
import { addHeadersToRequest } from './addHeadersToRequest';
import { handleAxiosError } from './handleAxiosError';
import { verifySession } from './verifySession';

export default function setupAxios(axios: AxiosInstance, store: EnhancedStore) {
  axios.interceptors.request.use(
    config => verifySession({ config, store }),
    (err: Error) => Promise.reject(err),
  );

  axios.interceptors.request.use(
    config => addHeadersToRequest({ config, store }),
    (err: Error) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response => response,
    (err: AxiosError) => handleAxiosError(err),
  );
}
