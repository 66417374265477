import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IMerchantPortalMediobancaSettlement } from '@schemas/merchantPortal/types/mediobancaSettlement';
import { IOrderDetail } from '@schemas/merchantPortal/types/ordersDetails';
import { PaymentMethods } from '@utils/constants';

export type PaymentMethod = keyof typeof PaymentMethods;
export type PaymentMethodValue = (typeof PaymentMethods)[PaymentMethod];

export interface IInvoiceDetailsProps {
  invoiceData?: IMerchantPortalMediobancaSettlement;
  orderData: IOrderDetail;
}

export interface IPrepareInvoiceDataProps {
  data: IInvoiceDetailsProps;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export interface IScheduledPayment {
  dueDate: string;
  outstandingAmount: string;
  paymentAmount: string;
}

export interface IMappedConsumerPaymentsData {
  amount: string;
  customerRefund: string;
  settlementDate: string;
  settlementType: string;
}
