import { Header } from '@tanstack/react-table';
import { IQueryState } from '@hooks/useQueryState/utils/types';
import { SortButton } from './SortButton';
import { FlexRender } from './types';

interface IServersideHeaderProps<T> {
  flexRender?: FlexRender;
  header: Header<T, unknown>;
  tableState: IQueryState;
}

export const ServersideHeader = <T,>({
  flexRender,
  header,
  tableState,
}: IServersideHeaderProps<T>) => {
  const colSort = tableState.sorting.find(col => col.id === header.id);
  const sortDirection = colSort?.desc ? 'descending' : 'ascending';
  const columnSortState = colSort ? sortDirection : undefined;

  return (
    <th aria-sort={columnSortState} key={header.id}>
      <SortButton
        sort={columnSortState}
        toggleSort={header.column.getToggleSortingHandler()}
      >
        {flexRender?.(header.column.columnDef.header, header.getContext())}
      </SortButton>
    </th>
  );
};
