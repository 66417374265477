import { Formik, Form } from 'formik';
import { IConfirmDispatch } from './types';

export const ConfirmDispatchForm = ({
  formID,
  handleSubmit,
  uuid,
}: IConfirmDispatch) => {
  const initialFormikValues = { contract_uuid: uuid };

  return (
    <Formik initialValues={initialFormikValues} onSubmit={handleSubmit}>
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      ></Form>
    </Formik>
  );
};
