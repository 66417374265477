import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { IPrepareInvoiceDataProps } from '../types';

const executeOrReturnDefault = (
  value: number | undefined,
  func: () => string | undefined,
) => (value || value === 0 ? func() : null);

export const prepareInvoiceData = ({
  data,
  getLocaleCurrencyValue,
}: IPrepareInvoiceDataProps): Record<string, string> => {
  const { invoiceData, orderData } = data;
  const {
    consumer_name: consumerName,
    currency,
    gross_amount: grossAmount,
    installments,
    interest,
    net_amount: netAmount,
    net_fee: netFee,
  } = invoiceData ?? {};

  const { mediobanca_external_id: mediobancaExternalId } = orderData;

  return addPlaceholderForEmptyValues<Record<string, string>>({
    mediobancaExternalId,
    consumerName,
    grossAmount: executeOrReturnDefault(grossAmount, () =>
      getLocaleCurrencyValue({
        currency,
        value: grossAmount?.toString(),
      }),
    ),
    installments: installments?.toString(),
    netAmount: executeOrReturnDefault(netAmount, () =>
      getLocaleCurrencyValue({
        currency,
        value: netAmount?.toString(),
      }),
    ),
    interest: executeOrReturnDefault(interest, () =>
      getLocaleCurrencyValue({
        currency,
        value: interest?.toString(),
      }),
    ),
    netFee: executeOrReturnDefault(netFee, () =>
      getLocaleCurrencyValue({
        currency,
        value: netFee?.toString(),
      }),
    ),
  });
};
