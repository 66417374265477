import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { alpha, styled } from '@mui/material/styles';
import { AlertSeverity } from '@utils/enums';
import { INotification } from './types';

type Severity = `${AlertSeverity}`;

interface IStyledAlertProps {
  severity: Severity;
}

interface INotificationBannerProps {
  action?: JSX.Element;
  notifications: INotification[];
}

const StyledAlert = styled(Alert)<IStyledAlertProps>(({ severity, theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
  whiteSpace: 'pre-line',

  '&.MuiPaper-root': {
    backgroundColor: alpha(
      theme.palette[severity || AlertSeverity.Info].light,
      0.2,
    ),
  },

  '&:first-of-type': {
    marginTop: theme.spacing(-2),
  },

  '& .MuiAlert-action': {
    alignSelf: 'center',
  },
}));

const StyledTitle = styled(AlertTitle)({ fontSize: '1.1rem' });

export const NotificationBanner = ({
  action,
  notifications,
}: INotificationBannerProps) => (
  <>
    {notifications.map(({ id, message, severity, title }) => (
      <StyledAlert action={action} key={id} severity={severity}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {message}
      </StyledAlert>
    ))}
  </>
);
