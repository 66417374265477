import { useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format as formatDate } from 'date-fns';
import { de, enGB, frCH, it } from 'date-fns/locale';
import { useField, useFormikContext } from 'formik';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError/ValidationError';
import { useI18n } from '@hooks/useI18n/useI18n';

interface IDateRangePicker {
  endId?: string;
  endLabel?: string;
  startId?: string;
  startLabel?: string;
}

export const DateRangePicker = ({
  endId = 'dateTo',
  endLabel,
  startId = 'dateFrom',
  startLabel,
}: IDateRangePicker) => {
  const { selectedLanguage } = useI18n();

  registerLocale('de', de);
  registerLocale('en-GB', enGB);
  registerLocale('fr-CH', frCH);
  registerLocale('it', it);

  const locales = {
    de: 'de',
    en: 'en-GB',
    fr: 'fr-CH',
    it: 'it',
  };

  const { translate } = useI18n();

  const { setFieldValue } = useFormikContext();
  const [startField, metaStartField] = useField({ name: startId });
  const [endField, metaEndField] = useField({ name: endId });

  const startDate = (startField.value && new Date(startField.value)) || null;
  const endDate = (endField.value && new Date(endField.value)) || null;

  const startHandler = useCallback(
    (date: Nullable<Date>) =>
      setFieldValue(
        startField.name,
        (date && formatDate(date, 'yyyy-MM-dd')) || '',
      ),
    [setFieldValue, startField.name],
  );

  const endHandler = useCallback(
    (date: Nullable<Date>) =>
      setFieldValue(
        endField.name,
        (date && formatDate(date, 'yyyy-MM-dd')) || '',
      ),
    [endField.name, setFieldValue],
  );

  const getDatePickerOptions = (isStart: boolean) => {
    const defaultOptions = {
      autoComplete: 'off',
      locale: locales[selectedLanguage],
      dateFormat: 'PP',
      isClearable: true,
      showPopperArrow: false,
      startDate,
      endDate,
    };

    const pickerSpecificOptions = isStart
      ? {
          selected: startDate,
          onChange: startHandler,
          selectsStart: true,
          id: startId,
          name: startId,
        }
      : {
          selected: endDate,
          onChange: endHandler,
          selectsEnd: true,
          minDate: isStart ? startDate : null,
          id: endId,
          name: endId,
        };

    return { ...defaultOptions, ...pickerSpecificOptions };
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor={startId}>
          {startLabel ?? translate('LABEL.START_DATE')}
        </Label>
        <InputText as={DatePicker} {...getDatePickerOptions(true)} />
        {metaStartField.touched && metaStartField.error ? (
          <ValidationError error={metaStartField.error} />
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label htmlFor={endId}>{endLabel ?? translate('LABEL.END_DATE')}</Label>
        <InputText as={DatePicker} {...getDatePickerOptions(false)} />
        {metaEndField.touched && metaEndField.error ? (
          <ValidationError error={metaEndField.error} />
        ) : null}
      </FormGroup>
    </>
  );
};
