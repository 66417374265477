import { ActionButtons } from '@appTypes';
import { ButtonMenu } from '@components/ButtonMenu';
import { CardButton } from '@components/CardButton';
import { useConfig } from '@hooks/useConfig';

interface ICardButtonsProps {
  buttons: ActionButtons;
}

export const CardButtons = ({ buttons }: ICardButtonsProps) => {
  const { maxCardHeaderButtons } = useConfig();

  return (
    <>
      {buttons?.length > maxCardHeaderButtons ? (
        <ButtonMenu buttons={buttons} />
      ) : (
        buttons
          ?.map(
            button =>
              button && <CardButton {...button} key={button.buttonKey} />,
          )
          .filter(Boolean)
      )}
    </>
  );
};
