import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortal } from '@hooks/usePortal';
import { ConfirmDispatchForm } from './ConfirmDispatchForm';
import { IConfirmDispatch } from './types';

export const ConfirmDispatchModalContent = ({
  formID,
  handleSubmit,
  uuid,
}: IConfirmDispatch) => {
  const { translate } = useI18n();
  const { isOpsPortal } = usePortal();

  const confirmationText = isOpsPortal
    ? translate('OPS_PORTAL.PAGE.CONTRACTS.CONFIRM_DISPATCH_OF_CONTRACT_X', {
        uuid,
      })
    : translate('PAGE.ORDERS.CONFIRM_DISPATCH_OF_ORDER_X', { uuid });

  return (
    <>
      <p>{confirmationText}</p>
      <p className="text-danger text-center">
        <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
      </p>
      <ConfirmDispatchForm
        formID={formID}
        handleSubmit={handleSubmit}
        uuid={uuid}
      />
    </>
  );
};
