import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import {
  IOrders as IConsumerOrders,
  IFullOrderDetails,
} from '@schemas/consumerPortal/types/orders';
import { IOrders } from '@schemas/merchantPortal/types/orders';
import { OriginationChannel } from '@utils/enums';
import { getFormattedAmount } from '@utils/getFormattedAmount';
import { getFullName } from '@utils/getFullName';
import { getStoreInformation } from '@utils/getStoreInformation';
import { getTranslatedData } from '@utils/getTranslatedData';
import { IOrderTableData } from './types';

interface IPrepareOrderTableData {
  data?: IOrders | IConsumerOrders;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isCompass?: boolean;
  isConsumerPortal?: boolean;
  translate: Translate;
}

interface IGetOriginationChannel {
  isCompass?: boolean;
  orderDetails: IFullOrderDetails;
  translate: Translate;
}
const getOriginationChannelTranslation = ({
  isCompass,
  orderDetails,
  translate,
}: IGetOriginationChannel) => {
  const { origination_channel: originationChannel } = orderDetails;

  if (!originationChannel) {
    return '';
  }

  if (originationChannel === OriginationChannel.Ecommerce) {
    return translate(`LABEL.${OriginationChannel.Ecommerce.toUpperCase()}`);
  }

  const store = getStoreInformation(orderDetails);
  const storeValues = Object.values(store);
  const storeValuesLabel = storeValues.join(' - ');

  const translatedInstoreChannel = isCompass
    ? translate('LABEL.IN_STORE_PAY_BY_LINK')
    : translate(`LABEL.${OriginationChannel.InStore.toUpperCase()}`);

  return storeValues.length
    ? `${translatedInstoreChannel}: ${storeValuesLabel}`
    : translatedInstoreChannel;
};

export const prepareOrderTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCompass = false,
  isConsumerPortal = false,
  translate,
}: IPrepareOrderTableData) =>
  data?.map(dataRow => {
    const {
      amount,
      consumer_name: { first_name: firstName, last_name: lastName },
      created_date: createdDate,
      delay_finalisation: delayFinalisation,
      external_uuid: orderId,
      mediobanca_external_id: mediobancaExternalId,
      merchant_contract_status: merchantContractStatus = 'unknown',
      merchant_name: merchantName,
      order_details: orderDetails,
      payment_plan_reference: paymentPlanReference,
      settlement_amount: settlementAmt,
      status = 'unknown',
    } = dataRow;

    const dateConfirmed =
      createdDate && getLocaleDate({ date: createdDate, includeTime: true });

    const merchantReference = orderDetails.order_reference;
    const customerName = getFullName({ firstName, lastName });
    const { currency } = amount;
    const orderValue = getFormattedAmount({
      amount: amount?.amount,
      currency,
      getLocaleCurrencyValue,
    });
    const settlementAmount = getFormattedAmount({
      amount: settlementAmt?.amount,
      currency,
      getLocaleCurrencyValue,
    });
    const translatedStatus = getTranslatedData({
      term: isConsumerPortal ? status : merchantContractStatus,
      translate,
    });

    const firstProduct = orderDetails.products?.[0];
    const productCode = firstProduct?.sku ? `${firstProduct.sku},` : null;
    const productId = firstProduct?.external_id
      ? `${firstProduct.external_id},`
      : null;
    const product = firstProduct?.name;

    const orifinationChannelTranslation = getOriginationChannelTranslation({
      orderDetails,
      translate,
      isCompass,
    });

    return addPlaceholderForEmptyValues<IOrderTableData>({
      customerName,
      dateConfirmed,
      dateRaw: createdDate,
      delayFinalisation,
      mediobancaExternalId,
      merchantName,
      merchantReference,
      orderId,
      orderValue,
      originationChannel: orifinationChannelTranslation,
      paymentPlanReference,
      product,
      productCode,
      productId,
      rawStatus: isConsumerPortal ? status : merchantContractStatus,
      settlementAmount,
      status: translatedStatus,
    });
  }) ?? [];
