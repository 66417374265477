import { useI18n } from '@hooks/useI18n/useI18n';
import { ContractPaymentScheduleColumns } from '@pages/ContractDetails/utils/types';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { TabEventKeys, TableNames } from '@utils/constants';
import { getPaymentScheduleTableColumns } from './getPaymentScheduleTableColumns';
import { mapPaymentScheduleData } from './mapPaymentScheduleData';

export const useContractPaymentScheduleTab = (
  monetaContractData?: IOpsPortalMonetaContract,
) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractActivePaymentScheduleColumns =
    getPaymentScheduleTableColumns<ContractPaymentScheduleColumns>(translate);

  const contractPaymentScheduleData = mapPaymentScheduleData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
  });

  return {
    eventKey: TabEventKeys.CONTRACT_PAYMENT_SCHEDULE,
    title: translate('OPS_PORTAL.TITLE.ACTIVE_SCHEDULE'),
    data: contractPaymentScheduleData,
    columns: contractActivePaymentScheduleColumns,
    tableName: TableNames.PAYMENT_SCHEDULE,
    className: 'payment-schedule',
    rowClasses: contractPaymentScheduleData.map(entry =>
      !entry.focused ? 'future-entry' : '',
    ),
  };
};
