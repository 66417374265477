import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  removeEmptyKeysAndReplaceLimitField,
  formatQueryParams,
} from '@components/TableV2/helpers/utils';
import { ITableConfig } from '@components/TableV2/types';
import { CursorPaginationModel } from '@hooks/useCursorPagination/types';
import { useCursorPagination } from '@hooks/useCursorPagination/useCursorPagination';
import { useHasPermission } from '@hooks/useHasPermission/useHasPermission';
import { Translate } from '@hooks/useI18n';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';
import { IOpsPortalPendingAllocations } from '@schemas/opsPortal/types/pendingAllocations';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { ApprovalStatus } from '../enums';
import { IPendingAllocationsFilterValues } from './types';
import { useGetPendingAllocationsColumns } from './useGetPendingAllocationsColumns';
import { useGetPendingAllocationsFilters } from './useGetPendingAllocationsFilters';

interface IUseGetPendingAllocationsTableConfig {
  translate: Translate;
}

export const useGetPendingAllocationsTableConfig = ({
  translate,
}: IUseGetPendingAllocationsTableConfig): ITableConfig<
  IPendingAllocationsFilterValues,
  CursorPaginationModel,
  IOpsPortalPendingAllocations | undefined
> => {
  const initialDefaultFilterValues: IPendingAllocationsFilterValues = {
    allocationDateFrom: '',
    allocationDateTo: '',
    allocationType: '',
  };

  const filtersSettings = useGetPendingAllocationsFilters({
    initialDefaultValues: initialDefaultFilterValues,
    translate,
  });

  const sortingSettings = useTableSorting();

  const paginationSettings = useCursorPagination();

  const canReconcileBankAccount = useHasPermission([
    'ops_portal.can_reconcile_bank_account',
  ]);

  const queryParams = useMemo(
    () =>
      removeEmptyKeysAndReplaceLimitField({
        ...filtersSettings.filterValues,
        ...sortingSettings.sortSearchParam,
        ...paginationSettings.paginationParams,
      }),
    [
      filtersSettings.filterValues,
      paginationSettings.paginationParams,
      sortingSettings.sortSearchParam,
    ],
  );

  const stringifiedQueryParams = useMemo(
    () => formatQueryParams(queryParams as object),
    [queryParams],
  );

  const queryKey = useMemo(
    () => [QueryKeys.PENDING_ALLOCATIONS, stringifiedQueryParams],
    [stringifiedQueryParams],
  );

  const columns = useGetPendingAllocationsColumns({
    canReconcileBankAccount,
    queryKey,
    translate,
  });

  const { data, error, isLoading } = useQuery<
    IOpsPortalPendingAllocations,
    AxiosError
  >(
    queryKey,
    () =>
      fetchDataFromEndpoint(Endpoints.FetchPendingAllocations, {
        query: {
          ...(queryParams as object),
          approvalStatus: ApprovalStatus.Pending,
        },
      }),
    {
      onSuccess: responseData => {
        paginationSettings.setNextCursor(responseData.next_cursor ?? '');
        paginationSettings.setPrevCursor(responseData.previous_cursor ?? '');
      },
    },
  );

  return {
    columns,
    data,
    error,
    filtersSettings,
    isLoading,
    paginationSettings,
    sortingSettings,
  };
};
