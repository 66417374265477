/* eslint-disable max-len */
import { useMemo } from 'react';
import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { formatScheduleHistoryData } from '@pages/ContractDetails/hooks/useContractPaymentScheduleHistoryTab/utils/formatScheduleHistoryData';
import { getPaymentScheduleHistoryColumns } from '@pages/ContractDetails/hooks/useContractPaymentScheduleHistoryTab/utils/getPaymentScheduleHistoryColumns';
import { getScheduleVersions } from '@pages/ContractDetails/hooks/useContractPaymentScheduleHistoryTab/utils/getScheduleVersions';
import { mapPaymentScheduleHistoryData } from '@pages/ContractDetails/hooks/useContractPaymentScheduleHistoryTab/utils/mapPaymentScheduleHistoryData';
import {
  IBalance,
  IOpsPortalMonetaContract,
} from '@schemas/opsPortal/types/monetaContract';
import { TabEventKeys, TableNames } from '@utils/constants';

export const useContractPaymentScheduleHistoryTab = (
  monetaContractData?: IOpsPortalMonetaContract,
) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  // In schedule history we tab we don't want to show manual payments/refunds
  // i.e. where there wasn't an amount due at the that time
  const monetaContractDataWithoutManualPayments = monetaContractData?.balances
    ? {
        ...monetaContractData,
        balances: monetaContractData.balances.filter(
          (balance: IBalance) => Number(balance.amount_due) !== 0,
        ),
      }
    : monetaContractData;

  const scheduleVersions = getScheduleVersions(monetaContractData);

  const contractPaymentScheduleHistoryData = mapPaymentScheduleHistoryData({
    data: monetaContractDataWithoutManualPayments,
    getLocaleCurrencyValue,
    getLocaleDate,
  });

  const formattedScheduleHistoryData = formatScheduleHistoryData({
    scheduleVersions,
    scheduleHistoryData: contractPaymentScheduleHistoryData,
  });

  const contractPaymentScheduleHistoryColumns = useMemo(
    () =>
      getPaymentScheduleHistoryColumns({
        scheduleVersions,
        translate,
      }),
    [scheduleVersions, translate],
  );

  const contractPaymentScheduleHistoryTab: ITabData = {
    eventKey: TabEventKeys.CONTRACT_PAYMENT_SCHEDULE_HISTORY,
    title: translate('OPS_PORTAL.TITLE.SCHEDULE_HISTORY'),
    data: formattedScheduleHistoryData,
    columns: contractPaymentScheduleHistoryColumns,
    tableName: TableNames.PAYMENT_SCHEDULE_HISTORY,
    className: 'payment-schedule',
    rowClasses: formattedScheduleHistoryData.map(entry =>
      !entry.focused ? 'future-entry' : '',
    ),
  };

  return contractPaymentScheduleHistoryTab;
};
