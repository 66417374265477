import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import TabsBS from 'react-bootstrap/Tabs';
import { ColumnDef } from '@tanstack/react-table';
import { DataDisplay } from '@components/DataDisplay';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { useLazyLoadTab } from '@hooks/useLazyLoadTab/useLazyLoadTab';
import { pushGrafanaEvent } from '@utils/grafana';
import { GrafanaEvent } from '@utils/grafana/enums';
import { ITabs, ITabData } from './types';

export const Tabs = ({ initialTabKey, tabsData, tabsId }: ITabs) => {
  const location = useLocation();
  const { setLazyLoadActiveTab } = useLazyLoadTab();

  const targetedTabId = location.state?.activeKey;

  const [activeKey, setActiveKey] = useState(targetedTabId || initialTabKey);

  const selectTabKey = useCallback(
    key => {
      setLazyLoadActiveTab({ key });
      setActiveKey(key);
      pushGrafanaEvent(GrafanaEvent.TabClicked, { tab: key });
    },
    [setLazyLoadActiveTab],
  );

  const renderTabContent = (tab: ITabData) => {
    if (tab.component) {
      return tab.component;
    }

    if (tab.dataList) {
      return <DataDisplay dataList={tab.dataList} />;
    }

    return tab.columns && tab.data && tab.tableName ? (
      <ClientsideTable
        className={tab.className}
        columns={tab.columns as ColumnDef<unknown, unknown>[]}
        data={tab.data}
        isInTab={true}
        rowClasses={tab.rowClasses}
        tableName={tab.tableName}
        tableSpecificProps={tab.tableSpecificProps}
      />
    ) : null;
  };

  return (
    <TabsBS
      activeKey={activeKey}
      className="mb-8"
      id={tabsId}
      onSelect={selectTabKey}
    >
      {tabsData.map((tab: ITabData) => (
        <Tab eventKey={tab.eventKey} key={tab.eventKey} title={tab.title}>
          <QueryStateRouter error={tab?.error} isLoading={tab?.isLoading}>
            {renderTabContent(tab)}
          </QueryStateRouter>
        </Tab>
      ))}
    </TabsBS>
  );
};
