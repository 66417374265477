import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CardButton } from '@components/CardButton';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import {
  IApprovalStatus,
  IUuid,
} from '@schemas/opsPortal/types/pendingAllocations';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { ApprovalStatus } from './enums';

interface IPendingAllocationProps {
  allocationUuid: IUuid;
  queryKey: string[];
}

interface IPendingAllocationRequest {
  allocation_uuid: IUuid;
  approval_status: IApprovalStatus;
}

export const PendingAllocation = ({
  allocationUuid,
  queryKey,
}: IPendingAllocationProps) => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  const resetLoading = () => {
    setIsLoadingApprove(false);
    setIsLoadingReject(false);
  };

  const { mutate: handleAllocate } = useMutation<
    string,
    AxiosError<IError>,
    IPendingAllocationRequest
  >(
    requestData => {
      if (requestData.approval_status === ApprovalStatus.Approved) {
        setIsLoadingApprove(true);
      } else {
        setIsLoadingReject(true);
      }

      return postDataToEndpoint({
        endpoint: Endpoints.HandlePendingAllocation,
        requestData,
      });
    },
    {
      onSuccess: () => {
        resetLoading();
        queryClient.invalidateQueries(queryKey);
        message.success('OPS_PORTAL.STATUS.UPDATE_ALLOCATION_SUCCESSFUL');
      },
      onError: error => {
        resetLoading();
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.UNABLE_TO_UPDATE_ALLOCATION',
        );
      },
    },
  );

  const handleApproved = useCallback(() => {
    const requestObj: IPendingAllocationRequest = {
      allocation_uuid: allocationUuid,
      approval_status: ApprovalStatus.Approved,
    };
    handleAllocate(requestObj);
  }, [handleAllocate, allocationUuid]);

  const handleReject = useCallback(() => {
    const requestObj: IPendingAllocationRequest = {
      allocation_uuid: allocationUuid,
      approval_status: ApprovalStatus.Rejected,
    };
    handleAllocate(requestObj);
  }, [handleAllocate, allocationUuid]);

  return (
    <div className="no-wrap">
      <CardButton
        buttonKey="bankAccountReconciliationsAllocate"
        clickHandler={handleApproved}
        disabled={isLoadingApprove}
        text={translate(
          'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATE',
        )}
        variant={[ButtonVariant.Primary, ButtonVariant.Sm]}
      />
      <CardButton
        buttonKey="bankAccountReconciliationReject"
        clickHandler={handleReject}
        disabled={isLoadingReject}
        text={translate('OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.REJECT')}
        variant={[ButtonVariant.Danger, ButtonVariant.Sm]}
      />
    </div>
  );
};
