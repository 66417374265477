import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useGetMerchantsQuery } from '@hooks/queries';
import { useHasPermission } from '@hooks/useHasPermission';
import { useOriginator } from '@hooks/useOriginator';
import { usePortal } from '@hooks/usePortal';
import { Portals } from '@utils/constants';
import { sort } from '@utils/sort';
import { useGetApplicationSearchQuery } from './useGetApplicationSearchQuery';
import { useGetContractSearchQuery } from './useGetContractSearchQuery';

const validSearchLength = 3;

const getIsValidSearchTerm = (search: string) =>
  search.length >= validSearchLength;

export const useGetHeaderSearchData = () => {
  const { portal } = usePortal();
  const navigate = useNavigate();
  const { originator } = useOriginator();
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [merchantUuids, setMerchantUuids] = useState<string[]>([]);

  const hasViewSearchPermission = useHasPermission([
    'ops_portal.can_view_ops_global_search',
  ]);

  const hasViewPagesPermissions = useHasPermission([
    'ops_portal.view_application',
    'ops_portal.view_contract',
  ]);

  const canViewSearchHeader =
    portal === Portals.OPS &&
    hasViewSearchPermission &&
    hasViewPagesPermissions;

  const { merchantsData } = useGetMerchantsQuery({
    enabled: canViewSearchHeader,
    originator,
  });

  useEffect(() => {
    const searchForMerchants: string[] = search
      ? merchantsData
          ?.filter(merchant =>
            merchant.short_name
              .toLowerCase()
              .includes(search.trim().toLowerCase()),
          )
          ?.map(merchant => merchant.external_uuid) ?? []
      : [];

    setMerchantUuids(searchForMerchants);
  }, [search, merchantsData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce((value: string) => {
      if (getIsValidSearchTerm(value)) {
        setDebouncedSearch(value);
        setSearching(false);
      }
    }, 500),
    [],
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearching(true);
      const { value } = event.target;
      setSearch(value);
      if (getIsValidSearchTerm(value)) {
        debouncedSearchChange(value.trim());
      }
    },
    [debouncedSearchChange],
  );

  const { contractsIsLoading, contractsSearchResults, totalContractsCount } =
    useGetContractSearchQuery({
      search: debouncedSearch,
    });

  const {
    applicationsIsLoading,
    applicationsSearchResults,
    totalApplicationsCount,
  } = useGetApplicationSearchQuery({
    search: debouncedSearch,
    merchantUuids,
  });

  const handleClearSearch = useCallback(() => {
    setSearch('');
    setDebouncedSearch('');
  }, []);

  const handleSelect = useCallback(
    (redirectUrl: string) => () => {
      navigate(redirectUrl);
      handleClearSearch();
    },
    [handleClearSearch, navigate],
  );

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      handleClearSearch();
    }, 150);
  }, [handleClearSearch]);

  const searchResults = [
    ...contractsSearchResults,
    ...applicationsSearchResults,
  ].sort((a, b) => sort(b.created, a.created));

  const hasSearchResults = searchResults.length > 0;

  const open = Boolean(search);
  const loading =
    open && (searching || contractsIsLoading || applicationsIsLoading);

  return {
    canViewSearchHeader,
    handleBlur,
    handleClearSearch,
    handleSearchChange,
    handleSelect,
    hasSearchResults,
    isValidSearchTerm: getIsValidSearchTerm(search),
    loading,
    open,
    search,
    searchResults,
    totalApplicationsCount,
    totalContractsCount,
  };
};
