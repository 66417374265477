import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { getLimitAndOffset, formatQueryParams } from '../helpers/utils';
import {
  LimitOffsetPaginationParams,
  LimitOffsetPaginationModel,
  PaginationParams,
} from '../types';

export interface IUseLimitOffsetPaginationChangeHandler<TFilterValues> {
  filterValues?: TFilterValues;
  handlePagination: (params: LimitOffsetPaginationModel) => void;
  paginationParams: PaginationParams;
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  sortSearchParam?: ISortingParam;
}

export const useLimitOffsetPaginationChangeHandler = <TFilterValues>({
  filterValues,
  handlePagination,
  setSearchParams,
  sortSearchParam,
}: IUseLimitOffsetPaginationChangeHandler<TFilterValues>) =>
  useCallback(
    ({ page, pageSize: pageLimit }: LimitOffsetPaginationModel) => {
      const { limit, offset }: LimitOffsetPaginationParams = getLimitAndOffset({
        pageNumber: page,
        pageSize: pageLimit,
      });

      const updatedPaginationParamsWithFiltersForURL = formatQueryParams({
        ...filterValues,
        ...sortSearchParam,
        limit: limit ?? '',
        offset: offset ?? '',
      });
      handlePagination({ page, pageSize: pageLimit });
      setSearchParams(updatedPaginationParamsWithFiltersForURL);
    },
    [filterValues, handlePagination, setSearchParams, sortSearchParam],
  );
