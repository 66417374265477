import { IGetLimitAndOffset, LimitOffsetPaginationParams } from '../types';
import { getStringifiedQueryParams } from './getStringifiedQueryParams';

export const addIdToRows = (data: object[]) =>
  data?.map((item: object, index: number) => {
    return { ...item, id: index };
  });

const notBlankOrEmpty = (value: unknown) =>
  value !== null && value !== '' && value !== undefined;

export const removeEmptyKeys = <T extends Record<string, unknown>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(entry => notBlankOrEmpty(entry[1])),
  ) as Partial<T>;

// Most endpoints use 'limit' for pageSize
// However, sometimes endpoint use alternatives e.g. pageSize
export const removeEmptyKeysAndReplaceLimitField = <
  T extends Record<string, unknown>,
>(
  obj: T,
  fieldName = 'pageSize',
): Partial<T> => {
  const updatedObj = {
    ...obj,
    [fieldName]: obj.limit,
  };

  delete updatedObj.limit;
  return removeEmptyKeys(updatedObj);
};

export const getLimitAndOffset = ({
  pageNumber,
  pageSize,
}: IGetLimitAndOffset): LimitOffsetPaginationParams => {
  const offset = pageSize * pageNumber;
  return {
    limit: pageSize ?? 10,
    offset: offset ?? 0,
  };
};

export const formatQueryParams = (queryParams: object) => {
  const queryParamsSorted = removeEmptyKeys(
    Object.fromEntries(
      Object.entries(queryParams).sort(([keyA], [keyB]) =>
        keyA.localeCompare(keyB),
      ),
    ),
  );

  return getStringifiedQueryParams(queryParamsSorted);
};
