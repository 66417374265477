import { useCallback } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { formatQueryParams, removeEmptyKeys } from '../helpers/utils';
import { PaginationParams } from '../types';

interface IUseFilterChangeHandler<TFilterValues> {
  filterHandler?: (values: TFilterValues) => void;
  filterValues?: TFilterValues;
  handlePaginationReset?: () => void;
  isCursorPagination?: boolean;
  paginationParams: PaginationParams;
  setSearchParams: SetURLSearchParams;
  setSyncedSearchParams?: (params: string) => void;
  sortSearchParam?: ISortingParam;
}

export const useFilterChangeHandler = <TFilterValues>({
  filterHandler,
  filterValues,
  handlePaginationReset,
  isCursorPagination = false,
  paginationParams,
  setSearchParams,
  setSyncedSearchParams,
  sortSearchParam,
}: IUseFilterChangeHandler<TFilterValues>) =>
  useCallback(
    (newFilterValues: Partial<TFilterValues>) => {
      // This needs to handle multiple tables' filters on one page like the current mechanism
      // Ticket - https://heidi-pay.atlassian.net/browse/HC-12836

      const updatedPaginationParamsWithFiltersForURL = {
        ...filterValues,
        ...newFilterValues,
        ...sortSearchParam,
        limit: paginationParams.limit.toString(),
        ...(isCursorPagination ? {} : { offset: 0 }),
      };

      const formattedQueryParams = formatQueryParams(
        updatedPaginationParamsWithFiltersForURL,
      );
      setSyncedSearchParams?.(formattedQueryParams);
      setSearchParams(formattedQueryParams);
      handlePaginationReset?.();
      filterHandler?.(removeEmptyKeys(newFilterValues) as TFilterValues);
    },
    [
      filterHandler,
      filterValues,
      handlePaginationReset,
      isCursorPagination,
      paginationParams,
      setSearchParams,
      setSyncedSearchParams,
      sortSearchParam,
    ],
  );
