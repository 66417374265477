import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { ICrabbResults } from '@schemas/opsPortal/types/crabb';
import { ICrabbTableData } from './types';

interface IMapCrabbTableData {
  data?: ICrabbResults;
}

export const mapCrabbTableData = ({ data }: IMapCrabbTableData) =>
  data?.map(dataRow => {
    const {
      duration,
      raw_request_data: rawRequestData,
      raw_response_data: rawResponseData,
      response_code: responseCode,
      salesforce_id: salesforceId,
      salesforce_model: salesforceModel,
      task_uuid: taskUuid,
    } = dataRow;

    return addPlaceholderForEmptyValues<ICrabbTableData>({
      taskUuid,
      salesforceId,
      salesforceModel,
      responseCode: responseCode.toString(),
      duration: duration.toString(),
      rawRequestData,
      rawResponseData,
    });
  }) ?? [];
