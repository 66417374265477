import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { Translate } from '@hooks/useI18n';
import { dictionary } from '@utils/dictionary';
import { getDisplayKeys } from '@utils/getDisplayKeys';

type UserObjKey = keyof typeof dictionary.user;

interface IGetDisplayDataListParams {
  translate: Translate;
  userData: Record<string, unknown>;
}

interface IDisplayData {
  key: string;
  value: string;
}

export const getDisplayDataList = ({
  translate,
  userData,
}: IGetDisplayDataListParams): IDisplayData[] => {
  const keys = getDisplayKeys({ data: userData, category: 'user' });
  const { user } = dictionary;

  return keys.map(key => {
    const userKey = key as UserObjKey;
    return addPlaceholderForEmptyValues<IDisplayData>({
      key: translate(user[userKey]),
      value: userData[userKey]?.toString(),
    });
  });
};
