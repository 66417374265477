import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IUnbalancedAllocations } from '@schemas/opsPortal/types/unbalancedAllocationDetails';
import { IAllocationTableColumns } from './types';

interface IMapUnbalancedAllocationsTableData {
  data: IUnbalancedAllocations;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapAllocationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapUnbalancedAllocationsTableData): IAllocationTableColumns[] =>
  data.map(dataRow => {
    const {
      amount,
      created,
      statement_line_reference: statementLineReference,
      statement_line_uuid: statementLineUuid,
      uuid: allocationUuid,
    } = dataRow;

    return addPlaceholderForEmptyValues<IAllocationTableColumns>({
      allocationAmount:
        amount?.amount &&
        getLocaleCurrencyValue({
          currency: amount.currency,
          value: amount.amount,
        }),
      allocationDate: getLocaleDate({ date: created }),
      allocationUuid,
      statementLineReference,
      statementLineUuid,
    });
  });
