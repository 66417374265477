import { useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalPendingAllocations } from '@schemas/opsPortal/types/pendingAllocations';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';
import { BankAccountReconciliationsTable } from '../bankAccountReconciliationsStyles';
import { ApprovalStatus } from './enums';
import { getPendingAllocationsColumns } from './getPendingAllocationsColumns';
import { mapPendingAllocationsTableData } from './mapPendingAllocationsTableData';
import { IPendingAllocationTableColumns } from './types';

interface IStylesProps {
  numColumns: number;
}

export const PendingAllocationsTable = styled('div', {
  shouldForwardProp: prop => prop !== 'numColumns',
})<IStylesProps>(({ numColumns }) => ({
  '& td, & th': {
    maxWidth: `calc(100% / ${numColumns})`,
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
}));

export const PendingAllocations = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const canReconcileBankAccount = useHasPermission([
    'ops_portal.can_reconcile_bank_account',
  ]);

  const refToDataTable =
    useRef<ITableRefObject<IPendingAllocationTableColumns>>(null);

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.PENDING_ALLOCATIONS);

  const queryKey = useMemo(
    () => [QueryKeys.PENDING_ALLOCATIONS, stringifiedQueryParams],
    [stringifiedQueryParams],
  );

  const {
    data: pendingAllocationsData,
    error: pendingAllocationsError,
    isLoading: isPendingAllocationsLoading,
  } = useQuery<IOpsPortalPendingAllocations, AxiosError>(queryKey, () =>
    fetchDataFromEndpoint(Endpoints.FetchPendingAllocations, {
      query: {
        ...queryParams,
        approvalStatus: ApprovalStatus.Pending,
      },
    }),
  );

  const columns = useMemo(
    () =>
      getPendingAllocationsColumns(
        canReconcileBankAccount,
        translate,
        queryKey,
      ),
    [canReconcileBankAccount, queryKey, translate],
  );

  const pendingAllocationsTableData = mapPendingAllocationsTableData({
    data: pendingAllocationsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const filters = getFilters({});

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: pendingAllocationsData?.next_cursor,
    prevCursor: pendingAllocationsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <QueryStateRouter
      error={pendingAllocationsError}
      isLoading={isPendingAllocationsLoading}
    >
      <Card
        title={translate(
          'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.PENDING_ALLOCATIONS',
        )}
      >
        <BankAccountReconciliationsTable>
          <PendingAllocationsTable numColumns={columns.length}>
            <ServersideTable
              columns={columns}
              data={pendingAllocationsTableData}
              filters={filters}
              isCursorPagination={true}
              manageControlledState={updateQueryParams}
              pagination={{
                gotoNextPage,
                gotoPrevPage,
                pageSize: queryParams.pageSize,
                totalItems: pendingAllocationsData?.count,
              }}
              ref={refToDataTable}
              resetFilters={resetFilters}
              tableState={queryState}
            />
          </PendingAllocationsTable>
        </BankAccountReconciliationsTable>
      </Card>
    </QueryStateRouter>
  );
};
