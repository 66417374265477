import { missingValuePlaceholder } from '@utils/constants';
import {
  IFormattedPaymentScheduleHistoryTableData,
  IMappedPaymentScheduleHistoryTableData,
} from '../../../utils/types';
import { IScheduleVersion } from './getScheduleVersions';
import { getUniqueScheduleHistoryPaymentData } from './getUniqueScheduleHistoryPaymentData';

interface IFormatScheduleHistoryData {
  scheduleHistoryData: IMappedPaymentScheduleHistoryTableData[];
  scheduleVersions: IScheduleVersion[];
}

interface IGetPaymentAmountForVersion {
  paymentDate: string;
  scheduleHistoryData: IMappedPaymentScheduleHistoryTableData[];
  versionId: number;
}
const getPaymentAmountForVersion = ({
  paymentDate,
  scheduleHistoryData,
  versionId,
}: IGetPaymentAmountForVersion): string => {
  const matchingPayment = scheduleHistoryData.find(
    payment =>
      payment.paymentDate === paymentDate &&
      Number(payment.scheduleVersion) === versionId,
  );

  return matchingPayment?.amountDue ?? missingValuePlaceholder;
};

export const formatScheduleHistoryData = ({
  scheduleHistoryData,
  scheduleVersions,
}: IFormatScheduleHistoryData): IFormattedPaymentScheduleHistoryTableData[] => {
  const uniquePaymentDataSortedAsc = getUniqueScheduleHistoryPaymentData({
    scheduleHistoryData,
  });

  return uniquePaymentDataSortedAsc.map(data => {
    const paymentDataForDate: IFormattedPaymentScheduleHistoryTableData = {
      ...data,
    };

    scheduleVersions.forEach(version => {
      paymentDataForDate[version.id] = getPaymentAmountForVersion({
        scheduleHistoryData,
        paymentDate: data.paymentDate,
        versionId: version.id,
      });
    });

    return paymentDataForDate;
  });
};
