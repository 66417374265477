import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalContractDocumentsV2 } from '@schemas/opsPortal/types/contractDocumentsV2';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

export const useGetMerchantDocumentsQuery = (merchantUuid: string) => {
  const queryKey = [QueryKeys.DOCUMENT_OPS_MERCHANT_DOCUMENTS, merchantUuid];

  const queryFn = () =>
    fetchDataFromEndpoint(
      `${Endpoints.FetchMerchantDocumentOpsUserDocuments}/${merchantUuid}`,
    );

  const { data, error, isLoading } = useQuery<
    IOpsPortalContractDocumentsV2,
    AxiosError
  >(queryKey, queryFn);

  return { data: data?.documents, error, isLoading };
};
