import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IMonetaTransactions } from '@schemas/opsPortal/types/unbalancedAllocationDetails';
import { dictionary } from '@utils/dictionary';
import { IMonetaTransactionsTableColumns } from './types';

interface IMapUnbalancedAllocationsTableData {
  data: IMonetaTransactions;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapMonetaTransactionsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapUnbalancedAllocationsTableData): IMonetaTransactionsTableColumns[] =>
  data.map(dataRow => {
    const {
      amount,
      settlement_type: settlementType,
      transaction_date: transactionDate,
      uuid: monetaTransactionUuid,
    } = dataRow;

    return addPlaceholderForEmptyValues<IMonetaTransactionsTableColumns>({
      monetaTransactionAmount:
        amount?.amount &&
        getLocaleCurrencyValue({
          currency: amount.currency,
          value: amount.amount,
        }),
      monetaTransactionDate: getLocaleDate({ date: transactionDate }),
      monetaTransactionType: translate(dictionary.api_label[settlementType]),
      monetaTransactionUuid,
    });
  });
