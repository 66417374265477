/* eslint-disable max-lines */
// enums
export const AccountSettingsFormNames = Object.freeze({
  ADDRESS: 'address',
  CODE: 'code',
  EMAIL: 'email',
  MANUAL_ADDRESS: 'manualAddress',
  NAME: 'name',
  PASSWORD: 'password',
  PHONE: 'phone',
});

export const AlertVariant = Object.freeze({
  ERROR: 'danger',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export const ApplicationStatuses = Object.freeze({
  ABANDONED: 'abandoned',
  APPLICATION_ONGOING: 'application_ongoing',
  AWAITING_AGREEMENT_SIGNING: 'awaiting_signing',
  AWAITING_VERIFICATION: 'awaiting_verification',
  DECLINED: 'declined',
  FAILED: 'failed',
  INITIALISED: 'initialised',
  SUCCESSFUL: 'successful',
});

export const AuthPathnames = Object.freeze({
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGIN: '/auth/login',
  REQUEST_REGISTRATION: '/auth/request-registration',
});

export const CommunicationsTypes = Object.freeze({
  EMAIL: 'email',
  LETTER: 'letter',
  SMS: 'text_message',
});

export const ContractStatuses = Object.freeze({
  ABANDONED: 'abandoned',
  ACTIVE: 'active',
  APPLICATION_ONGOING: 'application_ongoing',
  AWAITING_DISPATCH: 'awaiting_dispatch',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  DECLINED: 'declined',
  FAILED: 'failed',
  INITIALISED: 'initialised',
  PENDING_CLOSURE: 'pending_closure',
  PENDING_TERMINATION: 'pending_termination',
  SUCCESSFUL: 'successful',
  TERMINATED: 'terminated',
  TERMINATED_AND_PAID: 'terminated_and_paid',
  TERMINATION_FAILED: 'termination_failed',
  WRITTEN_OFF: 'written_off',
  WRITTEN_OFF_AND_PAID: 'written_off_and_paid',
});

export const EventTypes = Object.freeze({
  CHECKOUT: 'checkout',
  USER_ACCOUNT: 'user_account',
  COLLECTIONS: 'collections',
});

export const FilterTypes = Object.freeze({
  DATE_RANGE: 'daterange',
  DROPDOWN: 'dropdown',
  PRESET_DATE_RANGE: 'preset_daterange',
  SEARCH: 'search',
});

export const HotjarIds = Object.freeze({
  HEIDIPAY: '2721039',
  PAGOLIGHT: '3091536',
});

export const JSXTypes = Object.freeze({
  ELEMENT: 'element',
  FRAGMENT: 'fragment',
  TEXT: 'text',
});

export const LogoTypes = Object.freeze({
  PNG: 'png',
  SVG: 'svg',
});

export const LogoVariants = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
});

export const MerchantContractStatuses = Object.freeze({
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  CONFIRMED: 'confirmed',
  FULLY_REFUNDED: 'fully_refunded',
  NOT_APPLICABLE: 'not_applicable',
  PARTIALLY_REFUNDED: 'partially_refunded',
});

export const NodeNames = Object.freeze({
  ANCHOR: 'A',
  BODY: 'BODY',
  BREAK: 'BR',
  EMPHASIS: 'EM',
  LIST_ITEM: 'LI',
  ORDERED_LIST: 'OL',
  PARAGRAPH: 'P',
  STRONG: 'STRONG',
  UNDERLINE: 'U',
  UNORDERED_LIST: 'UL',
});

export const NodeTypes = Object.freeze({
  LINK: 'a',
  LIST_ITEM: 'li',
  // Plate creates a node type "lic" which seems to wrap LI content
  LIST_ITEM_CONTENT: 'lic',
  ORDERED_LIST: 'ol',
  PARAGRAPH: 'p',
  UNORDERED_LIST: 'ul',
});

export const Originators = Object.freeze({
  HEIDIPAY: 'HEIDIPAY',
  PAGOLIGHT: 'PAGOLIGHT',
  PAGOLIGHTPRO: 'PAGOLIGHTPRO',
});

export const OriginatorDisplayNamesHeyLight = Object.freeze({
  HEIDIPAY: 'HeyLight Switzerland',
  PAGOLIGHT: 'HeyLight Dilazione',
  PAGOLIGHTPRO: 'HeyLight Finanziamento',
});

export const OriginatorUUIDs = Object.freeze({
  HEIDIPAY: 'f93e233b-de32-4750-a021-fc4cd425cb74',
  PAGOLIGHT: '8d2e68ab-9b3c-4d50-bd80-2cc6825d7797',
  PAGOLIGHTPRO: 'a5413f30-a649-4179-86a1-604d308d4219',
});

export const PathnamePrefixes = Object.freeze({
  HEIDIPAY: '',
  PAGOLIGHT: '/pagolight',
  PAGOLIGHTPRO: '/pagolightpro',
});

export const HeyLightPath = Object.freeze({
  CH: '/HeyLight/CH',
  IT: '/HeyLight/IT',
});

export const HeyLightOriginatorRouteMapping = Object.freeze({
  HEIDIPAY: HeyLightPath.CH,
  PAGOLIGHT: HeyLightPath.IT,
  PAGOLIGHTPRO: HeyLightPath.IT,
});

export const PaymentMethods = Object.freeze({
  CARD: 'card',
  DIRECT_DEBIT: 'direct_debit',
  SWISS_QR: 'swiss_qr',
});

export const PaymentTypes = Object.freeze({
  ADJUSTMENTS: 'adjustments',
  MANUAL_PAYMENT: 'manual_payment',
  PREPAYMENT: 'prepayment',
  REFUND: 'refund',
  REIMBURSEMENT: 'reimbursement',
});

export const PerformanceStatuses = Object.freeze({
  COMPLETED: 'completed',
  DELAYED: 'delayed',
  OVERDUE: 'overdue',
  PERFORMING: 'performing',
});

export const UserDocumentClassifications = Object.freeze({
  ACKNOWLEDGEMENT_OF_DEBT: 'acknowledgement_of_debt',
  CREDIT_CONTRACT_SIGNED_INSTORE: 'credit_contract_signed_in_store',
  COMPLAINT_ABOUT_LATE_FEE: 'complaint_about_late_fee',
  COMPLAINT_ABOUT_MERCHANT: 'complaint_about_merchant',
  COMPLAINT_ABOUT_DEBT_COLLECTION_AGENCY:
    'complaint_about_debt_collection_agency',
  COMPLAINT_GENERAL: 'complaint_general',
  REPLY_TO_A_COMPLAINT: 'reply_to_a_complaint',
  IMPORTANT_COMMUNICATION_FROM_CUSTOMER:
    'important_communication_from_customer',
  OTHER: 'other',
});

export const Portals = Object.freeze({
  CONSUMER: 'consumer',
  MERCHANT: 'merchant',
  OPS: 'ops',
});

export const QueryKeys = Object.freeze({
  ACCOUNT_GROUPS: 'AccountGroups',
  ACCOUNT_SETTINGS: 'AccountSettings',
  ACCOUNT_USER_GROUPS: 'AccountUserGroups',
  ACKNOWLEDGEMENT_OF_DEBT: 'AcknowledgementOfDebt',
  ACTION_LOGS: 'ActionLogs',
  ALLOCATION_DETAILS: 'AllocationDetailsData',
  PENDING_ALLOCATIONS: 'PendingAllocationsData',
  API_CALLS: 'ApiCalls',
  API_KEYS: 'ApiKeys',
  APPLICANT_RISK_OVERRIDE: 'ApplicantRiskOverrideData',
  APPLICANT_RISK_OVERRIDES_LIST: 'ApplicantRiskOverridesList',
  APPLICATION_DETAILS: 'ApplicationDetailsData',
  APPLICATION_SEARCH: 'ApplicationSearch',
  APPLICATIONS_TABLE_DATA: 'ApplicationsTableData',
  ASSIGN_OVERRIDE_BUCKET: 'AssignOverrideBucket',
  ASSIGNMENT_HISTORY: 'AssignmentHistory',
  ARREARS_MANAGEMENT: 'ArrearsManagement',
  AWAITING_DISPATCH_TABLE_DATA: 'AwaitingDispatchTableData',
  BANK_ACCOUNT_TRANSACTIONS: 'BankAccountTransactions',
  BLOCKED_APPLICANTS: 'BlockedApplicants',
  BUCKET_GET: 'BucketGet',
  BUCKET_SET: 'BucketSet',
  CARD_ATTEMPTS: 'CardAttempts',
  COLLECTION_AGENCIES_LIST: 'CollectionAgenciesList',
  COLLECTIONS_BLOCK_AUTO_REFERRAL: 'CollectionsBlockAutoReferral',
  COLLECTIONS_DATA: 'CollectionsData',
  COLLECTIONS_QUEUE_TABLE_DATA: 'CollectionsQueueTableData',
  COLLECTIONS_TABLE_DATA: 'CollectionsTableData',
  COLLECTIONS_TIMELINE_EVENTS: 'CollectionsTimelineEvents',
  COMMUNICATIONS_EVENT: 'CommunicationsEvent',
  COMMUNICATIONS_EVENTS: 'CommunicationsEvents',
  CONSUMER_ADDRESS: 'ConsumerAddress',
  CONSUMER_DETAILS: 'ConsumerDetails',
  CONSUMER_TABLE_DATA: 'ConsumerTableData',
  CONTRACT_DETAILS_DATA: 'ContractDetailsData',
  CONTRACT_DOCUMENTS: 'ContractDocuments',
  CONTRACT_DOCUMENTS_CUSTOMER_AGREEMENT: 'ContractDocumentsCustomerAgreement',
  CONTRACT_DOCUMENTS_INTRUM: 'ContractDocumentsIntrum',
  CONTRACT_DOCUMENTS_ONFIDO: 'ContractDocumentsOnfido',
  CONTRACT_DOCUMENTS_TERMS_AND_CONDITIONS:
    'ContractDocumentsTermsAndConditions',
  CONTRACT_DOCUMENTS_OPS_USER_DOCUMENTS: 'ContractDocumentsOpsUserDocuments',
  CONTRACTS_BY_UUIDS: 'ContractsUuidsTableData',
  CONTRACT_SEARCH: 'ContractSearch',
  CONTRACTS_TABLE_DATA: 'ContractsTableData',
  CRABB_DATA: 'CrabbData',
  DOCUMENT_OPS_MERCHANT_DOCUMENTS: 'MerchantDocumentsOpsUserDocuments',
  DELETE_FEES: 'DeleteFees',
  DOCUMENTS: 'Documents',
  DOCUMENT: 'Document',
  DOCUMENT_CUSTOMER_AGREEMENT: 'DocumentCustomerAgreement',
  DOCUMENT_INTRUM: 'DocumentIntrum',
  DOCUMENT_ONFIDO: 'DocumentCustomerAgreement',
  DOCUMENT_TERMS_AND_CONDITIONS: 'DocumentTermsAndConditions',
  DOCUMENT_OPS_USER_DOCUMENT: 'DocumentOpsUserDocument',
  DOCUMENT_OPS_MERCHANT_DOCUMENT: 'DocumeentMerchantDocument',
  DOWNLOAD_QR_INVOICE: 'DownloadQrInvoice',
  DOWNLOAD_CUSTOMER_AGREEMENT: 'DownloadCustomerAgreement',
  DOWNLOAD_SIGNED_CUSTOMER_AGREEMENT: 'DownloadSignedCustomerAgreement',
  DOWNLOAD_QR_PDF: 'DownloadQrPdf',
  EARLY_SETTLEMENT_DISPLAY: 'EarlySettlementDisplay',
  EARLY_SETTLEMENTS: 'EarlySettlements',
  EMAIL_TEMPLATES_LIST: 'EmailTemplatesList',
  FULL_MERCHANT_LIST: 'FullMerchantsList',
  GENERATE_CERTIFICATE_OF_INTEREST: 'GenerateCertificateOfInterests',
  IBAN: 'IBAN',
  INDUSTRY_CHOICES: 'IndustryChoices',
  INIT_CARD_CHANGE: 'InitCardChange',
  IS_BLOCKED: 'IsBlocked',
  LIST_CERTIFICATE_OF_INTEREST: 'ListCertificateOfInterests',
  LIST_AGENCIES: 'FetchAgenciesList',
  LIST_BUCKETS: 'ListBuckets',
  MEDIOBANCA_SETTLEMENTS: 'MediobancaSettlements',
  MERCHANT_DETAILS: 'MerchantDetails',
  MERCHANTS_TABLE_DATA: 'MerchantsTableData',
  MERCHANT_STORE: 'MerchantStore',
  MERCHANT_STORES_TABLE_DATA: 'MerchantStoresTableData',
  MONETA_BALANCE_DATA: 'MonetaBalanceData',
  MONETA_CONTRACT_DATA: 'MonetaContractData',
  MONETA_SETTLEMENTS_DATA: 'MonetaSettlementsData',
  MY_ORDER_DETAILS: 'MyOrderDetails',
  MY_ORDERS_TABLE_DATA: 'MyOrdersTableData',
  NEXT_PAYMENT_DATA: 'NextPaymentData',
  NOTES_DATA: 'NotesData',
  ORDER_ARREARS_DATA: 'OrderArrearsData',
  ORDER_DETAILS: 'OrderDetails',
  ORDER_TRACKING: 'OrderTracking',
  ORDERS_TABLE_DATA: 'OrdersTableData',
  ORGANISATION_INFO: 'OrganisationInfo',
  OVERPAID_CONTRACTS: 'OverpaidContracts',
  OVERPAID_CONTRACTS_TABLE_DATA: 'OverpaidContractsTableData',
  PAUSED_CONTRACTS: 'PausedContracts',
  PAUSED_CONTRACTS_TABLE_DATA: 'PausedContractsTableData',
  PAUSE_COMMUNICATIONS: 'PauseCommunications',
  PAYMENTS_EVENTS: 'PaymentsEvents',
  PAYMENT_LINK: 'PaymentLink',
  PORTAL: 'Portal',
  RISK_AND_WATERFALL: 'RiskAndWaterfall',
  RISK_APPLICATION_DETAILS: 'RiskApplicationDetails',
  SETTLEMENT_CSV_DOWNLOAD: 'SettlementCsvDownload',
  SETTLEMENTS_TABLE_DATA: 'SettlementsTableData',
  TERMINABLE_CONTRACTS: 'TerminableContracts',
  TRANSACTIONS_SUMMARY: 'OverpaidContracts',
  TRANSACTIONS_TABLE_DATA: 'TransactionsTableData',
  UNBALANCED_ALLOCATIONS: 'UnbalancedAllocationsData',
});

export const RawStatuses = Object.freeze({
  ACTIVE: 'active',
  ACTIVE_ALT: 'Active',
  ADVANCE: 'advance',
  APPLICATION_ONGOING: 'application_ongoing',
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  AWAITING_DISPATCH: 'awaiting_dispatch',
  AWAITING_DISPATCH_ALT: 'Awaiting Dispatch',
  BLOCKED: 'blocked',
  CLAWBACK: 'clawback',
  CLOSED: 'closed',
  CLOSED_ALT: 'Closed',
  COMPLETED: 'completed',
  COLLECTIONS: 'collections',
  CONFIRMED: 'confirmed',
  DECLINED: 'declined',
  DELAYED: 'delayed',
  FEE: 'fee',
  INITIALISED: 'initialised',
  INSTALLMENT: 'installment',
  NA: 'n/a',
  OVERDUE: 'overdue',
  PARTIALLY_REFUNDED: 'partially_refunded',
  PAYMENTS_PAUSED: 'payments_paused',
  PERFORMING: 'performing',
  PREPAYMENT: 'prepayment',
  REFUND: 'refund',
  SALE: 'sale',
  TERMINATED: 'terminated',
  TERMINATED_ALT: 'Terminated',
  TERMINATED_AND_PAID: 'terminated_and_paid',
  TERMINATED_AND_PAID_ALT: 'Terminated And Paid',
  UNKNOWN: 'unknown',
});

export const ReceivableTypes = Object.freeze({
  ADVANCE: 'ADVANCE',
  CLAWBACK: 'CLAWBACK',
  FEE: 'FEE',
  INSTALLMENT: 'INSTALLMENT',
  PREPAYMENT: 'PREPAYMENT',
  REFUND: 'REFUND',
  SALE: 'SALE',
});

export const ReduxPersistKeys = Object.freeze({
  AUTH: 'heidipay-portals-auth',
  ORIGINATOR: 'heidipay-portals-originator',
});

export const RiskTestOutcomes = Object.freeze({
  GREEN: 'Green',
  RED: 'Red',
  UNKNOWN: 'Unknown',
});

export const SettlementTypes = Object.freeze({
  CARD_PAYMENT: 'CARD_PAYMENT',
  BANK_TRANSFER: 'BANK_TRANSFER',
});

export const SortDirection = Object.freeze({
  asc: 'ascending',
  desc: 'descending',
});

export const SortingFunctions = Object.freeze({
  ALPHANUMERIC: 'alphanumeric',
  CURRENCY: 'sortCurrency',
  DATETIME: 'datetime',
  NUMERIC: 'sortNumeric',
});

export const StatusPillClasses = Object.freeze({
  DARK: 'dark',
  [RawStatuses.ACTIVE]: 'info',
  [RawStatuses.ACTIVE_ALT]: 'info',
  [RawStatuses.ADVANCE]: 'info',
  [RawStatuses.APPLICATION_ONGOING]: 'info',
  [RawStatuses.AWAITING_CONFIRMATION]: 'info',
  [RawStatuses.AWAITING_DISPATCH]: 'info',
  [RawStatuses.AWAITING_DISPATCH_ALT]: 'info',
  [RawStatuses.BLOCKED]: 'danger',
  [RawStatuses.CLAWBACK]: 'danger',
  [RawStatuses.CLOSED]: 'dark',
  [RawStatuses.CLOSED_ALT]: 'dark',
  [RawStatuses.COLLECTIONS]: 'danger',
  [RawStatuses.COMPLETED]: 'info',
  [RawStatuses.CONFIRMED]: 'info',
  [RawStatuses.DECLINED]: 'danger',
  [RawStatuses.DELAYED]: 'danger',
  [RawStatuses.FEE]: 'info',
  [RawStatuses.INITIALISED]: 'info',
  [RawStatuses.INSTALLMENT]: 'dark',
  [RawStatuses.NA]: 'dark',
  [RawStatuses.OVERDUE]: 'danger',
  [RawStatuses.PARTIALLY_REFUNDED]: 'dark',
  [RawStatuses.PAYMENTS_PAUSED]: 'danger',
  [RawStatuses.PERFORMING]: 'info',
  [RawStatuses.PREPAYMENT]: 'info',
  [RawStatuses.REFUND]: 'info',
  [RawStatuses.SALE]: 'info',
  [RawStatuses.TERMINATED]: 'dark',
  [RawStatuses.TERMINATED_ALT]: 'dark',
  [RawStatuses.TERMINATED_AND_PAID]: 'dark',
  [RawStatuses.TERMINATED_AND_PAID_ALT]: 'dark',
  [RawStatuses.UNKNOWN]: 'dark',
});

export const SupportedLanguages = Object.freeze({
  ENGLISH: 'en',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
});

export const TabEventKeys = Object.freeze({
  APPLICANT_DETAILS: 'applicant-details',
  APPLICATION_OVERVIEW: 'application-overview',
  ASSIGNMENT_HISTORY: 'assignment-history',
  CONSUMER_ACTIVE_CONTRACTS: 'consumer-active-contracts',
  CONSUMER_APPLICATIONS: 'consumer-applications',
  CONSUMER_CLOSED_CONTRACTS: 'consumer-closed-contracts',
  CONSUMER_DETAILS: 'consumer-details',
  CONSUMER_FUTURE_PAYMENTS: 'consumer-future-payments',
  CONSUMER_PAYMENTS: 'consumer-payments',
  CONTRACT_CARD_ATTEMPTS: 'contract-card-attempts',
  CONTRACT_DETAILS: 'contract-details',
  CONTRACT_DOCUMENTS: 'contract-documents',
  CONTRACT_OVERVIEW: 'contract-overview',
  CONTRACT_PERFORMANCE: 'contract-performance',
  CONTRACT_PAYMENTS: 'contract-payments',
  CONTRACT_PAYMENT_SCHEDULE: 'contract-payment-schedule',
  CONTRACT_PAYMENT_SCHEDULE_HISTORY: 'contract-payment-schedule-history',
  CERTIFICATE_OF_INTEREST: 'certificate-of-interest',
  CRABB: 'crabb',
});

export const TableNames = Object.freeze({
  ACTION_LOGS: 'actionLogs',
  API_CALLS: 'apiCalls',
  APPLICATIONS: 'applications',
  AWAITING_DISPATCH: 'awaiting_dispatch',
  ASSIGNMENT_HISTORY: 'assignment_history',
  BANK_ACCOUNT_RECONCILIATIONS: 'bank_account',
  BLOCKED_APPLICANTS: 'blocked_applicants',
  CARD_ATTEMPTS: 'cardAttempts',
  CERTIFICATE_OF_INTEREST: 'certificateOfInterest',
  COLLECTIONS: 'collections',
  COLLECTIONS_QUEUE: 'collections_queue',
  COMMUNICATIONS: 'communications',
  CONSUMER_ACTIVE_CONTRACTS: 'consumer_active_contracts',
  CONSUMER_APPLICATIONS: 'consumer_applications',
  CONSUMER_CLOSED_CONTRACTS: 'consumer_closed_contracts',
  CONSUMER_PAYMENTS: 'consumer_payments',
  CONSUMERS: 'consumers',
  CONTRACTS: 'contracts',
  CONTRACTS_BY_UUIDS: 'contracts_by_uuids',
  CRABB: 'crabb',
  DOCUMENTS: 'documents',
  EARLY_SETTLEMENTS: 'early_settlements',
  EVENTS_TIMELINE: 'events_timeline',
  MEDIOBANCA_RECONCILIATIONS: 'mediobanca_reconciliations',
  MERCHANTS: 'merchants',
  MERCHANT_STORES: 'merchants_stores',
  NOTES: 'notes',
  ORDERS: 'orders',
  OVERPAID_CONTRACTS: 'overpaid_contracts',
  PAUSED_CONTRACTS: 'paused_contracts',
  PAYMENTS: 'payments',
  PAYMENT_SCHEDULE: 'payment_schedule',
  PAYMENT_SCHEDULE_HISTORY: 'payment_schedule_history',
  PENDING_ALLOCATIONS: 'allocation_transactions',
  SETTLEMENT_TRANSACTIONS: 'settlementTransactions',
  SETTLEMENTS: 'settlements',
  SUSPENSE: 'suspense',
  TERMINABLE_CONTRACTS: 'terminable_contracts',
  UNBALANCED_ALLOCATIONS: 'unbalancedAllocations',
  USERS: 'users',
});

export const TransactionEntityTypes = Object.freeze({
  CONSUMER: 'CONSUMER',
  FUNDING_ENTITY: 'FUNDING_ENTITY',
  HEIDI_PAY: 'HEIDI_PAY',
  MERCHANT: 'MERCHANT',
});

export const TransactionTypes = Object.freeze({
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
  CANCELLATION: 'CANCELLATION',
  CHARGEBACK: 'CHARGEBACK',
  WRITE_OFF: 'WRITE_OFF',
  WRITE_BACK: 'WRITE_BACK',
  ARREARS_ADJUSTMENT: 'ARREARS_ADJUSTMENT',
});

export const Views = Object.freeze({
  AWAITING_DISPATCH: 'awaiting_dispatch',
  COLLECTIONS: 'collections',
  CONTRACTS: 'contracts',
  OVERPAID_CONTRACTS: 'overpaid_contracts',
  PAUSED_CONTRACTS: 'paused_contracts',
  TERMINABLE_CONTRACTS: 'terminable_contracts',
  CONTRACTS_BY_UUIDS: 'contracts_by_uuids',
});

export const LoginPath = Object.freeze({
  Swiss: '/HeyLight/CH/auth/login',
  Italian: '/HeyLight/IT/auth/login',
});

// other constants
const defaultPageIndex = 0;

export const defaultPageSize = 10;

export const initialQueryState = {
  columnFilters: [],
  globalFilter: '',
  pagination: {
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize,
  },
  sorting: [],
};

export const supportedLanguages = Object.values(SupportedLanguages);

export const tablePageSizeOptions = ['10', '25', '50', '100', '500', '1000'];

export const schemaPrefix = 'http://heidipay.com/schemas';

export const missingValuePlaceholder = '—';

export const matchingContractUuidsDisplayLimit = 50;

export const resourcesBucketUrl =
  'https://storage.googleapis.com/heidipay-fe-resources-public/';

export const HeyLightBranding = Object.freeze({
  logo: 'https://storage.googleapis.com/heidipay-fe-resources-public/logos/heylight_light_standard.svg',
  primaryColour: '#ea1c00',
});

export const SentrySeverity = Object.freeze({
  debug: 'debug',
  error: 'error',
  fatal: 'fatal',
  info: 'info',
  log: 'log',
  warning: 'warning',
});
