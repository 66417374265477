import { useGetFilters } from '@components/TableV2/hooks/useGetFilters';
import { IFilterSettings } from '@components/TableV2/types';
import { Translate } from '@hooks/useI18n/types';
import {
  allocationType,
  getDropdownFromArray,
} from '@hooks/useQueryState/utils/getBankAccountTransactionFilters';
import { FilterTypes, TableNames } from '@utils/constants';
import { IPendingAllocationsFilterValues } from './types';

interface IUseGetPendingAllocationsFilters {
  initialDefaultValues: IPendingAllocationsFilterValues;
  translate: Translate;
}

export const useGetPendingAllocationsFilters = ({
  initialDefaultValues,
  translate,
}: IUseGetPendingAllocationsFilters): IFilterSettings<IPendingAllocationsFilterValues> => {
  const { filterHandler, filterValues, resetFiltersHandler } = useGetFilters(
    TableNames.PENDING_ALLOCATIONS,
    initialDefaultValues,
  );

  const allocationTypeDropdown = getDropdownFromArray({
    translate,
    optionsArray: allocationType,
    accessorKey: 'allocationType',
    name: 'OPS_PORTAL.LABEL.ALLOCATION_TYPE',
    value: filterValues?.allocationType ?? '',
  });

  return {
    filters: [
      allocationTypeDropdown,
      {
        accessorKey: 'allocationDate',
        from: {
          id: 'allocationDateFrom',
          label: translate('LABEL.START_DATE'),
          value: filterValues.allocationDateFrom ?? '',
        },
        id: '',
        to: {
          id: 'allocationDateTo',
          label: translate('LABEL.END_DATE'),
          value: filterValues.allocationDateTo ?? '',
        },
        type: FilterTypes.DATE_RANGE,
      },
    ],
    filterHandler,
    initialDefaultValues,
    resetFiltersHandler,
    filterValues,
  };
};
