import { useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { updateOrderingModel } from '../helpers/updateOrderingModel';
import { LimitOffsetPaginationModel } from '../types';

export interface IUseSyncURLParamsLimitOffsetPagination<TFilterValues> {
  filterHandler?: (values: TFilterValues) => void;
  handlePagination: (paginationParams: LimitOffsetPaginationModel) => void;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
  searchParams: URLSearchParams;
}

export const useSyncURLParamsLimitOffset = <TFilterValues>({
  filterHandler,
  handlePagination,
  handleSortModelChange,
  searchParams,
}: IUseSyncURLParamsLimitOffsetPagination<TFilterValues>) => {
  const [syncedUrlParams, setSyncedUrlParams] = useState<string>();

  useEffect(() => {
    const searchParamsToString = searchParams.toString();
    const hasUrlParamsChanged =
      syncedUrlParams === undefined || syncedUrlParams !== searchParamsToString;

    if (hasUrlParamsChanged) {
      const urlParams = Object.fromEntries(searchParams);
      const { limit, offset, ordering, ...filters } = urlParams;

      filterHandler?.({ ...filters } as TFilterValues);

      updateOrderingModel({ handleSortModelChange, ordering });

      const calcPageSize = limit ? Number(limit) : 10;
      const calcPage =
        !offset || !limit ? 0 : Math.floor(Number(offset) / Number(limit));
      handlePagination({
        pageSize: calcPageSize,
        page: calcPage,
      });

      setSyncedUrlParams(searchParams.toString());
    }
  }, [
    filterHandler,
    handleSortModelChange,
    handlePagination,
    searchParams,
    syncedUrlParams,
  ]);
};
