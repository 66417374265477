import { QueryStateRouter } from '@components/QueryStateRouter';
import { StaticTable } from '@components/Table';
import { useDocumentsTableData } from '@hooks/useDocumentsTab';
import { OpsPortalDocumentModes } from '@hooks/useDocumentsTab/enums';

interface IMerchantDocumentTable {
  merchantUuid: string;
}

export const MerchantDocumentTable = ({
  merchantUuid,
}: IMerchantDocumentTable) => {
  const {
    error: documentsError,
    isLoading: isDocumentsLoading,
    tableProps: documentTableProps,
  } = useDocumentsTableData(merchantUuid, OpsPortalDocumentModes.Merchant);

  return (
    <QueryStateRouter error={documentsError} isLoading={isDocumentsLoading}>
      <StaticTable {...documentTableProps} />
    </QueryStateRouter>
  );
};
