import { Formik, Form } from 'formik';
import { TextField } from '@components/TextField/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getValidationSchema } from './getValidationSchema';

interface IRequest {
  new_password: string;
  new_password_confirm: string;
  old_password: string;
}

interface IPasswordForm {
  formID: string;
  handleSubmit: (request: IRequest) => void;
}

export const PasswordForm = ({ formID, handleSubmit }: IPasswordForm) => {
  const { translate } = useI18n();

  const initialFormikValues: IRequest = {
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(translate)()}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="old_password"
          label={translate('ACCOUNT.OLD_PASSWORD')}
          name="old_password"
          type="password"
        />
        <TextField
          id="new_password"
          label={translate('ACCOUNT.NEW_PASSWORD')}
          name="new_password"
          type="password"
        />
        <TextField
          id="new_password_confirm"
          label={translate('ACCOUNT.CONFIRM_NEW_PASSWORD')}
          name="new_password_confirm"
          type="password"
        />
      </Form>
    </Formik>
  );
};
