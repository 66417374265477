import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { ISettlements } from '@schemas/merchantPortal/types/settlements';
import { getTranslatedData } from '@utils/getTranslatedData';
import { ISettlementsTableColumns } from './types';

interface IPrepareSettlementsTableDataProps {
  data?: ISettlements;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const prepareSettlementsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IPrepareSettlementsTableDataProps) =>
  data?.map(dataRow => {
    const {
      amount,
      payee,
      payor,
      settlement_date: settlementDate,
      settlement_reference: settlementRef,
      type,
      uuid: settlementId,
    } = dataRow;

    const { currency } = amount;

    const settlementAmount =
      currency &&
      getLocaleCurrencyValue({
        currency,
        value: amount.amount,
      });

    const settlementType = getTranslatedData({ term: type, translate });
    const translatedPayee = getTranslatedData({ term: payee.type, translate });
    const translatedPayor = getTranslatedData({ term: payor.type, translate });

    return addPlaceholderForEmptyValues<ISettlementsTableColumns>({
      settlementId,
      settlementRef,
      settlementType,
      settlementAmount,
      settlementDate: settlementDate && getLocaleDate({ date: settlementDate }),
      payor: translatedPayor,
      payee: translatedPayee,
    });
  });
