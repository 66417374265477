import { addIdToRows } from '@components/TableV2/helpers/utils';
import { TableCursorPaginationV2 } from '@components/TableV2/TableCursorPaginationV2';
import { useI18n } from '@hooks/useI18n/useI18n';
import { BankAccountReconciliationsTable } from '../../bankAccountReconciliationsStyles';
import { PendingAllocationsTable } from '../PendingAllocations';
import { useGetPendingAllocationsTableConfig } from './useGetPendingAllocationsTableConfig';

export const PendingAllocationsV2 = () => {
  const { translate } = useI18n();

  const {
    columns,
    data: pendingAllocationsData,
    error,
    filtersSettings,
    isLoading,
    paginationSettings,
    sortingSettings,
  } = useGetPendingAllocationsTableConfig({ translate });

  return (
    <BankAccountReconciliationsTable>
      <PendingAllocationsTable numColumns={columns.length}>
        <TableCursorPaginationV2
          columns={columns}
          count={pendingAllocationsData?.count ?? 10}
          data={addIdToRows(pendingAllocationsData?.results ?? [])}
          error={error}
          filterSettings={filtersSettings}
          isLoading={isLoading}
          name="PendingAllocations"
          paginationSettings={paginationSettings}
          sortingSettings={sortingSettings}
          title={translate(
            'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.PENDING_ALLOCATIONS',
          )}
        />
      </PendingAllocationsTable>
    </BankAccountReconciliationsTable>
  );
};
