import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ButtonWrapper,
  AuthButtonWrapper,
  LinkWrapper,
  Spinner,
  SpinnerButton,
} from '@auth/pages/Auth.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AuthPathnames } from '@utils/constants';
import { ButtonVariant } from '@utils/enums';
import { OriginatorRouterLink } from '../OriginatorRouterLink';
import { AuthFormFields } from './AuthFormFields';
import { IAuthFormProps } from './types';
import { useGetAuthFormDetails } from './useGetAuthFormDetails';

export const AuthFormLogin = ({
  fields,
  handleSubmit,
  initialValues,
  isConsumerPortal = false,
}: IAuthFormProps) => {
  const { translate } = useI18n();
  const { labels, schemaShape } = useGetAuthFormDetails({
    fields,
    isLogin: true,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(schemaShape)}
    >
      {({ errors, isSubmitting, status, touched }) => {
        return (
          <Form>
            <AuthFormFields
              errors={errors}
              fields={fields}
              labels={labels}
              status={status}
              touched={touched}
            />
            <AuthButtonWrapper>
              <LinkWrapper>
                <OriginatorRouterLink
                  className="text-dark-50 text-hover-primary"
                  id="kt_login_forgot"
                  to={AuthPathnames.FORGOT_PASSWORD}
                >
                  {translate('AUTH.FORGOT_PASSWORD')}
                </OriginatorRouterLink>
                {isConsumerPortal ? (
                  <OriginatorRouterLink
                    className="text-dark-50 text-hover-primary"
                    id="kt_login_forgot"
                    to={AuthPathnames.REQUEST_REGISTRATION}
                  >
                    {translate('AUTH.RECEIVE_ACCOUNT_ACCESS_LINK')}
                  </OriginatorRouterLink>
                ) : null}
              </LinkWrapper>

              <ButtonWrapper>
                <SpinnerButton
                  disabled={isSubmitting}
                  id="kt_login_signin_submit"
                  type="submit"
                  variant={ButtonVariant.Primary}
                >
                  <span>{translate('AUTH.SIGN_IN')}</span>
                  {isSubmitting ? <Spinner /> : null}
                </SpinnerButton>
              </ButtonWrapper>
            </AuthButtonWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
