import { InternalAxiosRequestConfig } from 'axios';
import { EnhancedStore } from '@reduxjs/toolkit';
import { actions } from '@auth/_redux';
import { logoutChannel } from '@auth/hooks/useCheckForLogOut';
import { checkIfCanRedirect } from './checkIfCanRedirect';

interface IVerifySessionProps {
  config: InternalAxiosRequestConfig;
  store: EnhancedStore;
}

export const verifySession = ({
  config,
  store,
}: IVerifySessionProps): InternalAxiosRequestConfig => {
  const {
    auth: { authToken, user },
  } = store.getState();

  const { merchant_uuid: merchantUuid } = user ?? {};

  if (authToken) {
    const decodedToken = JSON.parse(window.atob(authToken.split('.')[1]));
    const sessionExpiresAt = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const isSessionExpired = sessionExpiresAt < currentTime;
    const isWrongMerchant =
      merchantUuid && merchantUuid !== decodedToken.merchant;

    if (
      (isSessionExpired && checkIfCanRedirect(config.url)) ||
      isWrongMerchant
    ) {
      logoutChannel.postMessage('Logout');

      const { dispatch } = store;
      dispatch(actions.logout());
    }
  }

  return config;
};
