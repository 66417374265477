import { useQueryClient, useMutation } from '@tanstack/react-query';
import { IActionButton } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission/useHasPermission';
import { useI18n } from '@hooks/useI18n';
import { IOpsPortalApplicantRiskOverrides } from '@schemas/opsPortal/types/applicantRiskOverrides';
import { QueryKeys } from '@utils/constants';
import { Endpoints, ButtonVariant } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseDeleteRiskTestOverrides {
  applicantRiskOverrideData?: IOpsPortalApplicantRiskOverrides;
  applicantUuid?: string;
}

export const useDeleteRiskTestOverrides = ({
  applicantRiskOverrideData,
  applicantUuid,
}: IUseDeleteRiskTestOverrides) => {
  const { translate } = useI18n();
  const queryClient = useQueryClient();
  const canDeleteRiskOverride = useHasPermission([
    'ops_portal.can_create_risk_override',
  ]);

  const { mutate } = useMutation({
    mutationFn: (uuid: string) =>
      postDataToEndpoint({
        endpoint: Endpoints.DeleteApplicantOverride,
        requestData: { applicant_uuid: uuid },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.APPLICANT_RISK_OVERRIDE,
        applicantUuid,
      ]);
    },
  });

  const hasCurrentRiskOverride =
    Array.isArray(applicantRiskOverrideData) &&
    applicantRiskOverrideData.some(
      override => !override.is_expired && override.override_lists.length > 0,
    );

  const handleDeleteBlock = () => applicantUuid && mutate(applicantUuid);

  if (!hasCurrentRiskOverride || !canDeleteRiskOverride || !applicantUuid) {
    return { deleteRiskTestOverridesButton: [] };
  }

  const deleteRiskTestOverridesButton: IActionButton[] = [
    {
      text: translate('OPS_PORTAL.BUTTON.DELETE_RISK_TEST_OVERRIDES'),
      clickHandler: handleDeleteBlock,
      variant: ButtonVariant.Secondary,
      buttonKey: 'deleteApplicantRiskTestOverrides',
    },
  ];

  return { deleteRiskTestOverridesButton };
};
