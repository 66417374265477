import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components/index';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IConsumerPortalAccountSettings } from '@schemas/consumerPortal/types/accountSettings';
import { IMerchantPortalAccountSettings } from '@schemas/merchantPortal/types/accountSettings';
import { IOpsPortalAccountSettings } from '@schemas/opsPortal/types/accountSettings';
import { QueryKeys } from '@utils/constants';
import { getErrorMessage } from '@utils/getErrorMessage/getErrorMessage';
import { putDataToEndpoint } from '@utils/putDataToEndpoint/putDataToEndpoint';
import { EditNameForm } from './EditNameForm';

interface IUseEditNameModal extends ISharedModalProps {
  data?:
    | IConsumerPortalAccountSettings
    | IMerchantPortalAccountSettings
    | IOpsPortalAccountSettings;
}

interface IRequestData {
  email: string;
  first_name: string;
  last_name: string;
}

export const useEditNameModal = (props: IUseEditNameModal): Modal => {
  const { data } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName: 'editNameModal',
    permissions: ['merchant_portal.can_view_account_info'],
    buttonTranslationKey: 'PAGE.ACCOUNT_SETTINGS.EDIT_NAME',
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IRequestData
  >(
    requestData =>
      putDataToEndpoint({
        endpoint: 'user/settings',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ACCOUNT_SETTINGS]);
        modal.resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.NAME_SUCCESSFULLY_EDITED'),
        );
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_NAME',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'edit-name';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <EditNameForm data={data} formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_NAME'),
  };

  return getModalDetails({ modal, modalProps });
};
