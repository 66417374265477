import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF } from '@components/Dropdown/DropdownRHF';
import { TextBoxRHF } from '@components/TextBox';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import { getCompassNoteTypesDropDownOptions } from '../../../../components/Notes/getCompassNoteTypesDropDownOptions';

interface IAddNoteFormProps {
  formID: string;
  handleSubmit: (values: { note: string }) => void;
}

export const AddNoteForm = ({ formID, handleSubmit }: IAddNoteFormProps) => {
  const { translate } = useI18n();

  const defaultValues = {
    compass_type: '',
    note: '',
  };

  const validationSchema = Yup.object({
    note: Yup.string().required(translate(ValidationErrorKeys.Required)),
    compass_type: Yup.string().optional(),
  });

  const methods = useForm<{ note: string }>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <TextBoxRHF
          id="note"
          label={translate('OPS_PORTAL.TITLE.MESSAGE')}
          name="note"
        />
        <DropdownRHF
          id="compass_type"
          label={translate('OPS_PORTAL.TITLE.COMPASS_TYPE')}
          name="compass_type"
          options={getCompassNoteTypesDropDownOptions({ translate })}
        />
      </form>
    </FormProvider>
  );
};
