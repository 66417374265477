import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { ColourBox } from '@components/ColourBox';
import { IDataRow, ILink } from '@components/DataDisplay/DataDisplay';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { IOpsPortalMerchantDetails } from '@schemas/opsPortal/types/merchantDetails';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';
import {
  getMerchantLogo,
  getMerchantPrimaryColour,
} from '@utils/getBrandingDefaults';
import { MerchantLogo } from './MerchantLogo';

interface IGetDataListProps extends Partial<IOpsPortalIban> {
  canViewIban: boolean;
  data: IOpsPortalMerchantDetails;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const getDataList = ({
  canViewIban,
  data,
  getLocaleDate,
  iban,
  translate,
}: IGetDataListProps) => {
  const {
    availableLanguages,
    billingAddress,
    country,
    createdDate,
    externalOriginationId,
    externalUuid,
    instoreCollectAdditionalDetails,
    instoreDobRequired,
    instoreFormType,
    isB2b,
    isHighRiskIndustry,
    legalName,
    logo,
    merchantIndustryLevel1,
    merchantIndustryLevel2,
    merchantSettingGroup,
    merchantSettlementPaused,
    primaryColour,
    refundTimeLimitDays,
    shortName,
    smsSenderId,
    status,
    storeName,
    stripeStatementDescriptor,
    termsAndConditions,
    vatNumber,
    websiteUrl,
  } = objectKeysToCamelCase(data);

  const { isHeylightOverwriteByMerchantBrandingEnabled = false } =
    merchantSettingGroup ?? {};

  const link: ILink = {
    linkTo: termsAndConditions,
    target: '_blank',
    text: termsAndConditions,
  };

  const merchantLogo = getMerchantLogo(
    isHeylightOverwriteByMerchantBrandingEnabled,
    logo,
  );

  const merchantPrimaryColour = getMerchantPrimaryColour(
    isHeylightOverwriteByMerchantBrandingEnabled,
    primaryColour,
  );

  return [
    { key: translate('LABEL.LEGAL_NAME'), value: legalName },
    { key: translate('OPS_PORTAL.LABEL.SHORT_NAME'), value: shortName },
    { key: translate('TITLE.UUID'), value: externalUuid },
    { key: translate('TITLE.STATUS'), value: status },
    {
      key: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      value: createdDate ? getLocaleDate({ date: createdDate }) : '',
    },
    { key: translate('TITLE.BILLING_ADDRESS'), value: billingAddress },
    {
      key: translate('OPS_PORTAL.LABEL.SMS_SENDER_ID'),
      value: smsSenderId,
    },
    {
      key: translate('OPS_PORTAL.LABEL.PRIMARY_COLOUR'),
      value: <ColourBox colour={merchantPrimaryColour} />,
    },
    {
      key: translate('OPS_PORTAL.LABEL.LOGO'),
      value: <MerchantLogo logo={merchantLogo} />,
    },
    { key: translate('OPS_PORTAL.LABEL.COUNTRY'), value: country },
    {
      key: translate('OPS_PORTAL.LABEL.AVAILABLE_LANGUAGES'),
      value: availableLanguages
        ?.map(languageGroup =>
          translate(
            `OPS_PORTAL.LABEL.${languageGroup.languageCode.toUpperCase()}`,
          ),
        )
        .join(', '),
    },
    {
      key: translate('OPS_PORTAL.LABEL.EXTERNAL_ORGANISATION_ID'),
      value: externalOriginationId,
    },
    { key: translate('TITLE.STORE_NAME'), value: storeName },
    { key: translate('OPS_PORTAL.LABEL.WEBSITE_URL'), value: websiteUrl },
    { key: translate('LABEL.VAT_REGISTRATION_NUMBER'), value: vatNumber },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_SETTLEMENT_PAUSED'),
      value: convertBooleanToYesOrNo(merchantSettlementPaused, translate),
    },
    {
      key: translate('OPS_PORTAL.LABEL.INSTORE_COLLECT_ADDITIONAL_DETAILS'),
      value: convertBooleanToYesOrNo(
        instoreCollectAdditionalDetails,
        translate,
      ),
    },
    {
      key: translate('OPS_PORTAL.LABEL.INSTORE_DOB_REQUIRED'),
      value: convertBooleanToYesOrNo(instoreDobRequired, translate),
    },
    {
      key: translate('OPS_PORTAL.LABEL.INSTORE_FORM_TYPE'),
      value: instoreFormType,
    },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_INDUSTRY_LEVEL_1'),
      value: merchantIndustryLevel1,
    },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_INDUSTRY_LEVEL_2'),
      value: merchantIndustryLevel2,
    },
    {
      key: translate('OPS_PORTAL.LABEL.IS_HIGH_RISK_INDUSTRY'),
      value: convertBooleanToYesOrNo(isHighRiskIndustry, translate),
    },
    {
      key: translate('OPS_PORTAL.LABEL.REFUND_TIME_LIMIT_DAYS'),
      value: refundTimeLimitDays,
    },
    {
      key: translate('OPS_PORTAL.LABEL.IS_B2B'),
      value: convertBooleanToYesOrNo(isB2b, translate),
    },
    {
      key: translate('OPS_PORTAL.LABEL.TERMS_AND_CONDITIONS_URL'),
      value: link,
    },
    {
      key: translate('OPS_PORTAL.LABEL.STRIPE_STATEMENT_DESCRIPTOR'),
      value: stripeStatementDescriptor,
    },
    ...(canViewIban
      ? [{ key: translate('TITLE.BANK_ACCOUNT_NUMBER'), value: iban }]
      : []),
  ].map(val => addPlaceholderForEmptyValues<IDataRow>(val));
};
