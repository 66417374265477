import { ValueOf } from '@appTypes';
import { TableNames } from '@utils/constants';

const tablesThatUseCursorPagination = [
  TableNames.API_CALLS,
  TableNames.APPLICATIONS,
  TableNames.BANK_ACCOUNT_RECONCILIATIONS,
  TableNames.CARD_ATTEMPTS,
  TableNames.CERTIFICATE_OF_INTEREST,
  TableNames.CONTRACTS_BY_UUIDS,
  TableNames.CRABB,
  TableNames.MERCHANT_STORES,
  TableNames.ORDERS,
  TableNames.PENDING_ALLOCATIONS,
  TableNames.SETTLEMENT_TRANSACTIONS,
  TableNames.SUSPENSE,
  TableNames.UNBALANCED_ALLOCATIONS,
  TableNames.USERS,
];

export const getTableUsesCursorPagination = (
  tableName: ValueOf<typeof TableNames>,
) => tablesThatUseCursorPagination.some(name => name === tableName);
