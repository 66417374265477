import {
  ITableConfig,
  LimitOffsetPaginationModel,
} from '@components/TableV2/types';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies/useCollectionAgencies';
import { Translate } from '@hooks/useI18n';
import { useLimitOffsetPagination } from '@hooks/useModal/hooks/useLimitOffsetPagination/useLimitOffsetPagination';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';
import { IOpsPortalCollections } from '@schemas/opsPortal/types/collections';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { useGetCollectionsQuery } from './queries/useGetCollectionsQuery';
import { ICollectionsFilterValues } from './types';
import { useGetCollectionsFilters } from './useGetCollectionsFilters';
import { useGetCollectionsTableColumns } from './useGetCollectionsTableColums';

interface IUseGetCollectionsConfig {
  merchantsData?: IOpsPortalMerchantsList;
  translate: Translate;
}

export const useGetCollectionsTableConfig = ({
  merchantsData,
  translate,
}: IUseGetCollectionsConfig): ITableConfig<
  ICollectionsFilterValues,
  LimitOffsetPaginationModel,
  IOpsPortalCollections | undefined
> => {
  const initialDefaultFilterValues: ICollectionsFilterValues = {
    merchant: '',
    performanceStatus: 'overdue',
    search: '',
  };

  const filtersSettings = useGetCollectionsFilters({
    merchantsData,
    initialDefaultValues: initialDefaultFilterValues,
    translate,
  });

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  const paginationSettings = useLimitOffsetPagination();

  const columns = useGetCollectionsTableColumns({ collectionAgencies });

  const sortingSettings = useTableSorting();

  const { collectionsData, collectionsError, isCollectionsLoading } =
    useGetCollectionsQuery({
      filterValues: filtersSettings.filterValues,
      paginationParams: paginationSettings.paginationParams,
      sortingParam: sortingSettings?.sortSearchParam,
    });

  return {
    columns,
    data: collectionsData,
    error: collectionsError,
    filtersSettings,
    isLoading: isCollectionsLoading || isCollectionAgenciesLoading,
    paginationSettings,
    sortingSettings,
  };
};
