import { IRootFilter } from '@hooks/useQueryState/utils/types';
import { useTrackFiltersChangedEffect } from '@utils/grafana/hooks';

interface IUseGetFiltersChanged {
  columnFilterDefaults?: IRootFilter[];
  columnFilters?: IRootFilter[];
  tableName: string;
}

const mapToFilter = (columnFilters: IRootFilter[] | undefined) =>
  Object.fromEntries(
    columnFilters?.map(filter => [filter.id, filter.value]) ?? [],
  );

export const useGetQueryStateFiltersChanged = ({
  columnFilterDefaults,
  columnFilters,
  tableName,
}: IUseGetFiltersChanged) => {
  const filterValues = mapToFilter(columnFilters);
  const defaultFilterValues = mapToFilter(columnFilterDefaults);

  useTrackFiltersChangedEffect({
    filterValues,
    defaultFilterValues,
    tableName,
  });
};
