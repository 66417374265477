import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { IMerchantStore } from '@schemas/merchantPortal/types/merchantStores';
import { IMapDataProps, IMerchantStoreColumn } from './types';

export const mapData = ({ data }: IMapDataProps): IMerchantStoreColumn[] =>
  data?.map((dataRow: IMerchantStore) => {
    const { store_name: storeName, store_number: storeNumber, uuid } = dataRow;

    return addPlaceholderForEmptyValues<IMerchantStoreColumn>({
      storeName,
      storeNumber,
      uuid,
    });
  }) ?? [];
