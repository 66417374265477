import { Formik, Form } from 'formik';
import { TextField } from '@components/TextField/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getCodeValidationSchema } from './getValidationSchema';

interface IVerificationFormProps {
  formID: string;
  handleSubmit: (data: { code: string }) => void;
}

export const VerificationForm = ({
  formID,
  handleSubmit,
}: IVerificationFormProps) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    code: '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getCodeValidationSchema(translate)}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="code"
          label={translate('LABEL.VERIFICATION_CODE')}
          name="code"
        />
      </Form>
    </Formik>
  );
};
