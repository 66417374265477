import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleDate } from '@hooks/useI18n';
import { IConsumers } from '@schemas/opsPortal/types/consumers';

interface IPrepareConsumerTableDataProps {
  data: IConsumers;
  getLocaleDate: GetLocaleDate;
}

export const prepareConsumerTableData = ({
  data,
  getLocaleDate,
}: IPrepareConsumerTableDataProps) =>
  data.map(dataRow => {
    const {
      created: createdDate,
      email: consumerEmail,
      first_name: consumerFirstName,
      last_name: consumerLastName,
      uuid: consumerUUID,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      consumerFirstName,
      consumerLastName,
      consumerEmail,
      createdDate: createdDate && getLocaleDate({ date: createdDate }),
      consumerUUID,
    });
  });
