import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { requestRegistrationEmail } from '@auth/_redux';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { Originators, HeyLightOriginatorRouteMapping } from '@utils/constants';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components/AuthForm';

const originatorThemeMapping = {
  [Originators.HEIDIPAY]: 'heylight_ch',
  [Originators.PAGOLIGHT]: 'heylight_bnpl_it',
  [Originators.PAGOLIGHTPRO]: 'heylight_financing_it',
};
const StyledHeader = styled('h2')({
  whiteSpace: 'pre-line',
});

export const RequestRegistrationEmail = () => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const navigate = useNavigate();
  const routePrefix = HeyLightOriginatorRouteMapping[originator];

  const handleSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      requestRegistrationEmail(values)
        .then(() => {
          navigate(`${routePrefix}/auth/success`, {
            state: {
              heading: translate('AUTH.REGISTRATION_REQUESTED_SUBMITTED'),
              text: translate(
                'AUTH.PLEASE_CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS',
              ),
            },
            replace: true,
          });
        })
        .catch(() => {
          setStatus(translate('ERROR.UNEXPECTED_ERROR'));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate, routePrefix, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <StyledHeader>
          {translate('AUTH.RECEIVE_ACCOUNT_ACCESS_LINK')}
        </StyledHeader>
        <p>{translate('AUTH.USE_PURCHASE_EMAIL_ADDRESS')}</p>
      </AuthPageHeader>

      <AuthForm
        fields={['email']}
        handleSubmit={handleSubmit}
        initialValues={{
          email: '',
          language: selectedLanguage,
          theme: originatorThemeMapping[originator],
        }}
      />
    </ContentInnerWrapper>
  );
};
