import Skeleton from '@mui/material/Skeleton';
import { missingValuePlaceholder } from '@utils/constants';

interface ISkeletonColumnCell {
  value?: string;
}

export const SkeletonColumnCell = ({ value }: ISkeletonColumnCell) => {
  if (value === missingValuePlaceholder) {
    return <Skeleton sx={{ height: '30px' }} />;
  }
  return <>{value}</>;
};
