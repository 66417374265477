import { useCallback } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { FormError } from '@auth/pages/Auth.styles';
import { TextField } from '@components/TextField';
import { AuthField, Field } from './constants';
import { IAuthLabels } from './types';

interface IAuthFormFields {
  errors: FormikErrors<Partial<Record<Field, string>>>;
  fields: AuthField[];
  labels: IAuthLabels;
  status: unknown;
  touched: FormikTouched<Partial<Record<Field, string>>>;
}

export const AuthFormFields = ({
  errors,
  fields,
  labels,
  status,
  touched,
}: IAuthFormFields) => {
  const getInputClasses = useCallback(
    (fieldname: Field) => {
      const touchedClassName = errors[fieldname] ? 'is-invalid' : 'is-valid';
      return touched[fieldname] ? touchedClassName : '';
    },
    [errors, touched],
  );

  return (
    <>
      {status ? <FormError>{status}</FormError> : null}
      {fields.map(field => {
        const attributes = {
          className: getInputClasses(field),
          id: field,
          label: labels[field],
          name: field,
          ...(field === 'email' ? {} : { type: 'password' }),
        };

        return <TextField {...attributes} key={field} />;
      })}
    </>
  );
};
