import { useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import {
  ICardAttempts,
  IOpsPortalCardAttempts,
  IDateTimeNotNull,
} from '@schemas/opsPortal/types/cardAttempts';
import { QueryKeys, TabEventKeys, TableNames } from '@utils/constants';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks';

export interface ICardAttemptsTableData {
  amount: string;
  created: IDateTimeNotNull;
  refund: boolean;
  status: string;
  statusMessage: string;
  triggerType: string;
}

export const useCardAttemptsTab = (externalContractUUID?: string) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.CARD_ATTEMPTS);

  const queryKey = [
    QueryKeys.CARD_ATTEMPTS,
    externalContractUUID,
    stringifiedQueryParams,
  ];

  const refToDataTable = useRef(null);

  const { data, error, isLoading } = useQuery<
    IOpsPortalCardAttempts,
    AxiosError
  >(
    queryKey,
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.CardAttempts}/${externalContractUUID}`,
        { query: { ...queryParams } },
      ),
    {
      enabled: Boolean(externalContractUUID),
    },
  );

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: data?.next_cursor,
    prevCursor: data?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  const filters = getFilters({});

  const cardAttemptsData: ICardAttemptsTableData[] =
    data?.results?.map(
      ({
        amount,
        created,
        refund,
        status,
        status_message: statusMessage,
        trigger_type: triggerType,
      }: ICardAttempts) => {
        return addPlaceholderForEmptyValues<ICardAttemptsTableData>({
          created: getLocaleDate({ date: created, includeTime: true }),
          amount: getLocaleCurrencyValue({
            currency: amount.currency,
            value: amount.amount,
          }),
          refund: convertBooleanToYesOrNo(refund, translate),
          status,
          statusMessage,
          triggerType,
        });
      },
    ) ?? [];

  const columnHelper = createColumnHelper<ICardAttemptsTableData>();
  const cardAttemptColumns: ReactTableColumns<ICardAttemptsTableData> = [
    columnHelper.accessor('created', {
      header: translate('OPS_PORTAL.LABEL.CREATED'),
      enableSorting: false,
    }),
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
      enableSorting: false,
    }),
    columnHelper.accessor('refund', {
      header: translate('TYPE.REFUND'),
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: translate('TITLE.STATUS'),
      enableSorting: false,
    }),
    columnHelper.accessor('statusMessage', {
      header: translate('OPS_PORTAL.LABEL.STATUS_MESSAGE'),
      enableSorting: false,
    }),
    columnHelper.accessor('triggerType', {
      header: translate('OPS_PORTAL.LABEL.TRIGGER_TYPE'),
      enableSorting: false,
    }),
  ];

  const component = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <ServersideTable
        columns={cardAttemptColumns}
        data={cardAttemptsData}
        filters={filters}
        isCursorPagination={true}
        manageControlledState={updateQueryParams}
        pagination={{
          gotoNextPage,
          gotoPrevPage,
          pageSize: queryParams.pageSize,
          totalItems: data?.count,
        }}
        ref={refToDataTable}
        resetFilters={resetFilters}
        tableState={queryState}
      />
    </QueryStateRouter>
  );

  return {
    eventKey: TabEventKeys.CONTRACT_CARD_ATTEMPTS,
    error,
    isLoading,
    tableName: TableNames.CARD_ATTEMPTS,
    title: translate('OPS_PORTAL.TITLE.CARD_ATTEMPTS'),
    component,
    queryKey,
  };
};
