import { Translate } from '@hooks/useI18n';
import { FilterTypes, TableNames } from '@utils/constants';
import { TableName } from './types';

const searchLabel = 'LABEL.SEARCH';

export interface IGetSearchFieldParams {
  queryParams?: {
    search?: string;
  };
  tableName: TableName;
  translate: Translate;
}

export const getSearchField = ({
  queryParams,
  tableName,
  translate,
}: IGetSearchFieldParams) => {
  const labels: Partial<Record<TableName, string>> = {
    [TableNames.COMMUNICATIONS]: `${translate(searchLabel)} (${translate(
      'LABEL.SUBJECT_LINE_ONLY',
    )})`,
    [TableNames.CONSUMERS]: `${translate(searchLabel)} (${translate(
      'LABEL.EMAILS_AND_NAMES_ONLY',
    )})`,
    [TableNames.MERCHANTS]: `${translate(searchLabel)} (${translate(
      'LABEL.MERCHANT_NAME_ONLY',
    )})`,
  };

  return {
    id: 'search',
    label: labels[tableName] ?? translate(searchLabel),
    type: FilterTypes.SEARCH,
    value: queryParams?.search,
  };
};
