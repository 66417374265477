import { Formik, Form } from 'formik';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { TextField } from '@components/TextField/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IConsumerPortalAccountSettings } from '@schemas/consumerPortal/types/accountSettings';
import { IMerchantPortalAccountSettings } from '@schemas/merchantPortal/types/accountSettings';
import { IOpsPortalAccountSettings } from '@schemas/opsPortal/types/accountSettings';
import { getValidationSchema } from './getValidationSchema';

type IAccountSettings =
  | IMerchantPortalAccountSettings
  | IConsumerPortalAccountSettings
  | IOpsPortalAccountSettings;

interface IRequestObj {
  email: string;
  first_name: string;
  last_name: string;
}

interface IEditNameForm {
  data?: IAccountSettings;
  formID: string;
  handleSubmit: (data: IRequestObj) => void;
}

export const EditNameForm = ({ data, formID, handleSubmit }: IEditNameForm) => {
  const { translate } = useI18n();

  const { email, firstName, lastName } = objectKeysToCamelCase(
    addPlaceholderForEmptyValues<IRequestObj>(data ?? {}, ''),
  );
  const initialFormikValues: IRequestObj = {
    email,
    first_name: firstName,
    last_name: lastName,
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(translate)(initialFormikValues)}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="first_name"
          label={translate('LABEL.FIRST_NAME')}
          name="first_name"
        />
        <TextField
          id="last_name"
          label={translate('LABEL.LAST_NAME')}
          name="last_name"
        />
      </Form>
    </Formik>
  );
};
