import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalTerminableContracts } from '@schemas/opsPortal/types/terminableContracts';
import { QueryKeys, Views } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { getTerminableContractsTableColumns } from './getTerminableContractColumns';
import { getTerminatedContractStatusesTranslated } from './getTerminatedContractStatusesTranslated';
import { mapTerminableContractsTableData } from './mapTerminableContractsTableData';
import {
  IHandleSelectAllClicked,
  ITerminableContractTableColumns,
} from './types';

export const TerminableContracts = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const [selectedForTermination, setSelectedForTermination] = useState<
    string[]
  >([]);
  const userCanTerminateContracts = useHasPermission([
    'ops_portal.can_terminate_contracts',
  ]);

  const refToDataTable =
    useRef<ITableRefObject<ITerminableContractTableColumns>>(null);

  const {
    activateQueryStateHook,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(Views.TERMINABLE_CONTRACTS);

  const {
    data: contractsData,
    error: contractsError,
    isFetching: isContractsFetching,
    isLoading: isContractsLoading,
  } = useQuery<IOpsPortalTerminableContracts, AxiosError>(
    [QueryKeys.TERMINABLE_CONTRACTS, stringifiedQueryParams],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchTerminableContracts, {
        query: queryParams,
      }),
  );

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  useEffect(() => {
    setSelectedForTermination([]);
  }, [queryParams?.offset, queryParams?.limit]);

  const translatedTerminatedStatuses = useMemo(
    () => getTerminatedContractStatusesTranslated(translate),
    [translate],
  );

  const isTerminatedContract = useCallback(
    (status: string): boolean => {
      return translatedTerminatedStatuses.includes(status);
    },
    [translatedTerminatedStatuses],
  );

  const handleSelectAllClicked: IHandleSelectAllClicked = useCallback(
    ({ areAllNonTerminatedContractsChecked, rows }) => {
      return () => {
        rows.forEach(row => {
          if (
            row.getIsSelected() === areAllNonTerminatedContractsChecked &&
            !isTerminatedContract(row.original.status)
          ) {
            row.toggleSelected();
          }
        });
      };
    },
    [isTerminatedContract],
  );

  const columns = useMemo(
    () =>
      getTerminableContractsTableColumns({
        collectionAgencies,
        handleSelectAllClicked,
        isTerminatedContract,
        translate,
      }),
    [
      collectionAgencies,
      handleSelectAllClicked,
      isTerminatedContract,
      translate,
    ],
  );

  const contractsTableData = mapTerminableContractsTableData({
    data: contractsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const updateRowsForTerminationHandler = useCallback(
    (rows: Row<ITerminableContractTableColumns>[]) => {
      const externalContractUuids = rows
        .map(({ original }) => original.externalContractUuid)
        .filter(Boolean);
      setSelectedForTermination(externalContractUuids);
    },
    [setSelectedForTermination],
  );

  const { isLoading: isTerminating, mutate } = useMutation<
    unknown,
    AxiosError,
    unknown
  >(
    uuidsForTermination =>
      postDataToEndpoint({
        endpoint: Endpoints.TerminateContractsBatch,
        requestData: { uuids: uuidsForTermination },
      }),
    {
      onSuccess: () => {
        setSelectedForTermination([]);
        window.location.reload();
      },
      onError: () => {
        setSelectedForTermination([]);
        window.location.reload();
      },
    },
  );

  const handleContractsTermination = useCallback(() => {
    mutate(selectedForTermination);
  }, [mutate, selectedForTermination]);

  const buttons = [
    {
      clickHandler: handleContractsTermination,
      buttonKey: 'terminateContracts',
      text:
        !selectedForTermination.length || selectedForTermination.length < 2
          ? translate('OPS_PORTAL.BUTTON.TERMINATE_CONTRACT')
          : `${translate('OPS_PORTAL.BUTTON.TERMINATE_CONTRACTS')} (${
              selectedForTermination.length
            })`,
      disabled: !selectedForTermination.length || !userCanTerminateContracts,
    },
  ];

  return (
    <QueryStateRouter
      error={contractsError}
      isLoading={
        isContractsLoading ||
        isContractsFetching ||
        isTerminating ||
        isCollectionAgenciesLoading
      }
    >
      <Card
        buttons={buttons}
        title={translate('OPS_PORTAL.TITLE.ELIGIBLE_FOR_TERMINATION')}
      >
        <ServersideTable
          columns={columns}
          data={contractsTableData}
          enableMultiRowSelection={true}
          handleMultiRowSelect={updateRowsForTerminationHandler}
          manageControlledState={updateQueryParams}
          pagination={{
            limit: queryParams.limit,
            offset: queryParams.offset,
            totalItems: contractsData?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </Card>
    </QueryStateRouter>
  );
};
