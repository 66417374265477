import React from 'react';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { missingValuePlaceholder } from '@utils/constants';

interface IColourBoxProps {
  colour?: Nullable<string>;
}

const StyledColourBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

interface IColourIndicator {
  colour: string;
}

const ColourIndicator = styled(Box)<IColourIndicator>(({ colour }) => ({
  marginRight: '6px',
  width: '18px',
  height: '18px',
  borderRadius: '5px',
  background: colour,
}));

export const ColourBox = ({ colour }: IColourBoxProps) =>
  colour ? (
    <StyledColourBox>
      <ColourIndicator colour={colour} />
      {colour}
    </StyledColourBox>
  ) : (
    <>{missingValuePlaceholder}</>
  );
