import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { GetLocaleDate } from '@hooks/useI18n';
import { IMerchants } from '@schemas/opsPortal/types/merchants';
import { IMerchantsTableColumns } from './getMerchantsTableColumns';

interface IPrepareMerchantsTableDataProps {
  data: IMerchants;
  getLocaleDate: GetLocaleDate;
}

export const prepareMerchantsTableData = ({
  data,
  getLocaleDate,
}: IPrepareMerchantsTableDataProps): IMerchantsTableColumns[] =>
  data.map(dataRow => {
    const {
      country,
      createdDate,
      externalUuid,
      iban,
      merchantIndustryLevel1,
      merchantIndustryLevel2,
      shortName: merchantName,
    } = objectKeysToCamelCase(dataRow);

    return addPlaceholderForEmptyValues<IMerchantsTableColumns>({
      merchantName,
      country,
      iban,
      merchantIndustryLevel1,
      merchantIndustryLevel2,
      createdDate: createdDate && getLocaleDate({ date: createdDate }),
      externalUUID: externalUuid,
    });
  });
