import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IMerchantPortalMediobancaSettlements } from '@schemas/merchantPortal/types/mediobancaSettlements';
import { IMediobancaTableColumn } from './types';

interface IPrepareReconciliationsValues {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  reconciliations: IMerchantPortalMediobancaSettlements;
  translate: Translate;
}

export const prepareReconciliationsValues = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  reconciliations,
  translate,
}: IPrepareReconciliationsValues) => {
  const settlementTypeMap = {
    SETTLED: translate('TITLE.PAID'),
    REFUNDED: translate('TYPE.REFUND'),
  };

  return reconciliations.map(item => {
    const {
      consumerName,
      currency,
      installments,
      interest,
      invoiceNumber,
      mediobancaContractId,
      paymentPlan,
    } = objectKeysToCamelCase(item);

    const settlementType =
      item.settlement_type &&
      settlementTypeMap[
        item.settlement_type.toUpperCase() as keyof typeof settlementTypeMap
      ];

    const settlementDate =
      item.settlement_date && getLocaleDate({ date: item.settlement_date });

    const grossAmount = getLocaleCurrencyValue({
      currency,
      value: item.gross_amount?.toString(),
    });

    const netAmount = getLocaleCurrencyValue({
      currency,
      value: item.net_amount?.toString(),
    });

    const netFees = getLocaleCurrencyValue({
      currency,
      value: item.net_fee?.toString(),
    });

    return addPlaceholderForEmptyValues<IMediobancaTableColumn>({
      paymentPlan,
      interest: getLocaleCurrencyValue({
        currency,
        value: interest?.toString(),
      }),
      invoiceNumber,
      mediobancaContractId,
      consumerName,
      settlementDate,
      installments: installments?.toString(),
      grossAmount,
      netAmount,
      netFees,
      settlementType,
    });
  });
};
