import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { HeyLightBranding } from '@utils/constants';

const defaultLogo = HeyLightBranding.logo;
const defaultPrimaryColour = HeyLightBranding.primaryColour;

export const getMerchantLogo = (
  isMerchantBrandingOverrideEnabled: boolean,
  merchantLogo?: Nullable<string>,
) =>
  isMerchantBrandingOverrideEnabled ? merchantLogo ?? defaultLogo : defaultLogo;

export const getMerchantPrimaryColour = (
  isMerchantBrandingOverrideEnabled: boolean,
  merchantPrimaryColour?: Nullable<string>,
) =>
  isMerchantBrandingOverrideEnabled
    ? merchantPrimaryColour ?? defaultPrimaryColour
    : defaultPrimaryColour;
