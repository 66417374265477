import { NoWrap } from '@components/Common.styles';
import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { Translate, useI18n } from '@hooks/useI18n';
import { IPendingAllocation } from '@schemas/opsPortal/types/pendingAllocations';
import { PendingAllocation } from '../PendingAllocation';

interface IUseGetPendingAllocationsColumns {
  canReconcileBankAccount: boolean;
  queryKey: string[];
  translate: Translate;
}
const allocationTypeTranslationPrefix =
  'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS';

export const useGetPendingAllocationsColumns = ({
  canReconcileBankAccount,
  queryKey,
  translate,
}: IUseGetPendingAllocationsColumns) => {
  const { getLocaleCurrencyValue, getLocaleDate } = useI18n();

  const allocateTransactionColumn: TableColumns<IPendingAllocation> = [
    {
      field: 'buttonColumn',
      label: '',
      mapCustomJSX: ({ row }) => (
        <PendingAllocation allocationUuid={row.uuid} queryKey={queryKey} />
      ),
    },
  ];

  const columns: TableColumns<IPendingAllocation> = [
    {
      field: 'created',
      label: translate('OPS_PORTAL.TITLE.ALLOCATED_ON'),
      mapCustomValue: ({ row }) => getLocaleDate({ date: row.created }),
    },
    {
      field: 'statement_line_date',
      label: translate('OPS_PORTAL.TITLE.RECEIVED_ON'),
      mapCustomValue: ({ row: { statement_line_date: statementLineDate } }) =>
        statementLineDate && getLocaleDate({ date: statementLineDate }),
    },
    {
      field: 'payment_plan_reference',
      label: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
    },
    {
      field: 'statement_line_amount',
      label: translate('LABEL.AMOUNT'),
      mapCustomJSX: ({
        row: {
          amount: { amount, currency },
        },
      }) => (
        <NoWrap>{getLocaleCurrencyValue({ value: amount, currency })}</NoWrap>
      ),
    },
    {
      field: 'statement_line_reference',
      label: translate('OPS_PORTAL.LABEL.REFERENCE'),
    },
    {
      field: 'allocation_type',
      label: translate('OPS_PORTAL.LABEL.ALLOCATION_DETAILS'),
      mapCustomValue: ({ row }) =>
        translate(`${allocationTypeTranslationPrefix}.${row.allocation_type}`),
    },
    ...(canReconcileBankAccount ? allocateTransactionColumn : []),
  ];

  return createTableColumnsV2({ columns });
};
