import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IPaymentsTableData } from '@pages/ContractDetails/utils/types';
import { SortingFunctions } from '@utils/constants';

export const getPaymentsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IPaymentsTableData>();

  return [
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
    columnHelper.accessor('settlementDate', {
      header: translate('OPS_PORTAL.LABEL.CUSTOMER_PAYMENT_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('settlementCreatedDate', {
      header: translate('OPS_PORTAL.LABEL.RECONCILIATION_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('settlementReference', {
      header: translate('OPS_PORTAL.LABEL.REFERENCE'),
    }),
    columnHelper.accessor('settlementType', {
      header: translate('OPS_PORTAL.LABEL.PAID_VIA'),
    }),
    columnHelper.accessor('prepayment', {
      header: translate('TYPE.PREPAYMENT'),
    }),
    columnHelper.accessor('customerRefund', {
      header: translate('TYPE.REFUND'),
    }),
    columnHelper.accessor('invalidated', {
      header: translate('OPS_PORTAL.LABEL.INVALIDATED'),
    }),
    columnHelper.accessor('transactionInvalidationDate', {
      header: translate('OPS_PORTAL.LABEL.INVALIDATION_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
  ];
};
