import { Fragment, ReactElement, isValidElement } from 'react';
import { Link } from 'react-router-dom';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { Dd, Dl, Dt } from '@components/Common.styles';
import { StatusPill } from '@components/StatusPill';
import { useI18n } from '@hooks/useI18n/useI18n';
import { missingValuePlaceholder, RawStatuses } from '@utils/constants';

export interface ILink {
  linkTo?: Nullable<string>;
  target?: '_blank' | '_self';
  text?: Nullable<string>;
}

export type DataValue = number | string | ILink | ReactElement;

export interface IDataRow {
  key: string;
  value: DataValue;
}

interface IDataDisplayProps {
  dataList: IDataRow[];
}

export const DataDisplay = ({ dataList }: IDataDisplayProps) => {
  const { translate } = useI18n();

  const getRenderValue = (value: DataValue) => {
    if (
      typeof value === 'object' &&
      'linkTo' in value &&
      !isValidElement(value)
    ) {
      return value.linkTo ? (
        <Link target={value.target ?? '_self'} to={value.linkTo}>
          {value.text}
        </Link>
      ) : null;
    }
    if (value === 'blocked') {
      return (
        <StatusPill
          rawStatus={RawStatuses.BLOCKED}
          status={translate('OPS_PORTAL.STATUS.BLOCKED')}
        />
      );
    }
    return value;
  };

  return (
    <Dl>
      {dataList.map(({ key, value }) => {
        const renderValue = getRenderValue(value);

        return (
          <Fragment key={key}>
            <Dt>{key}</Dt>
            <Dd>{renderValue ?? missingValuePlaceholder}</Dd>
          </Fragment>
        );
      })}
    </Dl>
  );
};
