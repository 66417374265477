import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useOriginator } from '@hooks/useOriginator';
import { ButtonVariant } from '@utils/enums';

interface IOriginatorRouterLinkProps {
  children: string;
  className: string;
  id: string;
  to: string;
  variant?: ButtonVariant;
}

const StyledLink = styled(Link)({
  whiteSpace: 'pre-line',
});

export const OriginatorRouterLink = (props: IOriginatorRouterLinkProps) => {
  const { pathnamePrefix } = useOriginator();
  const path = pathnamePrefix + props.to;

  return <StyledLink {...props} to={path} />;
};
