import { useEffect, useState } from 'react';
import { PaginationParams } from '@components/TableV2/types';
import { CursorPaginationModel, IUseCursorPaginationProps } from './types';

export const useCursorPagination = ({
  defaultPageSize = 10,
}: IUseCursorPaginationProps = {}) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentCursor, setCurrentCursor] = useState('');
  const [nextCursor, setNextCursor] = useState('');
  const [prevCursor, setPrevCursor] = useState('');
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    cursor: '',
    limit: defaultPageSize,
  });

  const handlePagination = ({
    isNextClicked = true,
    manualOverrideCursor,
    pageSize: pageLimit,
    reset = false,
  }: CursorPaginationModel) => {
    const hasPageLimitChanged = pageLimit && pageLimit !== pageSize;
    if (reset || hasPageLimitChanged) {
      setCurrentCursor('');
      setPrevCursor('');
      setNextCursor('');
      if (hasPageLimitChanged) {
        setPageSize(pageLimit);
      }
    } else if (manualOverrideCursor) {
      // Manual override cursor is for the cases where
      // the cursor is preset in the URL on load
      setCurrentCursor(manualOverrideCursor);
      setPrevCursor('');
      setNextCursor('');
    } else if (isNextClicked) {
      setCurrentCursor(nextCursor);
    } else {
      setCurrentCursor(prevCursor);
    }
  };

  useEffect(() => {
    setPaginationParams({
      cursor: currentCursor,
      limit: pageSize,
    });
  }, [currentCursor, pageSize]);

  return {
    cursors: {
      cursor: currentCursor,
      nextCursor,
      prevCursor,
    },
    handlePagination,
    pageSize,
    paginationParams,
    setNextCursor,
    setPrevCursor,
  };
};
