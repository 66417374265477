import { styled } from '@mui/material/styles';

interface IJsonCell {
  data: object;
}

const JsonCellStyled = styled('pre')(() => ({
  fontFamily: 'HeyLight Sans',
  fontSize: '13px',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));

export const isJsonParsable = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

export const JsonCell = ({ data }: IJsonCell) => {
  const prettyJson = JSON.stringify(data, null, 0.01);
  const cleanedJson = prettyJson
    .replace(/[{}[\]"]/g, '')
    .split('\n')
    .map(line => line.trim())
    .join('\n');

  return <JsonCellStyled>{cleanedJson}</JsonCellStyled>;
};

export const normaliseJsonString = (jsonString: string) =>
  jsonString
    .replace(/'/g, '"')
    .replace(/\bTrue\b/g, 'true')
    .replace(/\bFalse\b/g, 'false');

export const getJsonCell = (str: string) => {
  const normalisedJson = normaliseJsonString(str);
  if (isJsonParsable(str)) {
    return <JsonCell data={JSON.parse(str)} />;
  }
  if (isJsonParsable(normalisedJson)) {
    return <JsonCell data={JSON.parse(normalisedJson)} />;
  }
  return str;
};
