import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { Environments } from '@utils/enums';
import { getEnv } from '@utils/getEnv';

const allowedGrafanaEnvironments: Environments[] = [Environments.Prod];

export const initGrafana = async () => {
  const { environment, isLocalhost } = await getEnv();

  if (!isLocalhost && allowedGrafanaEnvironments.includes(environment)) {
    initializeFaro({
      url: 'https://faro-collector-prod-gb-south-0.grafana.net/collect/6fd35ef2ed84f52904782f61ab5dc459',
      app: {
        name: 'Portals',
        version: '1.0.0',
        environment,
      },
      sessionTracking: {
        samplingRate: 0.1,
      },
      ignoreUrls: [/\/media\//],
      instrumentations: [
        // Mandatory, omits default instrumentations otherwise.
        ...getWebInstrumentations(),

        // Tracing package to get end-to-end visibility for HTTP requests.
        new TracingInstrumentation(),

        // Integrate with React Router 6
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V6,
            dependencies: {
              createRoutesFromChildren,
              matchRoutes,
              Routes,
              useLocation,
              useNavigationType,
            },
          },
        }),
      ],
    });
  }
};
