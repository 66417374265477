import { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import { LoadingSpinner } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n';
import { ExtraResultsMessage } from './ExtraResultsMessage';
import { useGetHeaderSearchData } from './hooks/useGetHeaderSearchData';
import {
  SearchContainer,
  SearchItem,
  SearchList,
  SearchListItem,
  SearchSubTitle,
  SearchTitle,
} from './styled';
import { IHeaderSearch } from './types';

export const HeaderSearch = () => {
  const { translate } = useI18n();
  const inputEl = useRef<HTMLInputElement | null>(null);

  const {
    canViewSearchHeader,
    handleBlur,
    handleClearSearch,
    handleSearchChange,
    handleSelect,
    hasSearchResults,
    isValidSearchTerm,
    loading,
    open,
    search,
    searchResults,
    totalApplicationsCount,
    totalContractsCount,
  } = useGetHeaderSearchData();

  return canViewSearchHeader ? (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading && isValidSearchTerm && <LoadingSpinner />}
              {search && (
                <IconButton onClick={handleClearSearch} size="small">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        inputRef={inputEl}
        label={translate('LABEL.SEARCH')}
        onBlur={handleBlur}
        onChange={handleSearchChange}
        size="small"
        sx={{ width: 400, marginRight: '20px' }}
        value={search}
      />
      <Popper
        anchorEl={inputEl.current}
        open={open}
        placement="bottom-start"
        sx={{ zIndex: 1000 }}
      >
        <SearchContainer>
          <SearchList>
            {loading ? (
              <SearchListItem>
                {translate(
                  isValidSearchTerm
                    ? 'STATUS.LOADING'
                    : 'OPS_PORTAL.LABEL.SEARCH_MUST_BE_MINIMUM_3_CHARACTERS_LONG',
                )}
              </SearchListItem>
            ) : null}
            {isValidSearchTerm ? (
              <>
                {hasSearchResults
                  ? searchResults.map(
                      ({
                        redirectUrl,
                        subTitle,
                        title,
                        type,
                      }: IHeaderSearch) => (
                        <SearchListItem
                          key={title}
                          onClick={handleSelect(redirectUrl)}
                        >
                          <SearchItem>
                            <SearchTitle>
                              <b>{type}</b> - {title}
                            </SearchTitle>
                            <SearchSubTitle>{subTitle}</SearchSubTitle>
                          </SearchItem>
                        </SearchListItem>
                      ),
                    )
                  : null}
                {!hasSearchResults && !loading ? (
                  <SearchListItem>
                    {translate('STATUS.NO_DATA_AVAILABLE')}
                  </SearchListItem>
                ) : null}
                <ExtraResultsMessage
                  totalApplicationsCount={totalApplicationsCount}
                  totalContractsCount={totalContractsCount}
                  translate={translate}
                />
              </>
            ) : null}
          </SearchList>
        </SearchContainer>
      </Popper>
    </>
  ) : null;
};
