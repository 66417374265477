import { faro } from '@grafana/faro-react';
import { camelCaseToLowerDelimited } from '@heidi-pay/heidi-common-fe/utils/camelCaseToLowerDelimited';
import { mapObject } from '@heidi-pay/heidi-common-fe/utils/mapObject';
import * as Sentry from '@sentry/react';
import { SentrySeverity } from '@utils/constants';
import { GrafanaEvent } from '../enums';

interface IPushGrafanaEventOptions {
  useSpaceDelimitValues: boolean;
}

const defaultOptions = {
  useSpaceDelimitValues: true,
};

export const pushGrafanaEvent = (
  name: GrafanaEvent,
  eventValues: Record<string, string>,
  { useSpaceDelimitValues }: IPushGrafanaEventOptions = defaultOptions,
) => {
  try {
    const formattedOptions = useSpaceDelimitValues
      ? mapObject(eventValues, ([key, value]) => [
          key,
          camelCaseToLowerDelimited(String(value)),
        ])
      : eventValues;

    faro.api?.pushEvent(name, formattedOptions);
  } catch (error) {
    Sentry.captureException(error, {
      level: SentrySeverity.error,
    });
  }
};
