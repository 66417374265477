import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleDate } from '@hooks/useI18n';
import { IPausedContract } from '@schemas/opsPortal/types/pausedContracts';
import { getFullName } from '@utils/getFullName';

import { IPausedContractTableColumns } from './types';

interface IMapPausedContractTableData {
  data?: IPausedContract[];
  getLocaleDate: GetLocaleDate;
}

export const mapPausedContractsTableData = ({
  data,
  getLocaleDate,
}: IMapPausedContractTableData): IPausedContractTableColumns[] =>
  data?.map((dataRow: IPausedContract) => {
    const {
      consumer_name: consumerName,
      external_uuid: externalUuid,
      payment_paused_till_date: pausedTill,
    } = dataRow;
    return addPlaceholderForEmptyValues<IPausedContractTableColumns>({
      externalUuid,
      customerName: getFullName({
        firstName: consumerName.first_name,
        lastName: consumerName.last_name,
      }),
      pausedTill: pausedTill ? getLocaleDate({ date: pausedTill }) : null,
    });
  }) ?? [];
