import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IPaymentScheduleTableData } from '../../utils/types';

const renderCell = (data: string, amountDuePresent = false) => {
  const cellClass = amountDuePresent ? 'amount-due-entry' : '';
  return <span className={cellClass}>{data}</span>;
};

export const getPaymentScheduleTableColumns = <T,>(translate: Translate) => {
  const columnHelper = createColumnHelper<IPaymentScheduleTableData>();

  return [
    columnHelper.accessor('paymentDate', {
      header: translate('TITLE.DATE'),
      cell: ({ row }) => {
        return renderCell(
          row.original.paymentDate,
          row.original.amountDue === '—' || row.original.focused,
        );
      },
    }),
    columnHelper.accessor('paymentType', {
      header: translate('TITLE.TYPE'),
      cell: ({ row }) => {
        return renderCell(row.original.paymentType);
      },
    }),
    columnHelper.accessor('amountDue', {
      header: translate('OPS_PORTAL.LABEL.AMOUNT_DUE'),
      cell: ({ row }) => {
        return renderCell(row.original.amountDue);
      },
    }),
    columnHelper.accessor('amountPaid', {
      header: translate('OPS_PORTAL.LABEL.AMOUNT_PAID'),
      cell: ({ row }) => {
        return renderCell(row.original.amountPaid);
      },
    }),
    columnHelper.accessor('arrears', {
      header: translate('LABEL.ARREARS_AMOUNT'),
      cell: ({ row }) => {
        return renderCell(row.original.arrears);
      },
    }),
  ] as T;
};
