import { ValueOf } from '@appTypes';
import { DateRangePicker } from '@components/DateRangePicker';
import { Dropdown } from '@components/Dropdown';
import { TextField } from '@components/TextField';
import { IFilter } from '@hooks/useQueryState/utils/types';
import { FilterTypes } from '@utils/constants';

export const getFilterUI = (filter: IFilter) => {
  if (filter.type === FilterTypes.SEARCH && filter.label) {
    return (
      <TextField
        id={filter.id}
        label={filter.label}
        name={filter.id}
        type="search"
      />
    );
  }
  if (filter.type === FilterTypes.DATE_RANGE && filter.to && filter.from) {
    return (
      <DateRangePicker
        endId={filter.to.id}
        endLabel={filter.to.label}
        startId={filter.from.id}
        startLabel={filter.from.label}
      />
    );
  }

  const dropdownFilterTypes: Array<ValueOf<typeof FilterTypes>> = [
    FilterTypes.DROPDOWN,
    FilterTypes.PRESET_DATE_RANGE,
  ];

  if (
    filter.label &&
    filter.options &&
    dropdownFilterTypes.includes(filter.type)
  ) {
    return (
      <Dropdown
        id={filter.id}
        label={filter.label}
        name={filter.id}
        options={filter.options}
      />
    );
  }
  return null;
};
