import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Permissions } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IPaymentMethod } from '@schemas/merchantPortal/types/orderDetails';
import { IMerchantPortalQrInvoice } from '@schemas/merchantPortal/types/qrInvoice';
import { IUuid } from '@schemas/opsPortal/types/contracts';
import { PaymentMethods, QueryKeys } from '@utils/constants';
import { downloadFile } from '@utils/downloadFile';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IGetQrInvoicesProps {
  endpoint?: string;
  externalContractUuid?: IUuid;
  paymentMethod?: IPaymentMethod;
  permissions?: Permissions;
}

export const useGetQrInvoices = ({
  endpoint = Endpoints.FetchMerchantQrInvoices,
  externalContractUuid,
  paymentMethod,
  permissions = ['merchant_portal.can_view_qr_invoice'],
}: IGetQrInvoicesProps) => {
  const { translate } = useI18n();
  const canViewQrInvoice = useHasPermission(permissions);

  const isQrPaymentMethod = paymentMethod === PaymentMethods.SWISS_QR;

  const {
    data: qrInvoiceData,
    error: qrInvoiceError,
    isLoading: qrInvoiceLoading,
  } = useQuery<IMerchantPortalQrInvoice, AxiosError>(
    [QueryKeys.DOWNLOAD_QR_INVOICE, externalContractUuid],
    () => fetchDataFromEndpoint(`${endpoint}/${externalContractUuid}`),
    {
      enabled:
        Boolean(externalContractUuid) && canViewQrInvoice && isQrPaymentMethod,
    },
  );

  const downloadQrInvoiceButton = qrInvoiceData?.url
    ? [
        {
          text: translate('BUTTON.DOWNLOAD_QR_INVOICE'),
          clickHandler: () => downloadFile(qrInvoiceData.url),
          buttonKey: 'qrInvoice',
          variant: ButtonVariant.Primary,
        },
      ]
    : [];

  return {
    qrInvoiceData,
    qrInvoiceError,
    qrInvoiceLoading,
    downloadQrInvoiceButton,
  };
};
